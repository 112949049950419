import React, { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import BulletIcon from '@mui/icons-material/RadioButtonChecked';
import CommonInstructions from './commonInstruction'
interface Props {
    time: number | null; // minutes
    type: string;
}

class LPTInstructions extends Component<Props> {
    render() {
        const {type} = this.props;
        return (
            <React.Fragment>
                <h2>Welcome to  {type === 'lpt' ? 'PsyQ LPT' : type === 'lptJC' ?'PP2' :'Personality Profile'}!</h2>
                <List>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            This is a psychometric test designed to help you better understand the dominant characteristics of your {type ==='lpt' ? 'Leadership ': ' ' }Personality.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            You will be presented with multiple-choice questions. For each question, you will need to select the option that best applies to you.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            All questions are mandatory - Once you have completed all questions, you must click ‘Submit’ 
                            <div style={{ marginLeft: '20px' }}>
                                The test will not be submitted unless all questions have been responded to and the ‘Submit’ button clicked.
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Please note that there are no ‘right’ or ‘wrong’ answers to the questions. For the test results to be a true reflection of your personality, it is important that you respond to this test naturally (who you are, rather than who you want to be).
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time allowed: {this.props.time ? (this.props.time + " minutes") : <CircularProgress size="1rem" />}
                            <div style={{ marginLeft: '20px' }}>
                                The above time limit is adequate for completing the test. Please complete it in one sitting.
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                Any test which is not submitted within the allocated time, will not be evaluated
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time will begin when you press the start button below.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        Once you have pressed 'Start', you will need to complete the test within the given time - please do not close the window/ refresh the page/ stop the test midway. You will not be able to access this test again (through the link provided) once you have started.
                        </ListItemText>
                    </ListItem>
                </List>
              <CommonInstructions />
            </React.Fragment>
        )
    }
}

export default LPTInstructions;