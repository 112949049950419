import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { saveUser } from '../../../util/apiService';
import { UserCredential } from '../../../models/user.model';
import toast from 'react-hot-toast';
import history from "../../../util/history";
interface Props {
}
interface State {
    name:string;
    password:string;
    submitted:boolean;
}
class CreateUser extends Component<Props, State> {
    state: State = {
            name:"",
            password:"",
            submitted:false
    }
   
    componentDidMount() {

    }
    setValue=(variable:any,value:string)=>{
        this.setState({...this.state,[variable]:value})
    }
    saveUserRecord=()=>{
        const {name,password}=this.state
        this.setState({submitted:true})
        if(name && password){
            const userParams:UserCredential={username:name,password:password}
           
            const saveUserPromise=saveUser(userParams).then(()=>{
                history.push("/admin/users")
            })
            toast.promise(saveUserPromise, {
                loading: 'Saving user',
                success: 'User saved',
                error: 'Error saving user'
            });
        }else{
            console.log("error")
        }
    }
    render() {
        const {name, password} = this.state
        return (
            <div>
                <h3> Create User</h3>
                <Accordion >

                    <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Name' onChange={(e) => { this.setValue('name', e.target.value) }} style={{ textAlign: 'right' }} />
                            {!name && this.state.submitted && <p className='red-text'>Name field is required.</p>}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Password' type="password" onChange={(e) => { this.setValue('password', e.target.value) }} style={{ textAlign: 'right' }} />
                           {!password && this.state.submitted && <p className='red-text'>Password field is required.</p>}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Button variant="contained" color="error" onClick={() => {this.saveUserRecord() }}>
                                Save
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        )
    }
}

export default CreateUser;