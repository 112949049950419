import { Component } from "react";
import Assessment from "../../../models/assessments.model";
import AptitudeDetails from "./details/aptitudeDetails";
import JobSkillsDetails from "./details/jobSkillsDetails";
import BeiDetails from "./details/beiDetails";
import IntrayDetails from "./details/intrayDetails";
import LeoDetails from "./details/leoDetails";
import LptDetails from "./details/lptDetails";
import SjtDetails from "./details/sjtDetails";

interface Props {
    type: any;
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: Assessment[]) => void;
    deleteAssessment: (assessmentType: string) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}

class AssessmentDetails extends Component<Props>{
    render() {
        const {type, groupId, assessment, addAssessment, deleteAssessment, setAssessmentTime, saveAssessmentTime} = this.props;
        switch(type.value) {
            case 'aptitude':
                return <AptitudeDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} key={assessment?._id} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'jobSkills':
                return <JobSkillsDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} key={assessment?._id} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'sjt':
                return <SjtDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'bei':
                return <BeiDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'inTray':
                return <IntrayDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'leo':
                return <LeoDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} />
            case 'lpt':
                return <LptDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} type={type.value} key={type.value} />
            case 'lptJ':
                return <LptDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} type={type.value} key={type.value} />
            case 'lptJC':
                    return <LptDetails groupId={groupId} assessment={assessment} addAssessment={addAssessment} deleteAssessment={deleteAssessment} setAssessmentTime={setAssessmentTime} saveAssessmentTime={saveAssessmentTime} type={type.value} key={type.value} />
              
                default:
                return null
        }
    }
}

export default AssessmentDetails;