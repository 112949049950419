import { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Grid from '@mui/material/Grid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import LEO, { LEOAnswer, Leoquestions } from "../../models/leo.model";
import { withStyles } from "@mui/styles";
import { generateStyles } from "../../util/mainStyles";

const grid = 8;
const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const move = (source: any[], destination: any[], droppableSource: any, droppableDestination: any) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: grid,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle
});

interface OwnProps {
    question: LEO,
    answer: LEOAnswer,
    setQuestionsAndAnswers: (questionIndex: number, questionList: Leoquestions[], answerList: Leoquestions[]) => void;
    index: number;
}

interface StyleProps {
    classes: any;
}

interface State { }

type Props = OwnProps & StyleProps;

class LeoComponent extends Component<Props, State> {

    getListStyle = (isDraggingOver: boolean, type: string) => ({
        background: (type === "answers" && this.props.answer?.questions?.length === 5) ? 'rgb(127, 255, 211)' : (isDraggingOver ? 'lightblue' : 'lightgrey'),
        padding: grid,
        minHeight: '100px'
    })

    onDragEnd = (result: any) => {
        const { source, destination } = result;
        const questions = this.props.question.questions;
        const answers = this.props.answer.questions;
        if (!destination) return;
        
        if (source.droppableId === destination.droppableId) {
            const items: Leoquestions[] = reorder(
                source.droppableId === 'questions' ? questions : answers,
                source.index,
                destination.index
            );
            if(source.droppableId === 'questions') {
                this.props.setQuestionsAndAnswers(this.props.index, items, answers);
            } else {
                this.props.setQuestionsAndAnswers(this.props.index, questions, items);
            }
        } else {
            let sourceArray, destinationArray;
            if(source.droppableId === 'questions') {
                sourceArray = questions;
                destinationArray = answers;
            } else {
                sourceArray = answers;
                destinationArray = questions;
            } 
            const result: any = move(
                sourceArray,
                destinationArray,
                source,
                destination
            );
            console.log(result.questions.length, result.answers.length);
            this.props.setQuestionsAndAnswers(this.props.index, result.questions, result.answers);
        }
    }

    render() {
        const {classes, answer, question} = this.props;
        return(
            <Grid container spacing={0} direction="row" alignItems="flex-start">
                <DragDropContext onDragEnd={this.onDragEnd} >
                    <Grid xs={6} item style={{padding: 10, overflow: 'auto'}} >
                        <div style={{ textAlign: 'center' }} className={classes.leoListItemText}>Select the 5 statements that most apply to you and drag-drop them in the adjacent grey box – the box will turn green when 5 statements have been dropped into it</div>
                        <Droppable droppableId="questions">
                            {(provided, snapshot) => (
                                <List ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver, "questions")} >
                                    {question.questions.map((op: Leoquestions, opIndex) => (
                                        <Draggable draggableId={op.question} index={opIndex} key={op.question} isDragDisabled={answer.questions.length >= 5} >
                                            {(provided, snapshot) => (
                                                <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                    <ListItemText classes={{primary: classes.leoListItemText}}>{op.question}</ListItemText>
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </Grid>
                    <Grid xs={6} item style={{ padding: 10, overflow: 'auto' }}>
                        <div style={{ textAlign: 'center' }} className={classes.leoListItemText}>Rank the statements in the box below from 1 (Most applicable/ relevant) to 5 (Least applicable/ relevant) by moving them up/down</div>
                        <Droppable droppableId="answers">
                            {(provided, snapshot) => (
                                <List ref={provided.innerRef} style={this.getListStyle(snapshot.isDraggingOver, "answers")} >
                                    {answer.questions.map((rank, rankIndex) => (
                                        <Draggable draggableId={rank.question} index={rankIndex} key={rank.question}>
                                            {(provided, snapshot) => (
                                                <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                                    <ListItemAvatar className={classes.responsiveListItemAvatar}>
                                                        <Avatar className={classes.responsiveAvatar}>
                                                            {rankIndex + 1}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText classes={{ primary: classes.leoListItemText }}>{rank.question}</ListItemText>
                                                </ListItem>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </List>
                            )}
                        </Droppable>
                    </Grid>
                </DragDropContext>
            </Grid>
        )
    }
}

const styledLeoComponent: any = withStyles(generateStyles, { withTheme: true })(LeoComponent);
export { styledLeoComponent as LeoComponent };