import { ThemeProvider } from '@mui/material';
import React, { Component } from 'react';
import { Toaster } from 'react-hot-toast';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';
import LoadingMessage from './components/login/loading';
import Login from './components/login/login';
import ErrorBoundary from './components/main/errorBoundary';
import MainLayout from './components/main/main';
import {TestLayout} from './components/test/test';
import authService from './util/authService';
import history from './util/history';
import theme from './util/theme';

interface Props {}
interface State {
  loggingIn: boolean;
  loggedIn: boolean;
}

class App extends Component<Props, State> {

  state = {
    loggingIn: true,
    loggedIn: false
  }

  componentDidMount() {
    const loggedIn = authService.isLoggedIn();
    this.setState({ loggedIn, loggingIn: false });
  }

  render() {
    if (this.state.loggingIn) {
      return (<LoadingMessage />)
    }
    return (
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route path="/admin" component={MainLayout} />
              <Route path="/test" component={TestLayout} />
              <Redirect from="*" to={this.state.loggedIn ? "/admin/assessments" : "/login"} />
            </Switch>
          </Router>
          <Toaster position='top-right' />
        </ThemeProvider>
      </ErrorBoundary>
    );
  }

}

export default App;
