import React, { Component } from "react";
import { Editor, EditorState, getDefaultKeyBinding, RichUtils } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';

type SyntheticKeyboardEvent = React.KeyboardEvent<{}>;

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    }
}

function getBlockStyle(block: any) {
    switch (block.getType()) {
        case 'blockQuote': return 'RichEditor-blockquote';
        default: return '';
    }
}

interface StyleButtonProps {
    onToggle: any;
    style: any;
    active: any;
    label: string;
}

class StyleButton extends Component<StyleButtonProps> {
    onToggle: any;

    constructor(props: any) {
        super(props);
        this.onToggle = (e: any) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        )
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props: any) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props: any) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

interface State {
    editorState: EditorState;
    initialContent: string;
}

interface Props {
    setText: (text: string) => void;
    initialContent: string;
}

class RichEditor extends Component<Props, State> {
    onChange: any;
    handleKeyCommand: any;
    mapKeyToEditorCommand: any;
    toggleBlockType: any;
    toggleInlineStyle: any;

    constructor(props: any) {
        super(props);
        this.state = { 
            editorState: !this.props.initialContent ? EditorState.createEmpty() : EditorState.createWithContent(stateFromHTML(this.props.initialContent)),
            initialContent: this.props.initialContent
        };
        this.onChange = (editorState: EditorState) => {
            this.setState({ editorState, initialContent: stateToHTML(editorState.getCurrentContent()) });
            this.props.setText(stateToHTML(editorState.getCurrentContent()));
        };
        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    }

    _handleKeyCommand(command: string, editorState: EditorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    _mapKeyToEditorCommand(e: SyntheticKeyboardEvent) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return null;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType: any) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle: any) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    static getDerivedStateFromProps(props: Props, current_state: State) {
        if (props.initialContent !== current_state.initialContent) {
            return {
                editorState: !props.initialContent ? EditorState.createEmpty() : EditorState.createWithContent(stateFromHTML(props.initialContent)),
                initialContent: props.initialContent
            };
        }
        return null;
    }
    
    render() {
        const { editorState } = this.state;
        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (
            <div className="RichEditor-root">
                <BlockStyleControls
                    editorState={editorState}
                    onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                    editorState={editorState}
                    onToggle={this.toggleInlineStyle}
                />
                <div className={className}>
                    <Editor
                        editorState={editorState}
                        blockStyleFn={getBlockStyle}
                        customStyleMap={styleMap}
                        handleKeyCommand={this.handleKeyCommand}
                        keyBindingFn={this.mapKeyToEditorCommand}
                        onChange={this.onChange}
                        spellCheck={true}
                    />
                </div>
            </div>
        )
    }
}

export default RichEditor;