export interface RequestParams {
    method: string,
    url: string,
}

const auth: { [key: string]: RequestParams } = {
    login: {
        method: "POST",
        url: `/auth/realms/inqsight/protocol/openid-connect/token`
    },
    refreshTokens: {
        method: "POST",
        url: "/auth/refresh/tokens"
  },
  createUser: {
    method: "POST",
    url: "/admin/realms/heroes/users",
  },
  getUserRole: {
    method: "GET",
    url: "/getuser/role",
  },
};

const questions: any = {
    getQuestions: (type: string, assessmentId?: string) => ({
        method: 'GET',
        url: `/${type}` + (assessmentId ? `?assessmentId=${assessmentId}` : '')
    }),
    getQuestion: (type: string, id: string) => ({
        method: 'GET',
        url: `/${type}?_id=${id}`
    }),
    saveQuestion: (type: string) => ({
        method: 'POST',
        url: `/${type}`
    }),
    updateQuestion: (type: string, id: string) => ({
        method: 'PUT',
        url: `/${type}?_id=${id}`
    }),
    deleteQuestion: (type: string, id: string) => ({
        method: 'DELETE',
        url: `/${type}?_id=${id}`
    })
}

const groups: any = {
    getGroups: {
        method: 'GET',
        url: `/group`
    },
    getGroup: (id: string) => ({
        method: 'GET',
        url: `/group?_id=${id}`
    }),
    saveGroup: {
        method: 'POST',
        url: `/group`
    },
  getGroupList:(id:string)=>({
    method: "GET",
    url: `/group?user_id=${id}`
  }),
    updateGroup: (id: string) => ({
        method: 'PUT',
        url: `/group?_id=${id}`
    }),
    getGroupAssessments: (groupId: string) => ({
        method: 'GET',
        url: `/assessment?groupId=${groupId}`
    }),
    getUsers: (groupId: string) => ({
        method: 'GET',
        url: `/groupuser?groupId=${groupId}`
    }),
    deleteGroupUser: (userId: string) => ({
        method: 'DELETE',
        url: `/groupuser?_id=${userId}`
    }),
    addUsers: {
        method: 'POST',
        url: '/groupuser'
    },
    getScores: (groupId: string) => ({
        method: 'GET',
        url: `/group/dashboard?groupId=${groupId}`
    }),
    deleteGroup: (groupId: string) => ({
        method: 'DELETE',
        url: `/group/deleteGroup?_id=${groupId}`
    }),
   
}

const assessments: any = {
    addAssessment: {
        method: 'POST',
        url: `/assessment`
    },
    deleteAssessment: (id: string) => ({
        method: 'DELETE',
        url: `/assessment?_id=${id}`
    }),
    updateAssessment: (id: string) => ({
        method: 'PUT',
        url: `/assessment?_id=${id}`
    }),
    addUserAssessment: {
        method: 'POST',
        url: '/userassessment'
    },
    getGroupUserAssessments: (groupId: string, assessmentType?: string, fetchUnscored?: boolean) => ({
        method: 'GET',
        url: `/userassessment?groupId=${groupId}` + (assessmentType ? `&assessmentType=${assessmentType}` : '') + (fetchUnscored ? '&answered=true&scored=false' : '')
    }),
    sendEmails: (reminder: boolean) => ({
        method: 'POST',
        url: '/userassessment/email' + (reminder ? '?reminder=true' : '')
    }),
    fetchTest: (token: string) => ({
        method: 'GET',
        url: `/userassessment/test?token=${token}`
    }),
    submitAnswers: (testType: string, token: string) => ({
        method: 'POST',
        url: `/${testType}/answer?token=${token}`
    }),
    saveBEIScores: (userAssessmentId: string) => ({
        method: 'POST',
        url: `/bei/score?userAssessmentId=${userAssessmentId}`
    }),
    downloadUserResponse: (groupId: string,ass:string) => ({
        method: 'GET',
        url: `/userassessment/download?_id=${groupId}&type=${ass}`
    }),
    saveExitFullScreen:(token:string) =>({
        method: 'POST',
        url: `/userassessment/saveExitFullScreen?token=${token}`
    }),
    saveUserLocation:(token:string) =>({
        method: 'POST',
        url: `/userassessment/saveUserLocation?token=${token}`
    }),
    personalityProfileQuestions: (assessmentId: string) => ({
        method: 'GET',
        url: `/lpt/questions?_id=${assessmentId}`
    }),
    updateQuestions: () => ({
        method: 'PUT',
        url: `/lpt/updateQuestions`
    }),
    startTest: (token: string) => ({
        method: 'GET',
        url: `/userassessment/start?token=${token}`
    }),
    
}



const users: any = {
  getUsers: () => ({
    method: "GET",
    url: `/users/list`,
  }),
  changePassword: (id: string) => ({
    method: "PUT",
    url: `/users/change-password/?id=${id}`,
  }),
  saveUser: () => ({
    method: "POST",
    url: `/users/create`,
  }),
  deleteUser: (id: string) => ({
    method: "DELETE",
    url: `/users/delete-user/?id=${id}`,
  }),
  userDetail: (id: string) => ({
    method: "GET",
    url: `/users/user-details/?id=${id}`,
  }),
};

const upload: any = {
    uploadImage: {
        method: 'POST',
        url: '/image'
    }
}

const urlLibrary = {
    auth,
    questions,
    groups,
    upload,
    assessments,
  users,
}

export default urlLibrary;