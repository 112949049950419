import React, { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import BulletIcon from '@mui/icons-material/RadioButtonChecked';
import CommonInstructions from './commonInstruction'
interface Props {
    time: number | null; // minutes
    type: string;
}

class MCQInstructions extends Component<Props> {
    render() {
        const {type} = this.props;
        return (
            <React.Fragment>
                <h2>Welcome to {type === 'aptitude' ? 'Qranium' : 'the Job Qompetency Test'}</h2>
                <List>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            You will be presented with questions with multiple options. You must select the option that you think best answers the question.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Each question has 1 correct option
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            There is no negative score for incorrect responses
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time allowed: {this.props.time ? (this.props.time + " minutes") : <CircularProgress size="1rem" />}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time will begin when you press the start button below
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        Once you have pressed 'Start', you will need to complete the test within the given time - please do not close the window/ refresh the page/ stop the test midway. You will not be able to access this test again (through the link provided) once you have started.
                        </ListItemText>
                    </ListItem>
                </List>
                <CommonInstructions />
            </React.Fragment>
        )
    }
}

export default MCQInstructions;