import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BeiQuestion from "./beiQuestion";
import IntrayQuestion from "./intrayQuestion";
import AptitudeQuestion from "./aptitudeQuestion";
import SJTQuestion from "./sjtQuestion";
import JobSkillsQuestion from "./jobSkillsQuestion";

interface Props {
    match: any
}

class QuestionsLayout extends Component<Props> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/aptitude/:id`} component={AptitudeQuestion} />
                <Route path={`${this.props.match.path}/aptitude`} component={AptitudeQuestion} />
                <Route path={`${this.props.match.path}/jobskills/:id`} component={JobSkillsQuestion} />
                <Route path={`${this.props.match.path}/jobskills`} component={JobSkillsQuestion} />
                <Route path={`${this.props.match.path}/sjt/:id`} component={SJTQuestion} />
                <Route path={`${this.props.match.path}/sjt`} component={SJTQuestion} />
                <Route path={`${this.props.match.path}/intray/:id`} component={IntrayQuestion} />
                <Route path={`${this.props.match.path}/intray`} component={IntrayQuestion} />
                <Route path={`${this.props.match.path}/bei/:id`} component={BeiQuestion} />
                <Route path={`${this.props.match.path}/bei`} component={BeiQuestion} />
            </Switch>
        );
    }
}

export default QuestionsLayout;