import OrgIcon from '@mui/icons-material/AccountTree';
import InfoIcon from '@mui/icons-material/Info';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React from 'react';
import { Component } from 'react';
import Assessment from '../../../models/assessments.model';
import Mail, { InTray } from '../../../models/intray.model';
import { generateStyles } from '../../../util/mainStyles';
import { SimpleDialog } from '../../common/dialog';
import StartIcon from '@mui/icons-material/PlayCircleFilled';
import DoneIcon from '@mui/icons-material/CheckCircle';
import { CheckCircle } from '@mui/icons-material';

const mailPriorities = [
    {
        display: "Low",
        value: 0
    },
    {
        display: "Medium",
        value: 1
    },
    {
        display: "High",
        value: 2
    },
]

interface OwnProps {
    questions: InTray[];
    assessment: Assessment;
    setIntrayAnswer: (emailId: string, type: string, value: number) => void;
    answers: any;
    submitAnswers: () => void;
}
interface StyleProps {
    classes: any
}

type Props = OwnProps & StyleProps;

interface State {
    mails: Mail[];
    currentMail: Mail | null;
    selectedMailIndex: number;
    showModal: boolean;
    modalType: string;
}

class InTrayTest extends Component<Props, State>{

    state: State = {
        mails: (this.props.questions && this.props.questions.length > 0) ? this.props.questions[0].emails : [],
        currentMail: null,
        selectedMailIndex: -1,
        showModal: false,
        modalType: ''
    }

    selectMail = (index: number) => {
        const mails = [...this.state.mails];
        if (!mails[index].read) {
            mails[index].read = true;
        }
        this.setState({ ...this.state, mails, currentMail: mails[index], selectedMailIndex: index });
    }

    showInboxBrief = () => {
        this.setState({ ...this.state, showModal: true, modalType: 'inboxBrief' });
    }

    showOrgChart = () => {
        this.setState({ ...this.state, showModal: true, modalType: 'orgChart' });
    }

    hideModal = () => {
        this.setState({ ...this.state, showModal: false });
    }

    mailPriorityCheck = (emailId: string, value: number) => {
        for (const email of this.props.answers) {
            if (email.emailId === emailId && email.hmlAnswer === value) return true;
        }
        return false;
    }

    actionCheck = (emailId: string, value: number) => {
        for (const email of this.props.answers) {
            if (email.emailId === emailId && email.actionAnswer === value) return true;
        }
        return false;
    }

    render() {
        const { classes, questions, setIntrayAnswer, submitAnswers, answers } = this.props;
        const question = questions[0];
        const mails = question.emails;
        const { currentMail } = this.state;

        return (
            <>
                <ButtonGroup style={{ width: "100%", height: 70, paddingLeft: 10 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.showInboxBrief()}
                        style={{ textTransform: "none", width: "33%" }}
                        startIcon={<InfoIcon />}
                    >
                        inQbox Brief
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => this.showOrgChart()}
                        style={{ textTransform: "none", width: "33%" }}
                        startIcon={<OrgIcon />}
                    >
                        Show Organization Chart
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => submitAnswers()}
                        style={{ textTransform: "none", width: "33%" }}
                        startIcon={<StartIcon />}
                    >
                        Finish
                    </Button>
                </ButtonGroup>
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ position: "relative" }}>
                        <Grid container>
                            <Grid item xs={12} className={classes.mailList}>
                                <List>
                                    {mails.map((mail: any, mailIndex: number) => {
                                        return (
                                            <ListItemButton
                                                style={{ cursor: 'pointer' }}
                                                selected={(this.state.currentMail && mailIndex === this.state.selectedMailIndex) ? true : false}
                                                onClick={() => this.selectMail(mailIndex)} key={mail._id}
                                                classes={{ selected: classes.mailActive }}
                                            >
                                                 <ListItemAvatar>
                                                <Avatar className={classes.mailAvatar}>
                                                    {(answers[mailIndex].actionAnswer !== null && answers[mailIndex].hmlAnswer !== null) ?
                                                        <DoneIcon />
                                                        :
                                                        <span>{mailIndex + 1}</span>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography noWrap style={{fontWeight : (!mail.read ? 600 : 300)}}>{mail.subject}</Typography>
                                                }
                                                secondary={
                                                    <Typography>{mail.from}</Typography>
                                                }
                                            />
                                            </ListItemButton>
                                        )
                                    })}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.mailContent} key={this.state.currentMail?._id}>
                        {this.state.currentMail === null ? (
                            <Card style={{ marginTop: "15px", padding: "40px" }}>
                                <div style={{ textAlign: "center" }}>Select a mail on the left to see details</div>
                            </Card>
                        ) : (
                            <React.Fragment>
                                <Card style={{ marginTop: '15px' }}>
                                    <CardContent>
                                        <Typography variant="h5" color="text.primary" gutterBottom>
                                            {currentMail?.subject}
                                        </Typography>
                                        <Divider />
                                        <Typography color="text.secondary" gutterBottom style={{ marginTop: '10px' }} >
                                            From: {currentMail?.from}
                                        </Typography>
                                        <Typography color="text.secondary" gutterBottom>
                                            To: {currentMail?.to}
                                        </Typography>
                                        <Typography color="text.secondary" gutterBottom>
                                            Date: {currentMail?.date ? new Date(currentMail?.date).toLocaleString('en-in') : ''}
                                        </Typography>
                                        <Divider />
                                        <Typography color="text.primary" style={{ marginTop: '10px' }} dangerouslySetInnerHTML={{ __html: currentMail?.content || "" }} />
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '15px' }}>
                                    <CardContent>
                                        <Typography color="text.primary" gutterBottom sx={{ fontSize: '17px' }} >
                                            Select priority:
                                        </Typography>
                    <div className="proiority-button">
                                            <ButtonGroup variant="contained">
                                                {mailPriorities.map((priority, priorityIndex) => (
                                                    <Button onClick={() => setIntrayAnswer(currentMail?._id || "", "hmlAnswer", priority.value)} className={classes.mailButton} variant={this.mailPriorityCheck(currentMail?._id || "", priority.value) ? 'contained' : 'outlined'} color="error" key={priorityIndex.toString()}>
                                                        {priority.display}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card style={{ marginTop: '15px', marginBottom: '30px' }}>
                                    <CardContent>
                                        <Typography color="text.primary" gutterBottom sx={{ fontSize: '17px' }} >
                                            What action will you take?
                                        </Typography>
                    <div className={classes.intrayOptions}>
                                            <FormControl component="fieldset">
                                                <RadioGroup>
                                                    {currentMail!.actions.map((action, actionIndex) => (
                                                        <div style={{ minWidth: 400, marginBottom: 10 }}>
                                                            <FormControlLabel onClick={() => setIntrayAnswer(currentMail?._id || "", "actionAnswer", action.id)} key={actionIndex.toString()} style={{ textTransform: 'none' }} label={action.text} control={<Radio checked={this.actionCheck(currentMail?._id || "", action.id)} style={{ marginBottom: 10 }} />} />
                                                            {actionIndex < (currentMail?.actions.length || 0) &&
                                                                <Divider />
                                                            }
                                                        </div>
                                                    ))}
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </CardContent>
                                </Card>
                            </React.Fragment>
                        )}
                    </Grid>
                    <SimpleDialog open={this.state.showModal} title={this.state.modalType === 'inboxBrief' ? 'inQbox Brief' : 'Organization Chart'} content={this.state.modalType === 'inboxBrief' ? question.text : '<img src="https://inqimages.s3.amazonaws.com/dev/' + question.orgChart + '" alt="org chart" />'} onClose={this.hideModal} />
                </Grid>
            </>
        );
    }
}

const styledInTrayTest: any = withStyles(generateStyles, { withTheme: true })(InTrayTest);
export { styledInTrayTest as InTrayTest };