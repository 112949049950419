import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { CompetencyType } from '../../../models/competency.model';
import SJTScenario from '../../../models/sjt.model';
import { getQuestion, saveQuestion, updateQuestion } from '../../../util/apiService';
import history from '../../../util/history';
import RichEditor from '../../common/richeditor';

interface Props {
    match?: any;
    questionDetails?: SJTScenario;
    assessmentId: string;
}

interface State {
    scenario: SJTScenario
}

const getEmptyQuestion = () => {
    return { name: '', text: '', denominator: [], subScenarios: [{ text: '', options: [{ text: '', scores: [] }] }] };
}

class SJTQuestion extends Component<Props, State> {
    state: State = {
        scenario: this.props.questionDetails || getEmptyQuestion()
    }

    componentDidMount() {
        const {match} = this.props;
        if(match) {
            const {params} = match;
            if(params && params.id) {
                const getQuestionPromise = getQuestion("sjt", params.id)
                getQuestionPromise.then((response: any) => {
                    if(response && response.length > 0) {
                        this.setState({scenario: response[0]});
                    }
                });
                toast.promise(getQuestionPromise, {
                    loading: 'Fetching details',
                    success: 'Ready',
                    error: 'Fetching details failed'
                });
            }
        }
    }

    setText = (value: string) => {
        this.setState({...this.state, scenario: {...this.state.scenario, text: value}});
    }
    
    setName = (value: string) => {
        this.setState({...this.state, scenario: {...this.state.scenario, name: value}});
    }

    setSubScenarioText = (index: number, value: string) => {
        const scenario = {...this.state.scenario};
        scenario.subScenarios[index].text = value;
        this.setState({...this.state, scenario});
    }

    addSubScenario = () => {
        const scenario = {...this.state.scenario};
        scenario.subScenarios.push({text: '', options: []});
        this.setState({...this.state, scenario});
    }

    removeSubScenario = (subScenarioIndex: number) => {
        const { scenario } = this.state;
        const {subScenarios} = scenario;
        subScenarios.splice(subScenarioIndex, 1);
        if (subScenarios.length === 0) {
            subScenarios.push({ text: '', options: [{ text: '', scores: [] }]});
        }
    }

    removeOption = (subScenarioIndex: number, optionIndex: number) => {
        const { scenario } = this.state;
        const subScenario = scenario.subScenarios[subScenarioIndex];
        const options = subScenario.options;
        options.splice(optionIndex, 1);
        if (options.length === 0) {
            options.push({ text: '', scores: [] });
        }
        subScenario.options = options;
        this.setState({ ...this.state, scenario });
    }

    moveOption = (subScenarioIndex: number, optionIndex: number, step: number) => {
        const { scenario } = this.state;
        const subScenario = scenario.subScenarios[subScenarioIndex];
        const options = subScenario.options;
        const newIndex = optionIndex + step;
        if (newIndex >= 0 && newIndex < options.length) {
            const option = options[optionIndex];
            options.splice(optionIndex, 1);
            options.splice(newIndex, 0, option);
            this.setState({ ...this.state, scenario });
        }
    }

    addOption = (subSchenarioIndex: number) => {
        const { scenario } = this.state;
        const question = scenario.subScenarios[subSchenarioIndex];
        const options = question.options;
        options.push({ text: '', scores: [] });
        this.setState({ ...this.state, scenario });
    }

    setOption = (subScenarioIndex: number, optionIndex: number, value: string) => {
        const { scenario } = this.state;
        const question = scenario.subScenarios[subScenarioIndex];
        const options = question.options;
        options[optionIndex].text = value;
        this.setState({ ...this.state, scenario });
    }

    getDenominator = (competencyId: string) => {
        if(!this.state.scenario || !this.state.scenario.denominator) return 0;
        for(const d of this.state.scenario?.denominator) {
            if(d.competencyId === competencyId) return d.v;
        }
        return 0;
    }

    setDenominator = (competencyId: string, v: string) => {
        const {scenario} = this.state;
        const {denominator} = scenario;
        let found = false;
        for (const d of denominator) {
            if (d.competencyId === competencyId) {
                d.v = parseInt(v) || 0;
                found = true;
                break;
            }
        }
        if(!found) {
            denominator.push({competencyId, v: parseInt(v)})
        }
        this.setState({scenario})
    }

    getOptionScore = (subScenarioIndex: number, optionIndex: number, competencyId: string) => {
        const {scenario} = this.state;
        const subScenario = scenario.subScenarios[subScenarioIndex];
        const option = subScenario.options[optionIndex];
        for (const d of option.scores) {
            if (d.competencyId === competencyId) return d.v;
        }
        return 0;
    }

    setOptionScore = (subScenarioIndex: number, optionIndex: number, competencyId: string, v: string) => {
        const { scenario } = this.state;
        const subScenario = scenario.subScenarios[subScenarioIndex];
        const option = subScenario.options[optionIndex];
        let found = false;
        for (const d of option.scores) {
            if (d.competencyId === competencyId) {
                d.v = parseInt(v) || 0;
                found = true;
                break;
            }
        }
        if (!found) {
            option.scores.push({ competencyId, v: parseInt(v) })
        }
        this.setState({ scenario })
    }

    saveQuestion = () => {
        if(this.state.scenario._id) {
            const {_id, text, subScenarios, denominator, name} = this.state.scenario;
            const questionObject: any = {text, subScenarios, denominator, name};
            if(this.props.assessmentId) {
                questionObject.assessmentId = this.props.assessmentId;
            }
            const updateQuestionPromise = updateQuestion("sjt", _id, questionObject);
            updateQuestionPromise.then((response: any) => {
                
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(updateQuestionPromise, {
                loading: 'Updating question',
                success: 'Question updated',
                error: 'Error updating question'
            });
        } else {
            const saveQuestionPromise = saveQuestion("sjt", [{...this.state.scenario}])
            saveQuestionPromise.then((response: any) => {
                history.push('/admin/questions/sjt/' + response[0]._id);
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveQuestionPromise, {
                loading: 'Saving question',
                success: 'Question saved',
                error: 'Error saving question'
            });
        }
    }

    render() {
        const {scenario} = this.state;
        return(
            <div>
                <h3>Create SJT Question</h3>
                <div style={{ marginTop: 15 }}>
                    <TextField label='Internal name' style={{ width: '50%' }} onChange={(e) => this.setName(e.target.value)} value={scenario.name} />
                </div>
                <div style={{ marginTop: 15 }}>
                    Description
                </div>
                <div style={{ marginTop: 15 }}>
                    <RichEditor setText={this.setText} initialContent={scenario.text} />
                </div>
                <div style={{ marginTop: 15 }}>
                    Denominators
                </div>
                <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                    { CompetencyType.map((cType, cTypeIndex) => (
                        <div style={{flex: 1, margin: '0 10px'}} key={cTypeIndex}>
                            <TextField type="number" label={cType.competency + ' Denominator'} value={this.getDenominator(cType.id)} onChange={(e) => this.setDenominator(cType.id, e.target.value)} style={{textAlign: 'right'}} />
                        </div>
                    ))}
                </div>
                <h3>Sub-Scenarios</h3>
                { scenario.subScenarios?.map((subScenario, subScenarioIndex) => (
                    <React.Fragment key={subScenarioIndex} >
                        <div style={{ marginTop: 15 }}>
                            <h4>
                                SubScenario {subScenarioIndex + 1}
                                <IconButton aria-label="delete" color="error" onClick={() => this.removeSubScenario(subScenarioIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                            </h4>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <RichEditor setText={(text) => this.setSubScenarioText(subScenarioIndex, text)} initialContent={subScenario.text} />
                        </div>
                        <h5>Options</h5>
                        <ul>
                            { subScenario.options.map((option, optionIndex) => (
                                <li style={{ lineHeight: '5em' }} key={optionIndex} >
                                    <div>
                                        <TextField label={'Option ' + (optionIndex + 1)} style={{ width: '80%', marginTop: 15 }} value={option.text} onChange={(e) => this.setOption(subScenarioIndex, optionIndex, e.target.value)} />
                                        <IconButton aria-label="delete" onClick={() => this.removeOption(subScenarioIndex, optionIndex)}>
                                            <DeleteIcon />
                                        </IconButton>
                                        {optionIndex !== 0 &&
                                            <IconButton aria-label="move up" onClick={() => this.moveOption(subScenarioIndex, optionIndex, -1)}>
                                                <ArrowUpIcon />
                                            </IconButton>
                                        }
                                        {optionIndex !== subScenario.options.length - 1 &&
                                            <IconButton aria-label="delete" onClick={() => this.moveOption(subScenarioIndex, optionIndex, 1)}>
                                                <ArrowDownIcon />
                                            </IconButton>
                                        }
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        Option Scores
                                    </div>
                                    <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                                        {CompetencyType.map((cType, cTypeIndex) => (
                                            <div style={{ flex: 1, margin: '0 10px' }} key={cTypeIndex}>
                                                <TextField type="number" label={cType.competency + ' Score'} value={this.getOptionScore(subScenarioIndex, optionIndex, cType.id)} onChange={(e) => this.setOptionScore(subScenarioIndex, optionIndex, cType.id, e.target.value)} style={{ textAlign: 'right' }} />
                                            </div>
                                        ))}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div style={{ marginTop: 15 }}>
                            <Button variant="contained" color="primary" onClick={() => this.addOption(subScenarioIndex)}>
                                Add Option
                            </Button>
                        </div>
                        <Divider style={{marginTop: 15}} />
                    </React.Fragment>
                ))}
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="primary" onClick={() => this.addSubScenario()}>
                        Add SubScenario
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="error" onClick={() => this.saveQuestion()}>
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

export default SJTQuestion;