import UserAssessment,{SaveExitFullScreen, userLocation} from "../models/userassessment.model";
import { adminRequest, testRequest } from "./fetchService"
import urlLibrary from "./urlLibrary"
import {UserCredential } from "../models/user.model"

export const saveQuestion = (type: string, questionDef: any) => {
    return adminRequest(urlLibrary.questions.saveQuestion(type), questionDef);
}

export const updateQuestion = (type: string, id: string, questionDef: any) => {
    return adminRequest(urlLibrary.questions.updateQuestion(type, id), questionDef);
}

export const getQuestions = (type: string, assessmentId?: string) => {
    const request = urlLibrary.questions.getQuestions(type, assessmentId);
    return adminRequest(request);
}

export const getQuestion = (type: string, id: string) => {
    const request = urlLibrary.questions.getQuestion(type, id);
    return adminRequest(request);
}

export const deleteQuestion = (type: string, id: string) => {
    const request = urlLibrary.questions.deleteQuestion(type, id);
    return adminRequest(request);
}

export const saveGroup = (groupDef: any) => {
    return adminRequest(urlLibrary.groups.saveGroup, groupDef);
}

export const updateGroup = (id: string, groupDef: any) => {
    return adminRequest(urlLibrary.groups.updateGroup(id), groupDef);
}

export const getGroups = () => {
    return adminRequest(urlLibrary.groups.getGroups);
}
export const getGroupList = (id:string) => {
    const request = urlLibrary.groups.getGroupList(id);
    return adminRequest(request);
}

export const getGroup = (id: string) => {
    const request = urlLibrary.groups.getGroup(id);
    return adminRequest(request);
}

export const addAssessment = (groupId: string, assessmentType: string) => {
    const request = urlLibrary.assessments.addAssessment;
    return adminRequest(request, [{ groupId, assessmentType }]);
}

export const updateAssessment = (assessmentId: string, assessmentDef: any) => {
    const request = urlLibrary.assessments.updateAssessment(assessmentId);
    return adminRequest(request, assessmentDef);
}

export const deleteAssessment = (assessmentId: string) => {
    const request = urlLibrary.assessments.deleteAssessment(assessmentId);
    return adminRequest(request);
}

export const getGroupAssessments = (groupId: string) => {
    const request = urlLibrary.groups.getGroupAssessments(groupId);
    return adminRequest(request);
}

export const addGroupUsers = (users: any[]) => {
    const request = urlLibrary.groups.addUsers;
    return adminRequest(request, users);
}

export const deleteGroupUser = (userId: string) => {
    const request = urlLibrary.groups.deleteGroupUser(userId);
    return adminRequest(request);
}

export const getGroupUsers = (groupId: string) => {
    const request = urlLibrary.groups.getUsers(groupId);
    return adminRequest(request);
}

export const addUserAssessments = (userAssessments: UserAssessment[]) => {
    const request = urlLibrary.assessments.addUserAssessment;
    return adminRequest(request, userAssessments);
}

export const getGroupUserAssessments = (groupId: string, assessmentType?: string, fetchUnscored?: boolean) => {
    const request = urlLibrary.assessments.getGroupUserAssessments(groupId, assessmentType, fetchUnscored);
    return adminRequest(request);
}

export const sendEmails = (userAssessmentIds: string[], reminder: boolean) => {
    const request = urlLibrary.assessments.sendEmails(reminder);
    return adminRequest(request, userAssessmentIds);
}

export const fetchTest = (token: string) => {
    const request = urlLibrary.assessments.fetchTest(token);
    return testRequest(request);
}

export const submitAnswers = (testType: string, token: string, answers: any) => {
    const request = urlLibrary.assessments.submitAnswers(testType, token);
    return testRequest(request, answers);
}

export const getScores = (groupId: string) => {
    const request = urlLibrary.groups.getScores(groupId);
    return adminRequest(request);
}

export const saveBEIScores = (userAssessmentId: string, scores: any) => {
    const request = urlLibrary.assessments.saveBEIScores(userAssessmentId);
    return adminRequest(request, scores);
}

export const uploadImage = (file: File) => {
    const formData = new FormData();
    formData.append("filename", file);
    const request = urlLibrary.upload.uploadImage;
    return adminRequest(request, formData,null, "file");
}

export const deleteGroup = (groupId:string) => {
    const request = urlLibrary.groups.deleteGroup(groupId);
    return adminRequest(request);
}
export const downloadUserResponse = (groupId:string,ass:string) => {
    const request = urlLibrary.assessments.downloadUserResponse(groupId,ass);
    return adminRequest(request);
}

export const saveExitFullScreen = (params:SaveExitFullScreen,token:string) => {
    const request = urlLibrary.assessments.saveExitFullScreen(token)
    return testRequest(request, params);
}

export const saveUserLocation = (params:userLocation,token:string) => {
    const request = urlLibrary.assessments.saveUserLocation(token)
    return testRequest(request, params);
}
export const personalityProfileQuestions = (assessmentId:string) => {
    const request = urlLibrary.assessments.personalityProfileQuestions(assessmentId);
    return adminRequest(request);
}
export const updateQuestions = (questions:any) => {
    const request = urlLibrary.assessments.updateQuestions();
    return adminRequest(request,questions);
}
export const saveQuestion2 = (type: string, questionDef: any) => {
    return adminRequest(urlLibrary.questions.saveQuestion(type), questionDef);
}

export const getUserRole = ()=>{
    return adminRequest(urlLibrary.auth.getUserRole);
}

export const getUsers = () => {
    const request = urlLibrary.users.getUsers();
    return adminRequest(request);
}
export const changePassword = (id:string, param:any) => {
    const request = urlLibrary.users.changePassword(id);
    return adminRequest(request, param);
}
export const saveUser = (userParams:any) => {
    const request = urlLibrary.users.saveUser(userParams);
    return adminRequest(request, userParams);
}
export const deleteUserById = (id:string) => {
    const request = urlLibrary.users.deleteUser(id);
    return adminRequest(request);
}
export const userDetailById = (id:string) => {
    const request = urlLibrary.users.userDetail(id);
    return adminRequest(request);
}
export const startTest = (token: any) => {
    const request = urlLibrary.assessments.startTest(token);
    return testRequest(request);
}
