import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import Aptitude, {aptitudeCompetencies} from '../../../../models/aptitude.model';
import { addAssessment, deleteAssessment, deleteQuestion, getQuestions, saveQuestion, updateQuestion } from '../../../../util/apiService';
import ellipsify from '../../../../util/ellipsify';
import AptitudeQuestion from '../../questions/aptitudeQuestion';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AptitudeQuestionPicker } from '../../../common/aptitudeQuestionPicker';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string, deleteIndex: number) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}
interface State {
    aptitudeIncluded: boolean;
    questions: any;
    selectedQuestions: {[key: string]: Aptitude[]} | null;
    autocompleteValue: any;
    numQuestions: {[key: string]: number};
    questionDifficulties: { [key: string]: number };
    questionPickerShown: boolean;
    shortlistedQuestions: any;
}

const assessmentType = "aptitude";
const blankLookup = { qa: [], di: [], va: [], lr: [] };

class AptitudeDetails extends Component<Props, State> {

    state: State = {
        aptitudeIncluded: this.props.assessment ? true : false,
        questions: [],
        selectedQuestions: null,
        autocompleteValue: null,
        numQuestions: { qa: 0, di: 0, va: 0, lr: 0},
        questionDifficulties: { qa: -1, di: -1, va: -1, lr: -1},
        questionPickerShown: false,
        shortlistedQuestions: []
    }

    questionBank: { [key: string]: Aptitude[] } = { ...blankLookup };

    componentDidMount() {
        if (this.props.assessment) {
            const getAssessmentQuestionsPromise = getQuestions(assessmentType, this.props.assessment._id);
            getAssessmentQuestionsPromise.then((response: any) => {
                const selectedQuestions: any = {};
                for(const question of response) {
                    if(question.category in selectedQuestions) {
                        selectedQuestions[question.category].push(question);
                    } else {
                        selectedQuestions[question.category] = [question];
                    }
                }
                this.setState({ selectedQuestions });
            });
            toast.promise(getAssessmentQuestionsPromise, {
                loading: 'Fetching assessment questions',
                success: 'Assessment questions fetched',
                error: 'Error fetching assessment questions'
            });
        }
        
        if (this.state.questions.length === 0) {
            const getQuestionsPromise = getQuestions(assessmentType);
            getQuestionsPromise.then((response: any) => {
                this.setState({ questions: response })
            });
            toast.promise(getQuestionsPromise, {
                loading: 'Fetching question bank',
                success: 'Question bank fetched',
                error: 'Error fetching question bank'
            });
        }
    }

    addQuestion = (aptitudeQuestion: Aptitude | null) => {
        if (!aptitudeQuestion) return;
        const { image, category, question, options, answer, level, priority } = aptitudeQuestion;
        const questionObject: Aptitude = { image, category, question, options, answer, level, priority };
        questionObject.assessmentId = this.props.assessment._id;
        const saveQuestionPromise = saveQuestion(assessmentType, [{ ...questionObject }]);
        saveQuestionPromise.then((response: any) => {
            let { selectedQuestions } = this.state;
            if(selectedQuestions) {
                if(response[0].category in selectedQuestions) {
                    selectedQuestions[response[0].category].push(response[0]);
                } else {
                    selectedQuestions[response[0].category] = [response[0]];
                }
            } else {
                selectedQuestions = {[response[0].category]: [response[0]]};
            }
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(saveQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    updateQuestion = (aptitudeQuestion: Aptitude | null, newPriority: number) => {
        if (!aptitudeQuestion) return;
        const updateQuestionPromise = updateQuestion(assessmentType, aptitudeQuestion._id || "", aptitudeQuestion);
        updateQuestionPromise.then((response: any) => {
            let { selectedQuestions } = this.state;
            if(selectedQuestions) {
                for(const q of selectedQuestions[aptitudeQuestion.category]) {
                    if(q._id === aptitudeQuestion._id) {
                        q.priority = newPriority;
                        break;
                    }
                }
            }
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(updateQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    setNumQuestions = (id: string, value: string) => {
        const {numQuestions} = this.state;
        numQuestions[id] = parseInt(value);
        this.setState({...this.state, numQuestions});
    }
    
    setQuestionDifficulties = (id: string, value: any) => {
        const {questionDifficulties} = this.state;
        questionDifficulties[id] = parseInt(value);
        this.setState({...this.state, questionDifficulties});
    }

    includeAptitude(aptitudeIncluded: boolean) {
        if (aptitudeIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, assessmentType)
            addAssessmentPromise.then((response) => {
                this.props.addAssessment(assessmentType, response);
                this.setState({ aptitudeIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding assessment ...',
                success: 'Added assessment',
                error: 'Adding assessment failed'
            });

            if (this.state.questions.length === 0) {
                const getQuestionsPromise = getQuestions(assessmentType);
                getQuestionsPromise.then((response: any) => {
                    this.setState({ questions: response })
                });
                toast.promise(getQuestionsPromise, {
                    loading: 'Fetching question bank',
                    success: 'Question bank fetched',
                    error: 'Error fetching question bank'
                });
            }
        } else {
            var shouldDelete = window.confirm('Delete Aptitude assessment and all questions?');
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(assessmentType, 0);
                this.setState({ aptitudeIncluded: false, selectedQuestions: {...blankLookup} });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting assessment ...',
                success: 'Deleted assessment',
                error: 'Deleting assessment failed'
            });
        }
    }

    fillQuestions = () => {
        const {numQuestions, questionDifficulties, questions, selectedQuestions} = this.state;
        for(const category in numQuestions) {
            if(numQuestions[category] > 0) {
                let categoryQuestions = [];
                if(questionDifficulties[category] > -1) {
                    categoryQuestions = questions.filter((q: any) => q.category === category && q.level === questionDifficulties[category]);
                } else {
                    categoryQuestions = questions.filter((q: any) => q.category === category);
                }
                let currentPriority = 0;
                if (selectedQuestions && category in selectedQuestions && selectedQuestions[category].length > 0) {
                    const questionDetails = selectedQuestions[category].map((q: any) => q.question);
                    categoryQuestions = categoryQuestions.filter((q: any) => !questionDetails.includes(q.question));
                    const lastQuestion = selectedQuestions[category][selectedQuestions[category].length - 1];
                    currentPriority = (lastQuestion.priority || 0) + 1;
                }
                const shuffled = categoryQuestions.sort(() => 0.5 - Math.random());
                const selected = shuffled.slice(0, numQuestions[category]);
                for(var i=0; i<selected.length; i++) {
                    selected[i].priority = currentPriority;
                    currentPriority++;
                    this.addQuestion(selected[i]);
                }
            }
        }
    }

    deleteQuestion = (category: string, qIndex: number, event: any) => {
        event.stopPropagation();
        var shouldDelete = window.confirm('Delete question?');
        if (!shouldDelete) {
            return;
        }
        const question: any = this.state.selectedQuestions![category][qIndex];
        const deleteQuestionPromise = deleteQuestion(assessmentType, question._id);
        deleteQuestionPromise.then(() => {
            const { selectedQuestions } = this.state;
            selectedQuestions![category].splice(qIndex, 1);
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(deleteQuestionPromise, {
            loading: 'Deleting question ...',
            success: 'Deleted question',
            error: 'Deleting question failed'
        });
    }

    getQuestions = (category: string) => {
        const {questions, questionDifficulties, selectedQuestions} = this.state;
        const selectedQuestionTexts = (selectedQuestions && category in selectedQuestions) ? selectedQuestions[category].map((q: any) => q.question): [];
        if(questionDifficulties[category] > -1) {
            return questions.filter((q: any) => q.category === category && q.level === questionDifficulties[category] && !selectedQuestionTexts.includes(q.question));
        }
        return questions.filter((q: any) => {
            return q.category === category && !selectedQuestionTexts.includes(q.question);
        });
    }

    toggleQuestionPicker = (competencyType?: string) => {
        if(competencyType) {
            this.setState({questionPickerShown: !this.state.questionPickerShown, shortlistedQuestions: this.getQuestions(competencyType)});
        } else {
            this.setState({questionPickerShown: !this.state.questionPickerShown});
        }
    }

    addQuestionsFromPicker = (questions: Aptitude[]) => {
        if(questions.length > 0) {
            const {selectedQuestions} = this.state;
            let currentPriority = 0;
            if(selectedQuestions && questions[0].category in selectedQuestions && selectedQuestions[questions[0].category].length > 0) {
                const lastQuestion = selectedQuestions[questions[0].category][selectedQuestions[questions[0].category].length - 1];
                currentPriority = (lastQuestion.priority || 0) + 1;
            }
            for(let q of questions) {
                q.priority = currentPriority;
                currentPriority++;
                this.addQuestion(q);
            }
        }
        this.setState({questionPickerShown: false});
    }

    moveQuestion = (e: any, category: string, questionIndex: number, movement: number) => {
        e.stopPropagation();
        const orderedQuestions = this.getSortedQuestions(category);
        const replacementQuestionIndex = questionIndex + movement;
        const replaceQuestion = orderedQuestions[replacementQuestionIndex];
        const currentQuestion = orderedQuestions[questionIndex];
        const replacementQuestionPriority = orderedQuestions[replacementQuestionIndex].priority;
        replaceQuestion.priority = orderedQuestions[questionIndex].priority;
        currentQuestion.priority = replacementQuestionPriority;
        this.updateQuestion(replaceQuestion, replaceQuestion.priority || 0);
        this.updateQuestion(currentQuestion, currentQuestion.priority || 0);
    }

    getSortedQuestions = (category: string) => {
        const sortedQuestions = this.state.selectedQuestions![category].sort((a, b) => (a.priority !== undefined && b.priority !== undefined) ? a.priority - b.priority : 0)
        return sortedQuestions;
    }

    render() {
        const { aptitudeIncluded, selectedQuestions, numQuestions, questionDifficulties, questionPickerShown, shortlistedQuestions } = this.state;
        const { assessment, setAssessmentTime, saveAssessmentTime } = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={aptitudeIncluded} onChange={(e) => this.includeAptitude(e.target.checked)} />} label="Include Aptitude" />
                </FormGroup>
                {aptitudeIncluded &&
                    <React.Fragment>
                        <div style={{ marginTop: 15 }}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{flex: 8}}>
                                    <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime(assessmentType, e.target.value)} type="number" />
                                </div>
                                <div style={{flex: 2, textAlign: 'left', marginLeft: 20}}>
                                    <Button variant="contained" color="primary" onClick={() => saveAssessmentTime(assessmentType)}>Save</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                            {aptitudeCompetencies.map((cType, cTypeIndex) => (
                                <div style={{ flex: 1, margin: '0 10px', flexDirection: 'column' }} key={cTypeIndex}>
                                    <div style={{flex: 1}}>
                                        <TextField type="number" label={cType.display} value={numQuestions[cType.id]} onChange={(e) => this.setNumQuestions(cType.id, e.target.value)} style={{ textAlign: 'right' }} />
                                    </div>
                                    <div style={{flex: 1, marginTop: 15}}>
                                        <FormControl fullWidth>
                                            <InputLabel>Select difficulty:</InputLabel>
                                            <Select value={questionDifficulties[cType.id]} label="Select difficulty:" onChange={(e) => this.setQuestionDifficulties(cType.id, e.target.value)}>
                                                <MenuItem value={-1} key={-1}>Any</MenuItem>
                                                {["Low", "Medium", "High"].map((level: string, dIndex: number) => (
                                                    <MenuItem value={dIndex} key={dIndex}>{level}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Button color="primary" variant="contained" onClick={this.fillQuestions} >Autofill Random Questions</Button>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            {aptitudeCompetencies.map((cType, cIndex: number) => (
                                <React.Fragment key={cIndex}>
                                    <div style={{ marginTop: 15 }}>
                                        <h3>Selected {cType.display} Questions</h3>
                                    </div>
                                    {selectedQuestions && (cType.id in selectedQuestions) && this.getSortedQuestions(cType.id).map((q, qIndex) => (
                                        <Accordion defaultExpanded={false} key={qIndex}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                    <Typography sx={{ flexShrink: 0, flex: 8 }}>
                                                        <div dangerouslySetInnerHTML={{__html: q.question}} />
                                                    </Typography>
                                                    <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                                                        { qIndex !== 0 &&
                                                            <IconButton onClick={(e) => this.moveQuestion(e, cType.id, qIndex, -1)} >
                                                                <UpIcon />
                                                            </IconButton>
                                                        }
                                                        { qIndex !== selectedQuestions[cType.id].length - 1 &&
                                                            <IconButton onClick={(e) => this.moveQuestion(e, cType.id, qIndex, 1)} >
                                                                <DownIcon />
                                                            </IconButton>
                                                        }
                                                        <IconButton onClick={(e) => this.deleteQuestion(q.category, qIndex, e)} >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <AptitudeQuestion questionDetails={q} assessmentId={assessment?._id} />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                    <div style={{ marginTop: 15 }}>
                                        <Button variant="contained" color="primary" onClick={() => this.toggleQuestionPicker(cType.id)} >Select Questions</Button>
                                    </div>
                                </React.Fragment>
                            ))}
                            <AptitudeQuestionPicker open={questionPickerShown} onClose={() => this.toggleQuestionPicker()} questions={shortlistedQuestions} addQuestionsFromPicker={this.addQuestionsFromPicker} />
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default AptitudeDetails;