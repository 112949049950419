import React, { Component } from "react";
import Button from '@mui/material/Button';
import history from "../../../util/history";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { getGroups, deleteGroup, getUserRole, getGroupList } from "../../../util/apiService";
import toast from "react-hot-toast";
import Group from "../../../models/group.model";
import IconButton from "@mui/material/IconButton";
import GridViewIcon from '@mui/icons-material/GridView';
import ReviewIcon from '@mui/icons-material/RateReview';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props { }

interface State {
    assessments: any[];
    isAdmin: boolean,
    userId: string
}

class AssessmentsList extends Component<Props, State> {

    state = {
        assessments: [],
        isAdmin: false,
        userId: '',
        userResponse: []
    }

    componentDidMount() {
        const userDetailInfo = localStorage.getItem('userDetails');
        if (userDetailInfo) {
            const userDetails = JSON.parse(userDetailInfo);
            console.log("userDetails",userDetails)
            const userId = userDetails.userDetails.id;
            const isAdmin =  userDetails.userRole.realmMappings.length && userDetails.userRole.realmMappings.find((val:any )=> val.name === "admin");
            if(isAdmin){
                this.setState({ isAdmin: true ,userId: userId})
            }
            console.log("isAdmin",isAdmin,userId)
            let me = this
            this.setState({userId: userId}, function(){
                me.getAssessmentList()
             });
        }
       
    }

    deleteGroup = (groupId: string) => {
        var shouldDelete = window.confirm('Note that this deletion cannot be reversed. You should download the dashboard & responses for the assessment before deleting the assessment. Proceed with deletion?');
        if (!shouldDelete) {
            return;
        }
        const deleteGroupPromise = deleteGroup(groupId)
        deleteGroupPromise.then((response: any) => {
            const { assessments } = this.state
            const newList = assessments.filter((item: any) => item._id !== groupId);
            this.setState({ assessments: newList })
        });
        toast.promise(deleteGroupPromise, {
            loading: 'Deleting group',
            success: 'Group  deleted',
            error: 'Error deleting groups'
        })
    }
    getAssessmentList = () => {
        
        const getGroupsPromise = this.state.isAdmin ? getGroups() : getGroupList(this.state.userId);
        getGroupsPromise.then((response: any) => {
            this.setState({ assessments: response })
        });
        toast.promise(getGroupsPromise, {
            loading: 'Fetching groups',
            success: 'Groups fetched',
            error: 'Error fetching groups'
        })
    }


    getSecondaryText = (q: any) => {
        return 'Created: ' + (q.dc ? new Date(q.dc).toLocaleDateString('en-in', { year: 'numeric', month: 'short', day: 'numeric' }) : 'NA');
    }

    render() {
        const { assessments } = this.state;
        return (
            <div>
                <h3>
                    List of Stored Assessments
                    <Button style={{ marginLeft: 30 }} variant="contained" color='primary' onClick={() => history.push(`/admin/assessments/create`)}>New Assessment</Button>
                </h3>
                <List>
                    {assessments.map((a: Group, aIndex: number) => (
                        <React.Fragment key={aIndex}>
                            <ListItem
                                key={aIndex}
                                secondaryAction={
                                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                        <div style={{ display: "flex", flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }} onClick={() => history.push(`/admin/assessments/dashboard/${a._id}`)}>
                                            <div style={{ flex: 1, textAlign: 'center' }}>
                                                <IconButton><GridViewIcon /> </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                DASHBOARD
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }} onClick={() => history.push(`/admin/bei/list/${a._id}`)}>
                                            <div style={{ flex: 1, textAlign: 'center' }}>
                                                <IconButton><ReviewIcon /> </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                BEI
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }} onClick={() => this.deleteGroup(`${a._id}`)}>
                                            <div style={{ flex: 1, textAlign: 'center' }}>
                                                <IconButton><DeleteIcon /> </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                Delete
                                            </div>
                                        </div>
                                    </div>
                                }>
                                <ListItemButton style={{ maxWidth: '100%' }} onClick={() => history.push(`/admin/assessments/create/${a._id}`)} >
                                    <ListItemIcon>
                                        <Avatar>
                                            {aIndex + 1}
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText primaryTypographyProps={{ style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }} primary={a.name + " - " + a.clientName} secondary={this.getSecondaryText(a)} />
                                </ListItemButton>
                            </ListItem>
                            {(aIndex < assessments.length - 1) &&
                                <Divider />
                            }
                        </React.Fragment>
                    ))}
                </List>
            </div>
        )
    }
}

export default AssessmentsList;