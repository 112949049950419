import axios, {AxiosError, Method} from 'axios';
import authService from './authService';
import urlLibrary, { RequestParams } from './urlLibrary';

export const methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT'
}

interface request {
    url?: string
    method: Method,
    headers: { [key: string]: string }
    body?: any,
    data?: any
}

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(async (response: any) => {
    const {data} = response;
    if('data' in data) {
        return data.data;
    }
    return data;
}, async(error: AxiosError) => {
    const originalRequest: any = {...error.config};
    if(error.response?.status === 401) {
        authService.logout();
    }
    if(error.response?.status === 403 && !error.config.url!.includes(urlLibrary.auth.login.url) && !originalRequest._retry) {
        const tokenRefresh = await authService.refreshTokens();
        if(tokenRefresh) {
            originalRequest._retry = true;
            const token = authService.getToken();
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axiosInstance(originalRequest);
        }
    }

    const errorResponse: any = error.response;
    if(errorResponse) {
        const {data} = errorResponse;
        const {status, message} = data;
        return Promise.reject(status || message || 'Unable to connect to server');
    }

    return Promise.reject(error.message || "Unable to complete this operation");
})

async function handleResponse(response: Response) {
    if (response.status === 401 || response.status === 403) {
        authService.logout();
    }
    if (response.ok) {
        return response.text().then((text: string) => {
            const data = text && JSON.parse(text);
            if ('data' in data) {
                return data.data
            }
            return data;
        });
    }
    try {
        const responseText = await response.text();
        const json = responseText && JSON.parse(responseText);
        if (json) return Promise.reject(json.message);
    } catch (err) {
        console.log(err);
        return Promise.reject(response.statusText || "Unable to parse server response");
    }
    return Promise.reject(response.statusText || "Unable to connect to server");
}

export function doLogin(requestParams: RequestParams, data: any = null) {
    let finalRequestParams: RequestParams = { ...requestParams };
    const requestOptions: request = {
        method: requestParams.method as Method,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }
    if (data) {
        var formBody = [];
        for (var property in data) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(data[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        requestOptions.body = formBody.join("&");

    }
    finalRequestParams.url = process.env.REACT_APP_LOGIN_BASE_URL+ finalRequestParams.url;
    console.log("finalRequestParams.url",finalRequestParams.url,process.env.REACT_APP_LOGIN_BASE_URL)
    return fetch(finalRequestParams.url, requestOptions).then(handleResponse);
}

export async function adminRequest(requestParams: RequestParams, data: { [key: string]: any } | null = null, headers: { [key: string]: string } | null = null, type: string = "json") {
    let finalRequestParams: RequestParams = { ...requestParams };
    if (process.env.NODE_ENV === 'production') {
        finalRequestParams.url = process.env.REACT_APP_API_PROD_URL + finalRequestParams.url;
    } else {
        finalRequestParams.url = process.env.REACT_APP_API_DEV_URL + finalRequestParams.url;
    }
    const baseHeaders = type === "json" ? authService.authHeader() : authService.uploadHeader();
    const requestHeaders = { ...baseHeaders, ...headers };
    const requestOptions: request = {
        url: finalRequestParams.url,
        method: requestParams.method as Method,
        headers: requestHeaders
    }
    if (data) {
        requestOptions.data = data;
    }
    return axiosInstance(requestOptions);
}

export async function testRequest(requestParams: RequestParams, data?: any) {
    let finalRequestParams: RequestParams = { ...requestParams };
    if (process.env.NODE_ENV === 'production') {
        finalRequestParams.url = process.env.REACT_APP_API_PROD_URL + finalRequestParams.url;
    } else {
        finalRequestParams.url = process.env.REACT_APP_API_DEV_URL + finalRequestParams.url;
    }
    const requestOptions: request = {
        method: requestParams.method as Method,
        headers: { 'Content-Type': 'application/json' },
    }
    if (data) {
        requestOptions.body = JSON.stringify(data);
    }

    return fetch(finalRequestParams.url, requestOptions).then(handleResponse);
}