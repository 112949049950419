import { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@mui/styles";
import { generateStyles } from "../../util/mainStyles";

interface OwnProps {
    open: boolean;
    onClose: any;
    title: any
    content: any;
    classes: any;
    persistent?: boolean;
    showCloseButton?: boolean;
    submitAnswers?: (check?: boolean) => void | null | undefined;
}

class SimpleDialog extends Component<OwnProps> {

    render() {
        const {open, onClose, title, content, classes, persistent, showCloseButton, submitAnswers} = this.props;

        return (
            <Dialog 
                open={open} 
                onClose={onClose} 
                closeAfterTransition 
                maxWidth="md" 
            >
                <DialogTitle style={{position: 'relative'}}>
                    <Typography sx={{fontSize: 25}} >
                        {title}
                    </Typography>
                    { !persistent &&
                        <IconButton className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                </DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{__html: content}} />
                    {showCloseButton &&
                        <Button style={{marginTop: 30}} onClick={() => { window.location.href="https://google.com"}} color="error" variant="contained">Close</Button>
                    }
                    {submitAnswers &&
                        <Button style={{marginTop: 30}} onClick={() => submitAnswers(false)} color="error" variant="contained">Submit</Button>
                    }
                </DialogContent>
            </Dialog>
        )
    }
}

const styledSimpleDialog: any = withStyles(generateStyles, {withTheme: true})(SimpleDialog);
export {styledSimpleDialog as SimpleDialog};