import React, { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import BulletIcon from '@mui/icons-material/RadioButtonChecked';
import CommonInstructions from './commonInstruction'
interface Props {
    time: number | null; // minutes
}

class BEIInstructions extends Component<Props> {
    render() {
        return (
            <>
                <h2>Welcome to critiQal</h2>
                <List>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            This exercises explores how you have handled different professional challenges/ situations in your past. You are presented below with some work scenarios/ questions.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            You have to give <span style={{ textDecoration: 'underline', fontWeight: 'bold'}}>real examples</span> from your own professional experience in response to each question asked. 
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Please give responses only from your professional experience
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            The responses should illustrate your actions on how you dealt with the different challenges/ situations. 
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            There are no right or wrong answers to this exercise. 
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time allowed: {this.props.time ? (this.props.time + " minutes") : <CircularProgress size="1rem" />}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            The time starts when you click the start button. The system will auto-submit the completed responses at the end of the allocated time.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        Once you have pressed 'Start', you will need to complete the test within the given time - please do not close the window/ refresh the page/ stop the test midway. You will not be able to access this test again (through the link provided) once you have started.
                        </ListItemText>
                    </ListItem>
                </List>
                <React.Fragment>
                <CommonInstructions></CommonInstructions>
                </React.Fragment>
                
            </>
        )
    }
}

export default BEIInstructions;