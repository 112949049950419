const questionTypes = [
    {
        name: "Aptitude",
        value: "aptitude"
    },
    {
        name: "Job Competency",
        value: "jobSkills"
    },
    {
        name: "SJT",
        value: "sjt"
    },
    {
        name: "BEI",
        value: "bei"
    },
    {
        name: "InTray",
        value: "inTray"
    },
]

export const allQuestionTypes = [
    ...questionTypes,
    {
        name: "LEO",
        value: "leo"
    },
    {
        name: "LPT",
        value: "lpt"
    },
    
    {
        name: "Personality Profile",
        value: "lptJ"
    },
    {
        name: "PP2",
        value: "lptJC"
    }
]

export default questionTypes;