import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import 'draft-js/dist/Draft.css';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import Mail, { InTray } from '../../../models/intray.model';
import { getQuestion, saveQuestion, updateQuestion, uploadImage } from '../../../util/apiService';
import history from '../../../util/history';
import CreateMail from '../../common/createMail';
import RichEditor from '../../common/richeditor';
import DeleteIcon from '@mui/icons-material/Delete';
import { getActionScoreArray, getHMLScoreArray } from '../../../util/intrayScoreTable';

interface State {
    intray: InTray
}
interface Props {
    match?: any;
    questionDetails?: InTray;
    assessmentId?: string;
}
const getEmptyQuestion = () => {
    return { name: '', emails: [], orgChart: '', text: '' };
}

const getBlankMail = (mailIndex: number) => ({
    to: '',
    cc: '',
    from: '',
    date: new Date(),
    hmlScores: getHMLScoreArray(mailIndex), // getting hmlscores for next email
    actionScores: getActionScoreArray(mailIndex), // getting action scores for next email
    desc: '',
    subject: '',
    content: '',
    actions: [{ id: 0, text: '' }, { id: 1, text: '' }, { id: 2, text: '' }]
});

class IntrayQuestion extends Component<Props, State> {

    state: State = {
        intray: this.props.questionDetails || getEmptyQuestion()
    }

    componentDidMount() {
        const { match } = this.props;
        if(match) {
            const { params } = match;
            if (params && params.id) {
                const getQuestionPromise = getQuestion("intray", params.id)
                getQuestionPromise.then((response: any) => {
                    if (response && response.length > 0) {
                        this.setState({ intray: response[0] });
                    }
                });
                toast.promise(getQuestionPromise, {
                    loading: 'Fetching details',
                    success: 'Ready',
                    error: 'Fetching details failed'
                });
            }
        }
    }

    setName = (value: string) => {
        const intray = {...this.state.intray, name: value};
        this.setState({...this.state, intray});
    }

    removeOption = (mailIndex: number, optionIndex: number) => {
        const intray = this.state.intray;
        const options = intray.emails[mailIndex].actions;
        options.splice(optionIndex, 1);
        if (options.length === 0) {
            options.push({id: 1, text: ''});
        }
        this.setState({ ...this.state, intray });
    }

    moveOption = (mailIndex: number, optionIndex: number, step: number) => {
        const intray = this.state.intray;
        const options = intray.emails[mailIndex].actions;
        const newIndex = optionIndex + step;
        if (newIndex >= 0 && newIndex < options.length) {
            const option = options[optionIndex];
            options.splice(optionIndex, 1);
            options.splice(newIndex, 0, option);
            this.setState({ ...this.state, intray });
        }
    }

    setOption = (mailIndex: number, optionIndex: number, value: string) => {
        const intray = this.state.intray;
        const actions = intray.emails[mailIndex].actions;
        actions[optionIndex].text = value;
        this.setState({ ...this.state, intray });
    }

    addOption = (mailIndex: number) => {
        const intray = this.state.intray;
        const mails = intray.emails;
        const actions = mails[mailIndex].actions;
        actions.push({ id: 1, text: '' });
        this.setState({ ...this.state, intray });
    }

    setValue = (mailIndex: number, variable: keyof Mail, value: string) => {
        const intray = this.state.intray;
        const mails = intray.emails;
        const thisMail = mails[mailIndex];
        Object.assign(thisMail, { [variable]: value });
        this.setState({ ...this.state, intray });
    }

    addMail = () => {
        if(this.state.intray.emails.length === 15) {
            toast.error("Maximum 15 mails allowed");
            return;
        }
        const intray = this.state.intray;
        const mails = intray.emails;
        mails.push(getBlankMail(mails.length + 1));
        this.setState({...this.state, intray});
    }

    setInboxInstructions = (text: string) => {
        const intray = this.state.intray;
        intray.text = text;
        this.setState({intray});
    }

    setMailContent = (mailIndex: number, content: string) => {
        const {intray} = this.state;
        const mail = intray.emails[mailIndex];
        mail.content = content;
        this.setState({intray});
    }

    uploadImage = (files: any) => {
        if(!files || files.length === 0) {
            const intray = this.state.intray;
            intray.orgChart = "";
            this.setState({ intray });
            return;
        }
        const uploadImagePromise = uploadImage(files[0])
        uploadImagePromise.then((response: any) => {
            const intray = this.state.intray;
            intray.orgChart = response.message;
            this.setState({intray});
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(uploadImagePromise, {
            loading: 'Uploading Image',
            success: 'Image uploaded',
            error: 'Error uploading image'
        });
    }

    saveQuestion = () => {
        if (this.state.intray._id) {
            const { _id, emails, name, orgChart, text } = this.state.intray;
            const questionObject: any = { emails, text, name, orgChart };
            if (this.props.assessmentId) {
                questionObject.assessmentId = this.props.assessmentId;
            }
            const updateQuestionPromise = updateQuestion("intray", _id, questionObject)
            updateQuestionPromise.then((response: any) => {
                
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(updateQuestionPromise, {
                loading: 'Updating question',
                success: 'Question updated',
                error: 'Error updating question'
            });
        } else {
            const saveQuestionPromise = saveQuestion("intray", [{ ...this.state.intray }])
            saveQuestionPromise.then((response: any) => {
                history.push('/admin/create/intray/' + response[0]._id);
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveQuestionPromise, {
                loading: 'Saving question',
                success: 'Question saved',
                error: 'Error saving question'
            });
        }
    }

    clearMail = (mailIndex: number) => {
        const {intray} = this.state;
        const mails = intray.emails;
        mails[mailIndex] = getBlankMail(mailIndex);
        this.setState({intray})
    }

    removeLastMail = () => {
        const { intray } = this.state;
        const mails = intray.emails;
        mails.pop();
        this.setState({ intray });
    }

    render() {
        const {intray} = this.state;
        return (
            <div>
                <h3>Create Intray Question</h3>
                <div style={{ marginTop: 15 }}>
                    <TextField label='Internal name' style={{ width: '50%' }} onChange={(e) => this.setName(e.target.value)} value={intray.name} />
                </div>
                <div style={{ marginTop: 15 }}>
                    Inbox instructions
                </div>
                <div style={{ marginTop: 15 }}>
                    <RichEditor setText={this.setInboxInstructions} initialContent={intray.text} />
                </div>
                <div style={{ marginTop: 15 }}>
                    Organization Chart
                </div>
                <div style={{ marginTop: 15 }}>
                    { !intray.orgChart ?
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            maxFileSize={10000000}
                            filesLimit={1}
                            fileObjects={[]}
                            dropzoneText='Drag and drop image for organization chart here or click to select'
                            onChange={(loadedFiles: any) => {
                                this.uploadImage(loadedFiles);
                            }}
                            showAlerts={true}
                        />
                    :
                        <div style={{textAlign: 'center'}}>
                            <div style={{display: 'inline-block', position: 'relative'}}>
                                <img src={'https://inqimages.s3.amazonaws.com/dev/' + intray.orgChart} style={{ width: '500px', border: '1px solid #000'}} />
                                <IconButton color="error" style={{ position: 'absolute', top: 15, right: -45 }} onClick={() => this.uploadImage(null)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ marginTop: 15 }}>
                    Mails
                </div>
                <div style={{ marginTop: 15}}>
                    { intray.emails.map((mail, index) => (
                        <CreateMail mail={mail} index={index} removeOption={this.removeOption} moveOption={this.moveOption} setOption={this.setOption} showUp={index !== 0} showDown={index !== intray.emails.length - 1} setValue={this.setValue} key={index} setMailContent={this.setMailContent} clearMail={this.clearMail} />
                    ))}
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="primary" onClick={this.addMail}>
                        Add Mail
                    </Button>
                    <Button variant="contained" color="error" onClick={this.removeLastMail} style={{marginLeft: 20}}>
                        Delete last Mail
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="error" onClick={() => this.saveQuestion()}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default IntrayQuestion;