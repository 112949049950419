import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="left">
            {'Copyright © '}
            <Link color="inherit" href="https://lq.inqsights.com/">inQsights</Link>,&nbsp;{new Date().getFullYear()}
        </Typography>
    )
}


export function CopyRightLeft() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lq.inqsights.com/">inQsights</Link>,&nbsp;{new Date().getFullYear()}
        </Typography>
    )
}