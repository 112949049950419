import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import BEIReviewList from "./beiReviewList";
import BEIReview from "./beireview";

interface Props {
    match: any
}

class BEIReviewLayout extends Component<Props> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/list/:id`} component={BEIReviewList} />
                <Route path={`${this.props.match.path}/review/:id`} component={BEIReview} />
            </Switch>
        );
    }
}

export default BEIReviewLayout;