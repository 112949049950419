import { Component } from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addAssessment, deleteAssessment } from "../../../../util/apiService";
import toast from "react-hot-toast";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}
interface State {
    leoIncluded: boolean
}

const assessmentType: string = "leo";

class LeoDetails extends Component<Props, State> {
    
    state = {
        leoIncluded: this.props.assessment ? true : false
    }

    includeLEO(leoIncluded: boolean) {
        if (leoIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, assessmentType)
            addAssessmentPromise.then((response) => {
                this.props.addAssessment(assessmentType, response);
                this.setState({ leoIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding assessment ...',
                success: 'Added assessment',
                error: 'Adding assessment failed'
            });
        } else {
            var shouldDelete = window.confirm('Delete LEO assessment?');
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(assessmentType);
                this.setState({ leoIncluded: false });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting assessment ...',
                success: 'Deleted assessment',
                error: 'Deleting assessment failed'
            });
        }
    }

    render() {
        const {leoIncluded} = this.state;
        const {assessment, setAssessmentTime, saveAssessmentTime} = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={leoIncluded} onChange={(e) => this.includeLEO(e.target.checked)} />} label="Include LEO" />
                </FormGroup>
                { leoIncluded &&
                    <div style={{ marginTop: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: 8 }}>
                                <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime(assessmentType, e.target.value)} type="number" />
                            </div>
                            <div style={{ flex: 2, textAlign: 'left', marginLeft: 20 }}>
                                <Button variant="contained" color="primary" onClick={() => saveAssessmentTime(assessmentType)}>Save</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default LeoDetails;