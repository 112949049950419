import React, { Component } from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addAssessment, deleteAssessment, getQuestions, saveQuestion } from "../../../../util/apiService";
import toast from "react-hot-toast";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InTray } from "../../../../models/intray.model";
import IntrayQuestion from "../../questions/intrayQuestion";
import Button from '@mui/material/Button';

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string, deleteIndex: number) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}
interface State {
    intrayIncluded: boolean;
    questions: any[],
    selectedQuestion: InTray | null
    autocompleteValue: any;
}

const assessmentType = "intray";

class IntrayDetails extends Component<Props, State> {

    state: State = {
        intrayIncluded: this.props.assessment ? true : false,
        questions: [],
        selectedQuestion: null,
        autocompleteValue: null
    }

    componentDidMount() {
        if (this.props.assessment) {
            const getAssessmentQuestionsPromise = getQuestions(assessmentType, this.props.assessment._id);
            getAssessmentQuestionsPromise.then((response: any) => {
                if(response && response.length > 0) {
                    this.setState({ selectedQuestion: response[0] })
                }
            });
            toast.promise(getAssessmentQuestionsPromise, {
                loading: 'Fetching assessment questions',
                success: 'Assessment questions fetched',
                error: 'Error fetching assessment questions'
            });
        }

        if (this.state.questions.length === 0) {
            const getQuestionsPromise = getQuestions(assessmentType);
            getQuestionsPromise.then((response: any) => {
                this.setState({ questions: response })
            });
            toast.promise(getQuestionsPromise, {
                loading: 'Fetching question bank',
                success: 'Question bank fetched',
                error: 'Error fetching question bank'
            });
        }
    }

    selectQuestion = (option: InTray) => {
        if(!option) return;
        const {emails, name, orgChart, priority, text} = option;
        const questionObject: any = { emails, name, orgChart, priority, text };
        questionObject.assessmentId = this.props.assessment._id;
        const saveQuestionPromise = saveQuestion(assessmentType, [{ ...questionObject }]);
        saveQuestionPromise.then((response: any) => {
            this.setState({ selectedQuestion: response[0] });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(saveQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    includeIntray = (intrayIncluded: any) => {
        if (intrayIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, "inTray");
            addAssessmentPromise.then((response) => {
                this.props.addAssessment("inTray", response);
                this.setState({ intrayIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding assessment ...',
                success: 'Added assessment',
                error: 'Adding assessment failed'
            });

            if (this.state.questions.length === 0) {
                const getQuestionsPromise = getQuestions(assessmentType);
                getQuestionsPromise.then((response: any) => {
                    this.setState({ questions: response })
                });
                toast.promise(getQuestionsPromise, {
                    loading: 'Fetching question bank',
                    success: 'Question bank fetched',
                    error: 'Error fetching question bank'
                });
            }
        } else {
            var shouldDelete = window.confirm('Delete InTray assessment?');
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(assessmentType, 0);
                this.setState({ intrayIncluded: false, selectedQuestion: null });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting assessment ...',
                success: 'Deleted assessment',
                error: 'Deleting assessment failed'
            });
        }
    }

    render() {
        const { intrayIncluded, questions, selectedQuestion } = this.state;
        const { assessment, setAssessmentTime, saveAssessmentTime } = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={intrayIncluded} onChange={(e) => this.includeIntray(e.target.checked)} />} label="Include Intray" />
                </FormGroup>
                { intrayIncluded &&
                    <React.Fragment>
                        <div style={{ marginTop: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 8 }}>
                                    <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime("inTray", e.target.value)} type="number" />
                                </div>
                                <div style={{ flex: 2, textAlign: 'left', marginLeft: 20 }}>
                                    <Button variant="contained" color="primary" onClick={() => saveAssessmentTime("inTray")}>Save</Button>
                                </div>
                            </div>
                        </div>
                        { selectedQuestion ?
                            <IntrayQuestion questionDetails={selectedQuestion} assessmentId={assessment?._id} />

                        :
                            <Autocomplete
                                disablePortal
                                options={questions}
                                sx={{ width: 300 }}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => <TextField {...params} label="Select Intray" />}
                                onChange={(event: any, option: InTray) => this.selectQuestion(option)}
                            />
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default IntrayDetails;