import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import { CopyRightLeft } from '../main/copyright';

import authService from '../../util/authService';
import history from '../../util/history';
import {  getUserRole } from "../../util/apiService";

interface Props{}

interface State {
    username: string;
    password: string;
    loginError: string;
}

class Login extends Component<Props, State> {
    
    state = {
        username: "",
        password: "",
        loginError: ""
    }

    doLogin = () => {
        if(!this.state.username || !this.state.password) {
            this.setState({loginError: "Please enter a username and password"});
            return;
        }
        authService.login(this.state.username, this.state.password).then((response: any) => {
            if(response) {
                const getUserRolePromise = getUserRole()
                getUserRolePromise.then((response: any) => {
                    if (response) {
                        localStorage.setItem("userDetails", JSON.stringify(response))
                        history.push('/admin/assessments');
                    }
                })
              
            }
        });
    }

    render() {
        return(
            <Grid container spacing={0} justifyContent="center" direction="row" style={{ backgroundColor: "rgb(127, 255, 211)" }}>
                <Grid item>
                    <Grid container direction="column" alignItems="center" justifyContent="center" style={{height: '100vh'}}>
                        <Paper variant='elevation' elevation={2} style={{paddingLeft: 80, paddingRight: 80}}>
                            <Grid item alignItems="center" style={{padding: 50, textAlign: 'center'}}>
                                <img src="/inQlogo.png" style={{height: 100}} />
                            </Grid>
                            <Grid item>
                                <TextField type="email" placeholder="Email" fullWidth name="username" variant="outlined" value={this.state.username} onChange={(event) => this.setState({...this.state, username: event.target.value})} required autoFocus />
                            </Grid>
                            <Grid item>
                                <TextField type="password" placeholder="Password" fullWidth name="password" variant="outlined" value={this.state.password} onChange={(event) => this.setState({...this.state, password: event.target.value})} required style={{marginTop: 50}} onKeyUp={(e) => {if(e.which === 13) this.doLogin()}} />
                            </Grid>
                            <Grid item style={{ padding: 50, textAlign: 'center' }}>
                                <Button variant="contained" color="primary" type="button" onClick={this.doLogin}>Login</Button>
                            </Grid>
                            { this.state.loginError &&
                                <Grid item style={{ padding: 50, textAlign: 'center' }}>
                                    <Alert severity='error'>{this.state.loginError}</Alert>
                                </Grid>
                            }
                            <Divider />
                            <Grid item style={{ padding: 50, textAlign: 'center' }}>
                                <CopyRightLeft />
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Login;