import { doLogin } from "./fetchService";
import history from "./history";
import urlLibrary from "./urlLibrary";

class authServiceDef {
    user: any = null;

    public async login(username: string, password: string) {
        const loginObject: any = {
            client_id: 'auth',
            grant_type: 'password',
            scope: 'openid',
            username,
            password
        }
        try {
            const tokens: any = await doLogin(urlLibrary.auth.login, loginObject);
            localStorage.setItem('user', JSON.stringify({
                access_token: tokens.access_token,
                refresh_token: tokens.refresh_token,
                id_token: tokens.id_token
            }));
            return true;
        } catch (err: any) {
            console.log(err);
            localStorage.removeItem("user");
            return false;
        }
    }

    public getToken = () => {
        if (this.user) return this.user.access_token;
        const user = this.getUser();
        if (user) return this.user.access_token;
        return "";
    }

    public getUser = () => {
        if (this.user) return this.user;
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            this.user = JSON.parse(storedUser);
            return this.user;
        }
    }

    public authHeader = () => ({
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": "application/json"
    })

    public uploadHeader = () => ({
        Authorization: "Bearer " + this.getToken()
    })

    public async refreshTokens() {
        if (!this.user) {
            this.logout();
        }
        try {
            const tokens: any = await doLogin(urlLibrary.auth.refreshTokens, { refreshToken: this.user.refresh_token })
            localStorage.setItem('user', JSON.stringify({
                access_token: tokens.access_token,
                refresh_token: tokens.refresh_token,
                id_token: tokens.id_token
            }));
            return true;
        } catch (err: any) {
            console.log(err);
            localStorage.removeItem("user");
            return false;
        }
    }

    public logout = () => {
        this.user = null;
        localStorage.removeItem('user');
        localStorage.removeItem('userDetails');
        history.push('/login');
    }

    public isLoggedIn = () => {
        return !!this.getUser();
    }

}

const authService = new authServiceDef();
export default authService;