import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { CSVReader } from 'react-papaparse';
import AssessmentDetails from './assessmentdetails';
import Assessment from '../../../models/assessments.model';
import Group from '../../../models/group.model';
import { allQuestionTypes } from '../../../models/questionTypes';
import User from '../../../models/user.model';
import UserAssessment from '../../../models/userassessment.model';
import { addGroupUsers, addUserAssessments, deleteGroupUser, getGroup, getGroupAssessments, getGroupUserAssessments, getGroupUsers, saveGroup, sendEmails, updateAssessment, updateGroup, uploadImage } from '../../../util/apiService';
import history from '../../../util/history';
import RichEditor from '../../common/richeditor';
interface Props {
    match: any;
}
interface State {
    group: Group,
    selectedType: any;
    selectedTabIndex: number;
    assessments: { [key: string]: Assessment | null };
    users: any[];
    userAssessments: UserAssessment[];
    clinentId: string;
}

const buttonRef = React.createRef<CSVReader>();
const emailRegex = /([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

class CreateAssessments extends Component<Props, State> {
    
    state: State = {
        group: {name: '', clientContact: '', clientName: '', clientLogo: ''},
        selectedType: allQuestionTypes[0],
        selectedTabIndex: 0,
        assessments: {sjt: null, aptitude: null, inTray: null, lpt: null, leo: null, bei: null, jobSkills: null, lptJ: null},
        users: [],
        userAssessments: [],
        clinentId: "1"
    }

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        if (params && params.id) {
            const getGroupPromise = getGroup(params.id)
            getGroupPromise.then((response: any) => {
                if (response && response.length > 0) {
                    this.setState({ group: response[0] });
                }
            });
            toast.promise(getGroupPromise, {
                loading: 'Fetching details',
                success: 'Details fetched',
                error: 'Fetching details failed'
            });
            const getGroupAssessmentsPromise = getGroupAssessments(params.id);
            getGroupAssessmentsPromise.then((response: any) => {
                for(const assessment of response) {
                    const {assessments} = this.state;
                    assessments[assessment.assessmentType] = assessment;
                    this.setState({ assessments });
                }
            });
            toast.promise(getGroupAssessmentsPromise, {
                loading: 'Fetching assessments',
                success: 'Assessments fetched',
                error: 'Error fetching assessments'
            });

            const getGroupUsersPromise = getGroupUsers(params.id);
            getGroupUsersPromise.then((response: any) => {
                this.setState({ users: response });
            });
            toast.promise(getGroupUsersPromise, {
                loading: 'Fetching users',
                success: 'Users fetched',
                error: 'Error fetching users'
            });

            const getGroupUserAssessmentsPromise = getGroupUserAssessments(params.id);
            getGroupUserAssessmentsPromise.then((response: any) => {
                const userAssessments = [];
                for(const userAssessment of response) {
                    userAssessments.push(userAssessment);
                }
                this.setState({ userAssessments });
            });
            toast.promise(getGroupUserAssessmentsPromise, {
                loading: 'Fetching user assessments',
                success: 'User assessments fetched',
                error: 'Error fetching user assessments'
            });
        }
    }

    addAssessment = (assessmentType: string, assessmentDetailsList: Assessment[]) => {
        if(assessmentDetailsList.length > 0) {
            const {assessments} = this.state;
            const assessmentDetails = assessmentDetailsList[0];
            assessments[assessmentType] = assessmentDetails;
            this.setState({assessments});
            const userAssessments: UserAssessment[] = [];
            for(const user of this.state.users) {
                userAssessments.push({
                    groupId: this.state.group._id || "",
                    assessmentId: assessmentDetails._id || "",
                    userId: user._id,
                    assessmentType,
                    clientId:"1"
                });
            }
            if(userAssessments.length > 0) {
                this.createUserAssessments(userAssessments);
            }
        }
    }

    deleteAssessment = (assessmentType: string) => {
        const { assessments } = this.state;
        assessments[assessmentType] = null;
        this.setState({ assessments });
    }

    setAssessmentTime = (assessmentType: string, time: string) => {
        const {assessments} = this.state;
        assessments[assessmentType]!.time = parseInt(time);
        this.setState({assessments});
    }

    saveAssessmentTime = (assessmentType: string) => {
        const { assessments } = this.state;
        const assessment = assessments[assessmentType];
        const updateAssessmentPromise = updateAssessment(assessment?._id || "", assessment);
        updateAssessmentPromise.then((response) => {
            
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(updateAssessmentPromise, {
            loading: 'Updating assessment ...',
            success: 'Updated assessment',
            error: 'Updating assessment failed'
        });
    }

    setValue = (variable: keyof Group, value: string) => {
        this.setState({...this.state, group: {...this.state.group, [variable]: value}});
    }

    uploadImage = (files: any) => {
        if (!files || files.length === 0) {
            const group = this.state.group;
            group.clientLogo = "";
            this.setState({ group });
            return;
        }
        const uploadImagePromise = uploadImage(files[0])
        uploadImagePromise.then((response: any) => {
            const group = this.state.group;
            group.clientLogo = response.message;
            this.setState({ group });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(uploadImagePromise, {
            loading: 'Uploading Image',
            success: 'Image uploaded',
            error: 'Error uploading image'
        });
    }

    saveGroup = () => {
        if (this.state.group._id) {
            const { _id, name, clientContact, clientName, clientLogo, emailSubject, emailPart1, emailPart2, reminderEmailSubject, reminderEmailPart1, reminderEmailPart2 } = this.state.group;
            const groupObject = { name, clientContact, clientName, clientLogo, emailSubject, emailPart1, emailPart2, reminderEmailSubject, reminderEmailPart1, reminderEmailPart2 };
            const updateGroupPromise = updateGroup(_id, groupObject).then((response: any) => {
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(updateGroupPromise, {
                loading: 'Updating group',
                success: 'Group updated',
                error: 'Error updating group'
            });
        } else {
            const saveGroupPromise = saveGroup([{ ...this.state.group }])
            saveGroupPromise.then((response: any) => {
                this.setState({group: response[0]});
                history.push('/admin/assessments/create/' + response[0]._id);
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveGroupPromise, {
                loading: 'Saving group',
                success: 'Group saved',
                error: 'Error saving group'
            });
        }
    }

    changeTab = (tabIndex: number) => {
        this.setState({selectedType: allQuestionTypes[tabIndex], selectedTabIndex: tabIndex})
    }

    createUserAssessments = (userAssessments: UserAssessment[]) => {
        const addUserAssessmentsPromise = addUserAssessments(userAssessments);
        addUserAssessmentsPromise.then((uaResponse: any) => {
            const {userAssessments} = this.state;
            for(const userAssessment of uaResponse) {
                userAssessments.push(userAssessment);
            }
            this.setState({ userAssessments });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(addUserAssessmentsPromise, {
            loading: 'Creating user assessments ...',
            success: 'User assessments created',
            error: 'Error creating user investments'
        })
    }

    handleFileLoad = (data: any) => {
        const newUsers: any[] = [];
        data.shift();
        for(var i = 0; i < data.length; i++) {
            const row = data[i].data || [];
            const groupId = this.state.group._id || "";
            const name = row[0] || null;
            const email = row[1] || null;
            const contact = row[2] || null;
            const employeeCode = row[3] || null;
            const businessUnit = row[4] || null;
            const department = row[5] || null;
            const level = row[6] || null;
            const jobTitle = row[7] || null;

            if(!name) {
                continue;
            }
            if(!email) {
                window.alert("Please check row " + (i+2) + ": Email missing");
                buttonRef.current?.setState({ file: null, files: null });
                return;
            }
            if(!email.match(emailRegex)) {
                window.alert("Please check row " + (i + 2) + ": Invalid email");
                buttonRef.current?.setState({ file: null, files: null });
                return;
            }

            const user: User = {
                name: name,
                email: email,
                groupId: groupId
            }
            if(contact) {
                user.contact = contact;
            }
            if(employeeCode) {
                user.employeeCode = employeeCode;
            }
            if(businessUnit) {
                user.businessUnit = businessUnit;
            }
            if(department) {
                user.department = department;
            }
            if(level) {
                user.level = level;
            }
            if(jobTitle) {
                user.jobTitle = jobTitle;
            }

            newUsers.push(user);
        }
        if(newUsers.length > 0) {
            const addGroupUserPromise = addGroupUsers(newUsers)
            addGroupUserPromise.then((response: any) => {
                const {assessments} = this.state;
                let {users} = this.state;
                users = users.concat(response);
                this.setState({users});
                buttonRef.current?.setState({file: null, files: null});
                const userAssessments: UserAssessment[] = [];
                for (const assessmentType in assessments) {
                    if (assessments[assessmentType]) {
                        for (const user of response) {
                            userAssessments.push({
                                groupId: this.state.group._id || "",
                                assessmentId: assessments[assessmentType]!._id || "",
                                userId: user._id,
                                assessmentType
                            });
                        }
                    }
                }
                if(userAssessments.length > 0) {
                    this.createUserAssessments(userAssessments);
                }
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(addGroupUserPromise, {
                loading: 'Saving users ...',
                success: 'Users saved',
                error: 'Error saving users'
            });
        }
    }

    sendEmails = (reminder: boolean = false) => {
        for(const key in this.state.assessments) {
            if (this.state.assessments[key] && !this.state.assessments[key]?.time) {
                alert("Time for " + (key && key==='lptJC'?'PP2':key==='lptJ'?'Personality Profile':key )+ " assessment is not set. Please set time (in minutes) and save it.");
                return;
            }
        }
        const { userAssessments } = this.state;
        const userAssessmentIds: string[] = userAssessments && userAssessments.map((ua: UserAssessment) => ua._id || "");
        const sendEmailsPromise = sendEmails(userAssessmentIds, reminder);
        sendEmailsPromise.then((response: any) => {
            
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(sendEmailsPromise, {
            loading: 'Sending emails ...',
            success: 'Emails sent',
            error: 'Error sending emails'
        });
    }

    deleteUser = (userIndex: number) => {
        const { users, userAssessments } = this.state;
        const user = users[userIndex];
        const filteredUserAssessments = userAssessments.filter((ua: UserAssessment) => ua.userId !== user._id);
        const deleteUserPromise = deleteGroupUser(users[userIndex]._id);
        deleteUserPromise.then((response: any) => {
            users.splice(userIndex, 1);
            this.setState({ users, userAssessments: filteredUserAssessments });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(deleteUserPromise, {
            loading: 'Deleting user ...',
            success: 'User deleted',
            error: 'Error deleting user'
        });
    }

    render() {
        const {group, selectedType, selectedTabIndex, assessments, users} = this.state;
        return (
            <div>
                <h3>Step 1: Create Group</h3>
                <Accordion defaultExpanded={(typeof group._id === 'undefined' || group._id === null || group._id === "") ? true : false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography sx={{ width: '80%', flexShrink: 0 }}>
                            {group.name} - {group.clientName}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Name' value={group.name} onChange={(e) => this.setValue('name', e.target.value)} style={{ textAlign: 'right' }} />
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Client name' value={group.clientName} onChange={(e) => this.setValue('clientName', e.target.value)} style={{ textAlign: 'right' }} />
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Client contact' value={group.clientContact} onChange={(e) => this.setValue('clientContact', e.target.value)} style={{ textAlign: 'right' }} />
                        </div>
                        <div style={{ marginTop: 15 }}>
                            {!group.clientLogo ?
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    maxFileSize={5000000}
                                    filesLimit={1}
                                    fileObjects={[]}
                                    dropzoneText='Drag and drop image for client logo here or click to select'
                                    onChange={(loadedFiles: any) => {
                                        this.uploadImage(loadedFiles);
                                    }}
                                    showAlerts={false}
                                />
                                :
                                <div style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'inline-block', position: 'relative' }}>
                                        <img src={'https://inqimages.s3.amazonaws.com/dev/' + group.clientLogo} style={{ width: '200px', border: '1px solid #000' }} />
                                        <IconButton color="error" style={{ position: 'absolute', top: 15, right: -45 }} onClick={() => this.uploadImage(null)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Button variant="contained" color="error" onClick={() => this.saveGroup()}>
                                Save
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>
                { group._id &&
                    <>
                        <h3>Step 2: Create Assessment</h3>
                        <Accordion defaultExpanded={!!group._id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ width: '80%', flexShrink: 0 }}>
                                    Assessments
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                                <React.Fragment>
                                    <Box sx={{width: '100%'}}>
                                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                            <Tabs value={selectedTabIndex} onChange={(e, tabIndex) => this.changeTab(tabIndex)}>
                                                { allQuestionTypes.map((qType, qTypeIndex) => (
                                                    <Tab key={qTypeIndex} label={qType.name} />
                                                ))}
                                            </Tabs>
                                        </Box>
                                    </Box>
                                    <React.Fragment>
                                        <AssessmentDetails type={selectedType} groupId={group._id || ""} assessment={assessments[selectedType.value]} addAssessment={this.addAssessment} deleteAssessment={this.deleteAssessment} setAssessmentTime={this.setAssessmentTime} saveAssessmentTime={this.saveAssessmentTime} />
                                    </React.Fragment>
                                </React.Fragment>
                            </AccordionDetails>
                        </Accordion>
                        <h3>Step 3: Specify candidates</h3>
                        <Accordion defaultExpanded={!!group._id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ width: '80%', flexShrink: 0 }}>
                                    Candidates
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                                <CSVReader ref={buttonRef} onFileLoad={this.handleFileLoad}>
                                    <div style={{textAlign: 'center'}}>Drop CSV file or click here to upload</div>
                                    <div style={{textAlign: 'center'}}>Please use a CSV file with columns for name (row will be ignored if empty), email and optionally phone, employee code, business unit, department, level, job title </div>
                                    <div style={{color: 'red', fontWeight: 'bold', textAlign: 'center'}}>Please do not change the order of columns</div>
                                </CSVReader>
                                { users && users.length > 0 &&
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Contact</TableCell>
                                                <TableCell>Emp Code</TableCell>
                                                <TableCell>Business Unit</TableCell>
                                                <TableCell>Department</TableCell>
                                                <TableCell>Level</TableCell>
                                                <TableCell>Job Title</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {users.map((user: User, uIndex: number) => (
                                                <TableRow key={uIndex}>
                                                    <TableCell>{user.name}</TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell>{user.contact}</TableCell>
                                                    <TableCell>{user.employeeCode}</TableCell>
                                                    <TableCell>{user.businessUnit}</TableCell>
                                                    <TableCell>{user.department}</TableCell>
                                                    <TableCell>{user.level}</TableCell>
                                                    <TableCell>{user.jobTitle}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => this.deleteUser(uIndex)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                            </AccordionDetails>
                        </Accordion>
                        <h3>Step 4: Finalize Email Templates</h3>
                        <Accordion defaultExpanded={!!group._id}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography sx={{ width: '80%', flexShrink: 0 }}>
                                    Email Templates
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                                <React.Fragment>
                                    <div style={{ marginTop: 15 }}>
                                        <TextField fullWidth label='First Email Subject' value={group.emailSubject} onChange={(e) => this.setValue('emailSubject', e.target.value)} style={{ textAlign: 'right' }} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <div>First Email, Part 1 (above links table)</div>
                                        <RichEditor setText={(content) => this.setValue('emailPart1', content)} initialContent={group.emailPart1 || ""} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <div>First Email, Part 2 (below links table)</div>
                                        <RichEditor setText={(content) => this.setValue('emailPart2', content)} initialContent={group.emailPart2 || ""} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <Divider />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <TextField fullWidth label='Reminder Email Subject' value={group.reminderEmailSubject} onChange={(e) => this.setValue('reminderEmailSubject', e.target.value)} style={{ textAlign: 'right' }} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <div>Reminder Email, Part 1 (above links table)</div>
                                        <RichEditor setText={(content) => this.setValue('reminderEmailPart1', content)} initialContent={group.reminderEmailPart1 || ""} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <div>Reminder Email, Part 2 (below links table)</div>
                                        <RichEditor setText={(content) => this.setValue('reminderEmailPart2', content)} initialContent={group.reminderEmailPart2 || ""} />
                                    </div>
                                    <div style={{ marginTop: 15 }}>
                                        <Button variant="contained" color="error" onClick={() => this.saveGroup()}>
                                            Save Emails
                                        </Button>
                                    </div>
                                </React.Fragment>
                            </AccordionDetails>
                        </Accordion>
                        <div style={{marginTop: 15}}>
                            <Button variant="contained" color="error" onClick={() => this.sendEmails()}> Send Emails</Button>
                            <Button style={{marginLeft: 20}} variant="contained" color="primary" onClick={() => this.sendEmails(true)}> Send Reminders</Button>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default CreateAssessments;