import React, { Component } from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addAssessment, deleteAssessment, deleteQuestion, getQuestions, saveQuestion } from "../../../../util/apiService";
import toast from "react-hot-toast";
import { Bei } from "../../../../models/bei.model";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import BeiQuestion from "../../questions/beiQuestion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ellipsify from "../../../../util/ellipsify";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string, deleteIndex: number) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}
interface State {
    beiIncluded: boolean,
    questions: any,
    selectedQuestions: any[],
    autocompleteValue: any;
}

const assessmentType: string = "bei";

class BeiDetails extends Component<Props, State> {

    state: State = {
        beiIncluded: this.props.assessment ? true : false,
        questions: [],
        selectedQuestions: [],
        autocompleteValue: null
    }

    componentDidMount() {
        if (this.props.assessment) {
            const getAssessmentQuestionsPromise = getQuestions(assessmentType, this.props.assessment._id);
            getAssessmentQuestionsPromise.then((response: any) => {
                this.setState({ selectedQuestions: response })
            });
            toast.promise(getAssessmentQuestionsPromise, {
                loading: 'Fetching assessment questions',
                success: 'Assessment questions fetched',
                error: 'Error fetching assessment questions'
            });
        }

        if (this.state.questions.length === 0) {
            const getQuestionsPromise = getQuestions(assessmentType);
            getQuestionsPromise.then((response: any) => {
                this.setState({ questions: response })
            });
            toast.promise(getQuestionsPromise, {
                loading: 'Fetching question bank',
                success: 'Question bank fetched',
                error: 'Error fetching question bank'
            });
        }
    }

    addQuestion = (beiQuestion: Bei | null) => {
        if(!beiQuestion) return;
        const { title, question, options, competency } = beiQuestion;
        const questionObject: any = { title, question, options, competency };
        questionObject.assessmentId = this.props.assessment._id;
        const saveQuestionPromise = saveQuestion(assessmentType, [{ ...questionObject }]);
        saveQuestionPromise.then((response: any) => {
            const { selectedQuestions } = this.state;
            selectedQuestions.push(response[0]);
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(saveQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    includeBEI(beiIncluded: boolean) {
        if (beiIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, assessmentType)
            addAssessmentPromise.then((response) => {
                this.props.addAssessment(assessmentType, response);
                this.setState({ beiIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding assessment ...',
                success: 'Added assessment',
                error: 'Adding assessment failed'
            });

            if (this.state.questions.length === 0) {
                const getQuestionsPromise = getQuestions(assessmentType);
                getQuestionsPromise.then((response: any) => {
                    this.setState({ questions: response })
                });
                toast.promise(getQuestionsPromise, {
                    loading: 'Fetching question bank',
                    success: 'Question bank fetched',
                    error: 'Error fetching question bank'
                });
            }
        } else {
            var shouldDelete = window.confirm('Delete BEI assessment and all questions?');
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(assessmentType, 0);
                this.setState({ beiIncluded: false, selectedQuestions: [] });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting assessment ...',
                success: 'Deleted assessment',
                error: 'Deleting assessment failed'
            });
        }
    }

    deleteQuestion = (qIndex: number, event: any) => {
        event.stopPropagation();
        var shouldDelete = window.confirm('Delete question?');
        if (!shouldDelete) {
            return;
        }
        const question = this.state.selectedQuestions[qIndex];
        const deleteQuestionPromise = deleteQuestion(assessmentType, question._id);
        deleteQuestionPromise.then(() => {
            const { selectedQuestions } = this.state;
            selectedQuestions.splice(qIndex, 1);
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(deleteQuestionPromise, {
            loading: 'Deleting question ...',
            success: 'Deleted question',
            error: 'Deleting question failed'
        });
    }

    render() {
        const { beiIncluded, questions, selectedQuestions, autocompleteValue } = this.state;
        const { assessment, setAssessmentTime, saveAssessmentTime } = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={beiIncluded} onChange={(e) => this.includeBEI(e.target.checked)} />} label="Include BEI" />
                </FormGroup>
                {beiIncluded &&
                    <React.Fragment>
                        <div style={{ marginTop: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 8 }}>
                                    <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime(assessmentType, e.target.value)} type="number" />
                                </div>
                                <div style={{ flex: 2, textAlign: 'left', marginLeft: 20 }}>
                                    <Button variant="contained" color="primary" onClick={() => saveAssessmentTime(assessmentType)}>Save</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Autocomplete
                                disablePortal
                                options={questions}
                                sx={{ width: 300 }}
                                getOptionLabel={(option: Bei) => option.title}
                                renderInput={(params) => <TextField {...params} label="Add Bei Question Set" value={autocompleteValue} />}
                                onChange={(event: any, option: Bei | null) => this.addQuestion(option)}
                                value={autocompleteValue}
                            />
                        </div>
                        <div style={{marginTop: 15}}>
                            { selectedQuestions.map((q: Bei, qIndex: number) => (
                                <Accordion defaultExpanded={false} key={qIndex}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Typography sx={{ flexShrink: 0, flex: 8 }}>
                                                {ellipsify(q.title, 100)}
                                            </Typography>
                                            <div style={{ flex: 1, textAlign: 'right' }}>
                                                <IconButton onClick={(e) => this.deleteQuestion(qIndex, e)} >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BeiQuestion questionDetails={q} assessmentId={assessment?._id} />
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default BeiDetails;