export default class UserAssessment {
    _id?: string;
    assessmentId: string;
    userId: string;
    groupId: string;
    scores?: any[] = [];
    assessmentType?: string;
    url?: string;
    du?: Date;
    dc?: Date;
    invited?: Date;
    completed?: Date;
    exitedFullScreen?:boolean;
    ipAddress?:string;
    location?:string;
    incomplete?: boolean;
   
    denom?: any[] = [];
    clientId?:string;

    constructor(assessmentId: string, userId: string, groupId: string) {
        this.assessmentId = assessmentId;
        this.userId = userId;
        this.groupId = groupId;
    }
}
export  class SaveExitFullScreen  {
    _id:string;
    exitedFullScreen:Boolean
}
export  class userLocation  {
    ipAddress:string;
    location:string
}

export const scoreCalculation = [
    {
        assessment: "aptitude",
        assessmentName: "APTITUDE",
        display: "Qranium (Aptitude) (Scores in %)",
        headerWidth: 6,
        denominator: 4,
        competencies: [
            'qa',
            'di',
            'va',
            'lr'
        ]
    },
    {
        assessment: "jobSkills",
        assessmentName: "JOB Competency",
        display: "Job Qompetency Test (Scores in %)",
        denominator: 1,
        headerWidth: 2,
        competencies: [
            "jobSkills"
        ]
    },
    {
        assessment: "sjt",
        assessmentName: "SJT",
        display: "Qvad (Situational Judgment) (Scores in %)",
        headerWidth: 8,
        denominator: 6,
        competencies: [
            'ee',
            'dm',
            'cf',
            'pd',
            'es',
            'ic'
        ]
    },
    {
        assessment: "bei",
        assessmentName: "BEI",
        display: "critiQal (BEI) (Scores in %)",
        headerWidth: 8,
        denominator: 6,
        competencies: [
            'ee',
            'dm',
            'cf',
            'pd',
            'es',
            'ic'
        ]
    },
    {
        assessment: "inTray",
        assessmentName: "INTRAY",
        display: "inQbox (In-Tray) (Scores in %)",
        headerWidth: 6,
        denominator: 4,
        competencies: [
            'ee',
            'dm',
            'cf',
            'pd'
        ]
    },
    {
        assessment: "leo",
        assessmentName: "LEO",
        display: "PsyQ LEO (Scores in %)",
        headerWidth: 7,
        denominator: 5,
        competencies: [
            'is',
            'pa',
            'dc',
            'ra',
            'ei'
        ]
    },
    {
        assessment: "lpt",
        assessmentName: "LPT",
        display: "PsyQ LPT (Scores in %)",
        headerWidth: 6,
        denominator: 8,
        competencies: ['pc', 'eb', 'vm', 'pm'],
        dimensions: ['people', 'result'],
        // competencies: [
        //     'pc:people',
        //     'pc:result',
        //     'eb:people',
        //     'eb:result',
        //     'vm:people',
        //     'vm:result',
        //     'pm:people',
        //     'pm:result',
        // ]
    },
    {
        assessment: "lptJ",
        assessmentName: "PERSONALITY PROFILE",
        display: "PERSONALITY PROFILE (Scores in %)",
        headerWidth: 6,
        denominator: 1,
        competencies: ['ol', 'wo','os', 'ps'],
        dimensions: ['people', 'result'],
    },
    {
        assessment: "lptJC",
        assessmentName: "PP2",
        display: "PP2 (Scores in %)",
        headerWidth: 6,
        denominator: 1,
        competencies: ['ol', 'wo','os', 'ps'],
        dimensions: ['people', 'result'],
    },
   
    
];

export const leftColumns = [
    {
        display: "Employee Code",
        name: "employeeCode"
    },
    {
        display: "Phone",
        name: "contact"
    },
    {
        display: "Business Unit",
        name: "businessUnit"
    },
    {
        display: "Department",
        name: "department"
    },
    {
        display: "Level",
        name: "level"
    },
    {
        display: "Job Title",
        name: "jobTitle"
    }
]

export const getDisplay: any = {
    employeeCode: "Employee Code",
    contact: "Phone",
    businessUnit: "Business Unit",
    department: "Department",
    level: "Level",
    jobTitle: "Job Title",
    lpt: "PsyQ LPT",
    leo: "PsyQ LEO",
    sjt: "Qvad (Situational Judgment)",
    inTray: "inQbox (In-Tray)",
    aptitude: "Qranium (Aptitude)",
    bei: "critiQal (BEI)",
    lptJ: "Personality Profile",
    lptJC: "PP2",
    jobSkills: "Job Competency Test"
}

export const  getPP2DislayNames:any={
    ol:"Theme 1",
    wo:"Theme 2",
    os:"Theme 3",
    ps:"Theme 4",

}
export const csvDownloadName:any={
    employeeCode: "Employee Code",
    contact: "Phone",
    businessUnit: "Business Unit",
    department: "Department",
    level: "Level",
    jobTitle: "Job Title",
    aptitude: "APTITUDE",
    jobSkills: "JOB COMPETENCY",
    sjt: "SJT",
    bei: "BEI",
    inTray: "INTRAY",
    leo: "LEO",
    lpt: "LPT",
    lptJ: "PERSONALITY PROFILE",
    lptJC: "PP2",
}