import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import { Bei } from '../../../models/bei.model';
import { CompetencyType } from '../../../models/competency.model';
import { getQuestion, saveQuestion, updateQuestion } from '../../../util/apiService';
import history from '../../../util/history';
import RichEditor from '../../common/richeditor';

interface Props {
    match?: any;
    questionDetails?: Bei;
    assessmentId?: string;
}

interface State {
    beiQuestion: Bei
}

const getEmptyQuestion = () => {
    return {
        title: '', question: '', competency: '', options: [
            { name: 'What was the situation?', maxChars: 200 },
            { name: 'What were your actions (what did you think, do etc.)?', maxChars: 650 },
            { name: 'What was the result?', maxChars: 200 }
        ]
    }
}

class BeiQuestion extends Component<Props, State> {
    
    state: State = {
        beiQuestion: this.props.questionDetails || getEmptyQuestion()
    }

    componentDidMount() {
        const { match } = this.props;
        if(match) {
            const { params } = match;
            if (params && params.id) {
                const getQuestionPromise = getQuestion("bei", params.id)
                getQuestionPromise.then((response: any) => {
                    if (response && response.length > 0) {
                        this.setState({ beiQuestion: response[0] });
                    }
                });
                toast.promise(getQuestionPromise, {
                    loading: 'Fetching details',
                    success: 'Ready',
                    error: 'Fetching details failed'
                });
            }
        }
    }

    setTitle = (value: string) => {
        const beiQuestion = {...this.state.beiQuestion, title: value};
        this.setState({...this.state, beiQuestion});
    }

    setQuestion = (value: string) => {
        const beiQuestion = {...this.state.beiQuestion, question: value};
        this.setState({...this.state, beiQuestion});
    }

    setCompetency = (value: string) => {
        const beiQuestion = {...this.state.beiQuestion, competency: value};
        this.setState({...this.state, beiQuestion});
    }

    addOption = () => {
        const beiQuestion = {...this.state.beiQuestion, options: [...this.state.beiQuestion.options, {name: '', maxChars: 200}]};
        this.setState({...this.state, beiQuestion});
    }

    setOptionName = (optionIndex: number, value: string) => {
        const beiQuestion = {...this.state.beiQuestion, options: [...this.state.beiQuestion.options.slice(0, optionIndex), {...this.state.beiQuestion.options[optionIndex], name: value}, ...this.state.beiQuestion.options.slice(optionIndex + 1)]};
        this.setState({...this.state, beiQuestion});
    }

    setOptionMaxChars = (optionIndex: number, value: string) => {
        const beiQuestion = {...this.state.beiQuestion, options: [...this.state.beiQuestion.options.slice(0, optionIndex), {...this.state.beiQuestion.options[optionIndex], maxChars: parseInt(value)}, ...this.state.beiQuestion.options.slice(optionIndex + 1)]};
        this.setState({...this.state, beiQuestion});
    }

    saveQuestion = () => {
        if (this.state.beiQuestion._id) {
            const { _id, title, question, options, competency } = this.state.beiQuestion;
            const questionObject: any = {title, question, options, competency};
            if (this.props.assessmentId) {
                questionObject.assessmentId = this.props.assessmentId;
            }
            const updateQuestionPromise = updateQuestion("bei", _id, questionObject);
            updateQuestionPromise.then((response: any) => {

            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(updateQuestionPromise, {
                loading: 'Updating question',
                success: 'Question updated',
                error: 'Error updating question'
            });
        } else {
            const saveQuestionPromise = saveQuestion("bei", [{ ...this.state.beiQuestion }])
            saveQuestionPromise.then((response: any) => {
                history.push('/admin/create/bei/' + response[0]._id);
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveQuestionPromise, {
                loading: 'Saving question',
                success: 'Question saved',
                error: 'Error saving question'
            });
        }
    }

    removeOption = (optionIndex: number) => {
        const beiQuestion = {...this.state.beiQuestion, options: [...this.state.beiQuestion.options.slice(0, optionIndex), ...this.state.beiQuestion.options.slice(optionIndex + 1)]};
        this.setState({...this.state, beiQuestion});
    }

    render() {
        const {beiQuestion} = this.state;
        return (
            <div>
                <h3>Create CritiQal Question</h3>
                <div style={{ marginTop: 15 }}>
                    <TextField label='Title' value={beiQuestion.title} onChange={(e) => this.setTitle(e.target.value)} style={{ textAlign: 'right' }} />
                </div>
                <h4>Question</h4>
                <div style={{ marginTop: 15 }}>
                    <RichEditor setText={this.setQuestion} initialContent={beiQuestion.question} />
                </div>
                <div style={{ marginTop: 15 }}>
                    <FormControl fullWidth>
                        <InputLabel>Competency</InputLabel>
                        <Select value={beiQuestion.competency} onChange={(e) => this.setCompetency(e.target.value)}>
                            { CompetencyType.map((cType, cTypeIndex) => (
                                <MenuItem key={cTypeIndex} value={cType.id}>{cType.competency}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                { beiQuestion.options.map((option, optionIndex) => (
                    <div key={optionIndex} style={{marginTop: '15px'}}>
                        <TextField fullWidth label='Question' value={option.name} onChange={(e) => this.setOptionName(optionIndex, e.target.value)} style={{ textAlign: 'right', margin: '15px 0' }} />
                        <TextField type="number" label='Max Characters allowed' value={option.maxChars} onChange={(e) => this.setOptionMaxChars(optionIndex, e.target.value)} style={{ textAlign: 'right', margin: '15px 0' }} />
                        <div>
                            <Button variant="contained" color="primary" onClick={() => this.removeOption(optionIndex)}>Remove Question</Button>
                        </div>
                        <Divider style={{ marginTop: 15 }} />
                    </div>
                ))}
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="primary" onClick={this.addOption}>
                        Add Question
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="error" onClick={() => this.saveQuestion()}>
                        Save
                    </Button>
                </div>
            </div>
        )
    }
}

export default BeiQuestion;