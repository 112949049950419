import React, { Component } from "react";

interface Props {
    time: number;
    submitAnswers: (check: boolean) => void;
    finished: boolean;
    testType: string;
    setSJTConfirmation: (confirmation: boolean) => void;
}

interface State {
    time: number;
}

let timer: any;

class Timer extends Component<Props> {
    

    state: State = {
        time: this.props.time
    }

    componentDidMount() {
        window.setTimeout(() => {
            console.log("timer finished");
        }, (this.props.time * 60) * 1000);
        timer = window.setInterval(() => {
            if (this.state.time === 1) {
                window.clearInterval(timer);
                timer = null;
                if(this.props.testType === "sjt") {
                    this.props.setSJTConfirmation(true);
                } else {
                    this.props.submitAnswers(false);
                }
            }
            this.setState({ time: this.state.time - 1 });
        }, 1000);
    }

    displayTime(time: number) {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }

    static getDerivedStateFromProps(props: Props, current_state: State) {
        if(props.finished) {
            window.clearInterval(timer);
            timer = null;
            return {time: 0}
        }
        return null;
    }

    render() {
        const {time} = this.state;
        return(
            <div style={{ display: "flex", alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} className={time > 60 ? '' : 'alerts-border'}>
                <div style={{fontSize: '1em', flex: 1}}>
                    Time left
                </div>
                <div style={{ color: time > 300 ? 'black' : time > 60 ? 'orange' : 'red', fontSize: '2em', flex: 1 }} className={time > 60 ? '' : 'time-flash'}>
                    {this.displayTime(time)}
                </div>
            </div>
        )
    }
}

export default Timer;