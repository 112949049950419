import { withStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import Group from '../../models/group.model';
import User from '../../models/user.model';
import UserAssessment, { scoreCalculation, getDisplay,getPP2DislayNames, leftColumns,csvDownloadName } from '../../models/userassessment.model';
import { getScores, downloadUserResponse } from '../../util/apiService';
import { generateStyles } from '../../util/mainStyles';
import { getColor, getLEOAverageColor, getLEOColor, getLPTColor, getExitColor } from '../../util/theme';
import CustomTableHead from '../common/customTableHead';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DownloadIcon from '@mui/icons-material/CloudDownloadRounded';
import Aptitude, { aptitudeCompetencies } from '../../models/aptitude.model';
import JobSkills from '../../models/jobSkills.model';
interface OwnProps {
    match: any;
}
interface StyleProps {
    classes: any
}
interface State {
    group: Group;
    groupUsers: User[];
    userAssessments: UserAssessment[];
    showAssessments: string[];
    assessmentsSelected: string[],
    aptitudePresent: any,
    sjtPresent: any,
    beiPresent: any
    userResponse: object[],
    groupId: ''
    loading: boolean
}

type Props = OwnProps & StyleProps;

class Dashboard extends Component<Props, State> {
    state: State = {
        group: new Group(),
        groupUsers: [],
        userAssessments: [],
        showAssessments: [],
        assessmentsSelected: [],
        aptitudePresent: {},
        sjtPresent: {},
        beiPresent: {},
        userResponse: [],
        groupId: '',
        loading: false
    }

    componentDidMount() {
        const { match } = this.props;
        const { params } = match;
        if (params && params.id) {
            const getGroupScoresPromise = getScores(params.id)
            getGroupScoresPromise.then((response: any) => {
                const { group, groupUsers, userAssessments } = response;
                this.initialSetup(userAssessments);
                this.setState({ group, groupUsers, userAssessments });
            });
            toast.promise(getGroupScoresPromise, {
                loading: 'Fetching scores ...',
                success: 'Scores fetched',
                error: 'Fetching scores failed'
            });
        }
        this.setState({ groupId: params.id })
        this.downloadResponse = this.downloadResponse.bind(this);
    }

    initialSetup(userAssessments: UserAssessment[]) {
        const initialColumns = leftColumns.map((column: any) => column.name);
        const showAssessments: string[] = initialColumns;
        const assessmentsSelected: string[] = [...initialColumns];
        const testsPresent: any = { aptitude: 0, sjt: 0, bei: 0, inTray: 0, lpt: 0, leo: 0, jobSkills: 0, lptJ: 0 };
        const aptitudePresent: any = {};
        const sjtPresent: any = {};
        const beiPresent: any = {};

        // setting up tests that are included 
        for (const ua of userAssessments) {
            const userAssessmentType = ua.assessmentType;
            if (!userAssessmentType || !ua.completed) {
                continue;
            }
            testsPresent[userAssessmentType] = 1;
            if (userAssessmentType === "aptitude") {
                if (!ua.denom) continue;
                for (const comp of ua.denom) {
                    if (comp.v && comp.v > 0) aptitudePresent[comp.id] = 1;
                }
            }
            else if (userAssessmentType === "sjt") {
                if (!ua.denom) continue;
                for (const comp of ua.denom) {
                    if (comp.v && comp.v > 0) sjtPresent[comp.id] = 1;
                }
            }
            else if (userAssessmentType === "bei") {
                if (!ua.denom) continue;
                for (const comp of ua.denom) {
                    if (comp.v && comp.v > 0) beiPresent[comp.id] = 1;
                }
            }
        }

        // Creating the top bar of selectable elements
        for (const assessment of scoreCalculation) {
            if (testsPresent[assessment.assessment]) {
                showAssessments.push(assessment.assessment);
                assessmentsSelected.push(assessment.assessment);
            }
        }

        this.setState({ showAssessments, assessmentsSelected, aptitudePresent, sjtPresent, beiPresent });
    }

    getLPTDimensionScore(dimensions: { people: number, result: number }) {
        let { people, result } = dimensions;
        if (people >= 75 && result >= 75) return 'Style 1';
        if (people < 31.25 && result < 31.25) return 'Style 4';
        if (result < 31.25) return 'Style 2';
        if (people < 31.25) return 'Style 3';
        if (people >= 75) return 'Style 2';
        if (result >= 75) return 'Style 3';
        return 'Style 5';
    }

    calculateScoresTable() {
        const userScores: any = {};
        const { group, groupUsers, userAssessments, assessmentsSelected, aptitudePresent, sjtPresent, beiPresent } = this.state;
        const tableData: (number | string)[][] = [];
       
        if (!group || !groupUsers || !userAssessments) return { tableData, tableHeader: [], components: null };
        // setting up user objects 
        for (const ua of userAssessments) {
            const userAssessmentType = ua.assessmentType || "";
            if (!assessmentsSelected.includes(userAssessmentType) || !ua.completed || !userAssessmentType) {
                continue;
            }
            const userId = ua.userId;
            if (!(userId in userScores)) {
                userScores[userId] = {};
            }
            if (!(userAssessmentType in userScores[userId])) {
                userScores[userId][userAssessmentType] = {};
            }
            for (const userScore of (ua.scores || [])) {
                userScores[userId][userAssessmentType][userScore.id] = userScore.v;
            }
            if (ua.completed) {
                userScores[userId][userAssessmentType].completed = ua.completed;
            }
            userScores[userId].invited = ua.invited;
            userScores[userId][userAssessmentType].incomplete = ua.incomplete;
            userScores[userId][userAssessmentType].exitedFullScreen = ua.exitedFullScreen;
        }
        let userExistScreen:any=[];
        for (const user of groupUsers) {
            let isExitedFullScreen: string = "Not exited";
            if (!user._id || !(user._id in userScores)) {
                continue;
            }
            const thisUserScores = userScores[user._id];
            userExistScreen[user._id]="Not exited";
            let userScoresRow: (number | string)[] = [user.name, user.email, user._id.slice(user._id.length - 10).toUpperCase(), thisUserScores.invited ? new Date(thisUserScores.invited).toLocaleDateString("en-in") : "",
            user.ipAddress ? user.ipAddress : "", user.location ? user.location : ""];
            const anyUser: any = { ...user };
            for (const lc of leftColumns) {
                if (assessmentsSelected.includes(lc.name)) {
                    userScoresRow.push(anyUser[lc.name]);
                }
            }
            for (const aType of scoreCalculation) {

                if (!assessmentsSelected.includes(aType.assessment)) {
                    continue;
                }
                if (aType.assessment in thisUserScores && 'completed' in thisUserScores[aType.assessment] && thisUserScores[aType.assessment].incomplete !== true) {
                    userScoresRow.push(new Date(thisUserScores[aType.assessment].completed).toLocaleDateString("en-in"));
                } else {
                    userScoresRow.push("-");
                    if (aType.assessment === 'jobSkills') {
                        userScoresRow.push("");
                    } else {
                        if (aType.assessment === 'aptitude') {
                            for (const comp of aType.competencies) {
                                if (!(comp in aptitudePresent)) continue;
                                userScoresRow.push("");
                            }
                        } else if (aType.assessment === 'sjt') {
                            for (const comp of aType.competencies) {
                                if (!(comp in sjtPresent)) continue;
                                userScoresRow.push("");
                            }
                        } else if (aType.assessment === 'bei') {
                            for (const comp of aType.competencies) {
                                if (!(comp in beiPresent)) continue;
                                userScoresRow.push("");
                            }
                        } else {
                            for (const ass of aType.competencies) {
                                userScoresRow.push("");
                            }
                        }
                        userScoresRow.push("");
                    }
                    continue;
                }
                let aTypeTotal = 0;
                if (aType.assessment === 'lpt') {
                    const dimensionScore: any = { people: 0, result: 0 };
                    let anyScoreFound = false;
                    for (const comp of aType.competencies) {
                        let scoreFound = false;
                        let compTotal = 0;
                        for (const dim of aType.dimensions!) {
                            if ((aType.assessment in thisUserScores) && ((comp + ":" + dim) in thisUserScores[aType.assessment])) {
                                scoreFound = true;
                                anyScoreFound = true;
                                const dimension = comp + ":" + dim;
                                compTotal += thisUserScores[aType.assessment][dimension];
                                dimensionScore[dim] += (thisUserScores[aType.assessment][dimension]) / 2;
                            }
                        }
                        userScoresRow.push(scoreFound ? compTotal : 0);
                    }
                    userScoresRow.push(anyScoreFound ? this.getLPTDimensionScore(dimensionScore) : 0);
                } else if (aType.assessment === "lptJ") {
                    let totalScore = 0;
                    let anyScoreFound = false;
                    for (const comp of aType.competencies) {
                        let compTotal = 0;
                        let scoreFound = false;
                        for (const dim of aType.dimensions!) {
                            if ((aType.assessment in thisUserScores) && ((comp + ":" + dim) in thisUserScores[aType.assessment])) {
                                compTotal += thisUserScores[aType.assessment][comp + ":" + dim];
                                totalScore += thisUserScores[aType.assessment][comp + ":" + dim];
                                scoreFound = true;
                                anyScoreFound = true;
                            }
                        }
                        userScoresRow.push(scoreFound ? compTotal : 0);
                    }
                    userScoresRow.push(anyScoreFound ? Math.round(totalScore / 4) : 0);
                } else if (aType.assessment === "lptJC") {
                    let totalScore = 0;
                    let anyScoreFound = false;
                    for (const comp of aType.competencies) {
                        let compTotal = 0;
                        let scoreFound = false;
                        for (const dim of aType.dimensions!) {
                            if ((aType.assessment in thisUserScores) && ((comp + ":" + dim) in thisUserScores[aType.assessment])) {
                                compTotal += thisUserScores[aType.assessment][comp + ":" + dim];
                                totalScore += thisUserScores[aType.assessment][comp + ":" + dim];
                                scoreFound = true;
                                anyScoreFound = true;
                            }
                        }
                        userScoresRow.push(scoreFound ? compTotal : 0);
                    }
                    userScoresRow.push(anyScoreFound ? Math.round(totalScore / 4) : 0);
                } else if (aType.assessment === 'jobSkills') {
                    if (thisUserScores.jobSkills && 'jobSkills' in thisUserScores.jobSkills) {
                        userScoresRow.push(Math.round(thisUserScores.jobSkills.jobSkills * 10) / 10);
                    } else {
                        userScoresRow.push("");
                    }
                } else {
                    let scoreFound = false;

                    for (const comp of aType.competencies) {
                        if (aType.assessment === 'aptitude' && !(comp in aptitudePresent)) continue;
                        if (aType.assessment === 'sjt' && !(comp in sjtPresent)) continue;
                        if (aType.assessment === 'bei' && !(comp in beiPresent)) continue;
                        if (aType.assessment in thisUserScores && comp in thisUserScores[aType.assessment]) {
                            scoreFound = true;
                            userScoresRow.push(thisUserScores[aType.assessment][comp]);
                            aTypeTotal += thisUserScores[aType.assessment][comp];
                        } else {
                            userScoresRow.push(0);
                        }
                    }
                    if (aType.assessment === 'aptitude') {
                        userScoresRow.push(scoreFound ? (Math.round((aTypeTotal / Object.keys(aptitudePresent).length) * 10) / 10) : 0);
                    } else if (aType.assessment === 'sjt') {
                        userScoresRow.push(scoreFound ? (Math.round((aTypeTotal / Object.keys(sjtPresent).length) * 10) / 10) : 0);
                    } else if (aType.assessment === 'bei') {
                        userScoresRow.push(scoreFound ? (Math.round((aTypeTotal / Object.keys(beiPresent).length) * 10) / 10) : 0);
                    } else {
                        userScoresRow.push(scoreFound ? (Math.round((aTypeTotal / aType.denominator) * 10) / 10) : 0);
                    }
                }
               
                isExitedFullScreen = ((thisUserScores[aType.assessment].exitedFullScreen ||  userExistScreen[user._id]==="Exited") ? "Exited" : "Not Exited")
                userExistScreen[user._id]=isExitedFullScreen
            }
            console.log("userExistScreen",userExistScreen)

            userScoresRow.push(isExitedFullScreen)
            tableData.push(userScoresRow);
        }
        const columnsToShow: any = [];
        let tableHeader: any[] = [
            {
                name: "Name",
                options: {
                    filter: true,
                    sort: true,
                    setCellProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 100,
                        }
                    }),
                    setCellHeaderProps: () => ({
                        style: {
                            whiteSpace: "nowrap",
                            position: "sticky",
                            left: 0,
                            background: "white",
                            zIndex: 1000,
                        }
                    })
                }
            },
            {
                name: "Email",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "ID",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "Invited",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "Ip address",
                options: {
                    filter: true,
                    sort: true
                }
            },
            {
                name: "Location",
                options: {
                    filter: true,
                    sort: true
                }
            },
        ];

        for (let col of leftColumns) {
            if (assessmentsSelected.includes(col.name)) {
                tableHeader.push({
                    name: col.display,
                    options: {
                        filter: true,
                        sort: true
                    }
                });
            }
        }

        const relevantTests = scoreCalculation.filter((sc) => assessmentsSelected.includes(sc.assessment));
        const leftColumnsSelected = leftColumns.filter((col) => assessmentsSelected.includes(col.name));
        columnsToShow.push({ display: "User Details", headerWidth: (leftColumnsSelected.length + 5) });

        for (var j = 0; j < relevantTests.length; j++) {
            const aType = relevantTests[j];
            let headerWidth = aType.headerWidth;
            if (aType.assessment === 'aptitude') headerWidth = (Object.keys(aptitudePresent).length + 2);
            if (aType.assessment === 'sjt') headerWidth = (Object.keys(sjtPresent).length + 2);
            if (aType.assessment === 'bei') headerWidth = (Object.keys(beiPresent).length + 2);
            columnsToShow.push({ display: aType.display, headerWidth: headerWidth });
            tableHeader.push({
                name: "Completed",
                options: {
                    filter: true,
                    sort: true,
                    setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                        style: {
                            textAlign: 'center',
                            borderLeft: '2px solid #333'
                        }
                    }),
                    setCellHeaderProps: () => ({
                        style: {
                            borderLeft: '2px solid #333',
                            textAlign: 'center'
                        }
                    })
                },
            });
            if (aType.assessment === 'lpt') {
                for (const comp of aType.competencies) {
                    tableHeader.push({
                        name: comp.toUpperCase(),
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: (value: number) => ({
                                style: {
                                    backgroundColor: getLPTColor(value),
                                    textAlign: 'center',
                                }
                            }),
                            // setCellHeaderProps: () => ({
                            //     style: {
                            //         borderLeft: '2px solid #090909',
                            //         textAlign: 'center'
                            //     }
                            // })
                        }
                    });
                }
                tableHeader.push({
                    name: "Overall",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: () => ({
                            style: {
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'aptitude') {
                for (const comp of aType.competencies) {
                    if (comp in aptitudePresent) {
                        tableHeader.push({
                            name: comp.toUpperCase(),
                            options: {
                                filter: true,
                                sort: true,
                                setCellProps: (value: number) => ({
                                    style: {
                                        backgroundColor: getColor(value),
                                        textAlign: 'center',
                                    }
                                })
                            }
                        });
                    }
                }
                tableHeader.push({
                    name: "Average",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                            style: {
                                backgroundColor: aType.assessment === 'leo' ? getLEOAverageColor(value) : getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'sjt') {
                for (const comp of aType.competencies) {
                    if (comp in sjtPresent) {
                        tableHeader.push({
                            name: comp.toUpperCase(),
                            options: {
                                filter: true,
                                sort: true,
                                setCellProps: (value: number) => ({
                                    style: {
                                        backgroundColor: getColor(value),
                                        textAlign: 'center',
                                    }
                                })
                            }
                        });
                    }
                }
                tableHeader.push({
                    name: "Average",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                            style: {
                                backgroundColor: aType.assessment === 'leo' ? getLEOAverageColor(value) : getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'bei') {
                for (const comp of aType.competencies) {
                    if (comp in beiPresent) {
                        tableHeader.push({
                            name: comp.toUpperCase(),
                            options: {
                                filter: true,
                                sort: true,
                                setCellProps: (value: number) => ({
                                    style: {
                                        backgroundColor: getColor(value),
                                        textAlign: 'center',
                                    }
                                })
                            }
                        });
                    }
                }
                tableHeader.push({
                    name: "Average",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                            style: {
                                backgroundColor: aType.assessment === 'leo' ? getLEOAverageColor(value) : getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'lptJ') {
                for (const comp of aType.competencies) {
                    tableHeader.push({
                        name: comp.toUpperCase(),
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: (value: number) => ({
                                style: {
                                    backgroundColor: getColor(value),
                                    textAlign: 'center',
                                }
                            })
                        }
                    });
                }
                tableHeader.push({
                    name: "Overall",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number) => ({
                            style: {
                                backgroundColor: getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'lptJC') {
                for (const comp of aType.competencies) {
                    tableHeader.push({
                        name: getPP2DislayNames[comp],
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: (value: number) => ({
                                style: {
                                    backgroundColor: getColor(value),
                                    textAlign: 'center',
                                }
                            })
                        }
                    });
                }
                tableHeader.push({
                    name: "Overall",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number) => ({
                            style: {
                                backgroundColor: getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else if (aType.assessment === 'jobSkills') {
                tableHeader.push({
                    name: "Score",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number) => ({
                            style: {
                                backgroundColor: getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            } else {
                for (const comp of aType.competencies) {
                    tableHeader.push({
                        name: comp.toUpperCase(),
                        options: {
                            filter: true,
                            sort: true,
                            setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                                style: {
                                    backgroundColor: aType.assessment === 'leo' ? getLEOColor(value) : getColor(value),
                                    textAlign: 'center',
                                }
                            })
                        }
                    });
                }
                tableHeader.push({
                    name: "Average",
                    options: {
                        filter: true,
                        sort: true,
                        setCellProps: (value: number, rowIndex: number, columnIndex: number) => ({
                            style: {
                                backgroundColor: aType.assessment === 'leo' ? getLEOAverageColor(value) : getColor(value),
                                textAlign: 'center',
                            }
                        })
                    }
                })
            }
        }
        columnsToShow.push({ display: "Fullscreen", headerWidth: (leftColumnsSelected.length + 3) });
        tableHeader.push({
            name: "Exited Fullscreen",
            options: {
                filter: true,
                sort: true,
                setCellProps: (value: string, rowIndex: number, columnIndex: number) => ({
                    style: {
                        backgroundColor: getExitColor(value),
                        textAlign: 'center',
                        borderLeft: '2px solid #090909',
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        borderLeft: '2px solid #090909',
                        textAlign: 'center'
                    }
                })
            },
        });
        const components = {
            TableHead: (props: any) => {
                return <CustomTableHead columnsToShow={columnsToShow} {...props} />
            },
        }
        return ({ tableData, tableHeader, components });
    }

    toggleColumns = (newList: string[]) => {
        this.setState({ assessmentsSelected: newList })
    }

    onDownload = (buildHead: any, buildBody: any, columns: any, data: any) => {
        const { assessmentsSelected } = this.state;
        let firstRow: string[] = ["User details", "", "", "", "", ""];
        for (let col of leftColumns) {
            if (assessmentsSelected.includes(col.name)) {
                firstRow.push("");
            }
        }

        const relevantTests = scoreCalculation.filter((sc) => assessmentsSelected.includes(sc.assessment));
        for (const test of relevantTests) {
            firstRow.push(test.display);
            if (test.assessment === 'aptitude') {
                const emptyCells = Object.keys(this.state.aptitudePresent).map(() => "");
                firstRow = firstRow.concat(emptyCells);
            } else if (test.assessment === 'sjt') {
                const emptyCells = Object.keys(this.state.sjtPresent).map(() => "");
                firstRow = firstRow.concat(emptyCells);
            } else if (test.assessment === 'bei') {
                const emptyCells = Object.keys(this.state.beiPresent).map(() => "");
                firstRow = firstRow.concat(emptyCells);
            } else {
                firstRow = firstRow.concat(test.competencies.map(() => ""));
            }
            if (test.assessment !== 'jobSkills') {
                firstRow.push("");
            }

        }
        firstRow = firstRow.concat("FullScreen")
        let firstRowString: string = JSON.stringify(firstRow);
        firstRowString = firstRowString.substring(1, firstRowString.length - 1) + "\r\n";
        return firstRowString + buildHead(columns) + buildBody(data);
    }
    organizeQuestions = (questions: Aptitude[]) => {
        const sortedQuestionsLookup: any = {};
        const sortedQuestionsList: any = [];
        console.log("aptitudeCompetencies", aptitudeCompetencies)
        for (const comp of aptitudeCompetencies) {
            sortedQuestionsLookup[comp.id] = [];
        }
        const sortedQuestions = questions.sort((a, b) => (a.priority !== undefined && b.priority !== undefined) ? a.priority - b.priority : 0);
        for (const question of sortedQuestions) {
            sortedQuestionsLookup[question.category].push(question);
            sortedQuestionsList.push(question)
        }
        return sortedQuestionsLookup;
    }
    organizeJobSkillsQuestions = (questions: JobSkills[]) => {
        const sortedQuestionsLookup: any = [];
        const sortedQuestions = questions.sort((a, b) => (a.priority !== undefined && b.priority !== undefined) ? a.priority - b.priority : 0);
        return sortedQuestions;
    }
    /**
     * Download csv file for all type of assessment
     * @param ass 
     */
    downloadResponse = (ass: string) => {
        const downloadPromise = downloadUserResponse(this.state.groupId, ass)
        downloadPromise.then((response: any) => {
            const userResponse: any = response.csvFileData
            let assessmentQuestions: any = []
            let usersCsv: any = []
            let groupUser: any = []
            userResponse.reduce((acc: any, user: any) => {
                if (!groupUser[user.userId]) {
                    groupUser[user.userId] = [];
                }
                groupUser[user.userId].push(user);
                let { name, email, assessmentType, questionId, answer, userId } = user
                acc.push([name, email, assessmentType, questionId, answer, userId].join(','))
                return acc
            }, [])
            let headers = ['Name', 'Email', 'ID', 'Assessment Type']
            switch (ass) {
                case 'aptitude':
                    {
                        let orgQuestions: any = ass === 'aptitude' ? this.organizeQuestions(response.questions) : response.questions
                        let i = 1, j = 1, ques: any = []
                        for (let user in groupUser) {
                            let answers: any = []
                            let usrname = "", usremail = "", usrassessmentType = "", usrId = ""
                            for (const comp of aptitudeCompetencies) {
                                for (let question of orgQuestions[comp.id]) {
                                    let quesNo = "Question " + i
                                    if (j == 1) {
                                        ques.push([quesNo])
                                    }
                                    const data = groupUser[user].filter((q: any) => q.questionId == question._id)
                                    if (data && data.length > 0) {
                                        const { name, email, assessmentType, questionId, answer, userId } = data[0]
                                        usrname = name;
                                        usremail = email
                                        usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                        usrassessmentType = assessmentType
                                        if (question._id === questionId) {
                                            answers.push(answer)
                                        }
                                    } else {
                                        answers.push("-")
                                    }
                                    i++
                                }
                            }
                            j++
                            usersCsv.push([usrname, usremail, usrId, ass, answers].join(','))

                        }
                        headers.push(...ques)
                        break;
                    }
                case 'jobSkills':
                    let orgQuestions: any = this.organizeJobSkillsQuestions(response.questions)
                    let i = 1, j = 1, ques: any = []
                    for (let user in groupUser) {
                        let answers: any = []
                        let usrname = "", usremail = "", usrassessmentType = "", usrId = ""
                        for (let question of orgQuestions) {
                            const data = groupUser[user].filter((q: any) => q.questionId == question._id)
                            let quesNo = "Question " + i
                            if (j == 1) {
                                ques.push([quesNo])
                            }
                            if (data && data.length > 0) {
                                const { name, email, assessmentType, questionId, answer, userId } = data[0]
                                usrname = name;
                                usremail = email
                                usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                usrassessmentType = assessmentType
                                if (question._id === questionId) {
                                    answers.push(answer)
                                }
                            } else {
                                answers.push("-")
                            }
                            i++
                        }
                        j++
                        usersCsv.push([usrname, usremail, usrId, ass, answers].join(','))
                    }
                    headers.push(...ques)
                    break;
                case 'leo':
                case 'lpt':
                case 'lptJ':
                case 'lptJC':
                    {
                        usersCsv = []
                        let subHeaders: any = []
                        assessmentQuestions = ass === "leo" ? response.leoQuestions : response.lptQuestions;
                        for (const category of assessmentQuestions) {
                            subHeaders.push('Category')
                            for (const question of category.questions) {
                                subHeaders.push(question.question.replace(/\,/g, '^'));
                            }
                        }
                        let x = 0;
                        for (let user in groupUser) {
                            let answers: any = []
                            let usrname = "", usremail = "", usrassessmentType = "", usrId = ""
                            subHeaders = (x === 0 && ass === "lptJC") ? [] : subHeaders
                            for (let data of groupUser[user]) {
                                const { name, email, assessmentType, category, answer, userId } = data
                                usrname = name;
                                usremail = email
                                usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                usrassessmentType = assessmentType
                                if (ass === "lptJC") {
                                    if (x === 0) {
                                        subHeaders.push('Category')
                                    }
                                    answers.push(getPP2DislayNames[category])
                                    for (const ques of answer) {
                                        if (x === 0) {
                                            subHeaders.push(ques.question.replace(/\,/g, '^'));
                                        }
                                        answers.push(ques.answer)
                                    }
                                } else {
                                    assessmentQuestions.filter((item: any) => {
                                        if (item.category === category) {
                                            answers.push(category)
                                            item.questions.filter((obj: any) => {
                                                let key: any
                                                if (answer && answer.length > 0) {
                                                    if (ass === "leo") {
                                                        key = answer.findIndex((val: any) => val === obj.question);
                                                        if (key >= 0) {
                                                            answers.push(key + 1)
                                                        } else {
                                                            answers.push('-')
                                                        }
                                                    } else {
                                                        key = answer.findIndex((val: any) => val.question === obj.question);
                                                        answers.push(answer[key].answer)
                                                    }
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                            x++
                            usersCsv.push([usrname, usremail, usrId, ass, answers].join(','))
                        }
                        headers.push(subHeaders)
                        break;
                    }
                case 'sjt':
                    {
                        usersCsv = []
                        let answers: any = []
                        let usrname = "", usremail = "", usrassessmentType = "", usrId = ""
                        let addHeaders: any = [], j = 0
                        for (let user in groupUser) {
                            answers = []
                            for (let data of groupUser[user]) {
                                const { name, email, assessmentType, scenario, answer, userId } = data
                                usrname = name;
                                usremail = email
                                usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                usrassessmentType = assessmentType
                                let replacedTxt = scenario.replace(/\,/g, '^')
                                // replacedTxt=replacedTxt.replace(/\\/g, '');
                                let subScenario = replacedTxt.replace(/<\/?[^>]+(>|$)/g, "")
                                if (j == 0) {
                                    addHeaders.push('Sub Scenario', 'Rank 1', 'Rank 2', 'Rank 3', 'Rank 4')
                                }
                                answers.push(subScenario, ...answer)
                            }
                            j++
                            usersCsv.push([usrname, usremail, usrId, ass, [...answers]].join(','))
                        }
                        headers.push(...addHeaders)
                        break;
                    }
                case 'inTray':
                    {
                        usersCsv = []
                        let answers: any = []
                        let usrname = "", usremail = "", usrassessmentType = "", usrId = ""
                        let addHeaders: any = [], i = 0
                        for (let user in groupUser) {
                            answers = []
                            for (let data of groupUser[user]) {
                                const { name, email, assessmentType, emailSubject, hmlAnswer, actionAnswer, userId } = data
                                if (emailSubject) {
                                    usrname = name;
                                    usremail = email
                                    usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                    usrassessmentType = assessmentType
                                    if (i === 0) {
                                        addHeaders.push("email subject")
                                        addHeaders.push("hmlAnswer")
                                        addHeaders.push("actionAnswer")
                                    }
                                    answers.push(emailSubject)
                                    answers.push(hmlAnswer)
                                    answers.push(actionAnswer)

                                }

                            }
                            usersCsv.push([usrname, usremail, usrId, ass, answers].join(','))
                            i++
                        }
                        headers.push(...addHeaders)
                        break;
                    }
                case 'bei':
                    {
                        assessmentQuestions = response.beiQuestions;
                        usersCsv = []
                        let answerss: any = []
                        let usrname = "", usremail = "", usrassessmentType = "", usrquestionId = "", usrId
                        for (const question of assessmentQuestions) {
                            headers.push("Question");
                            for (const opt of question.options) {
                                let replacedTxt = opt.name.replace(/\,/g, '^')
                                headers.push(replacedTxt);
                            }
                        }
                        for (let user in groupUser) {
                            answerss = []
                            for (let data of groupUser[user]) {
                                const { name, email, assessmentType, questionId, answers, userId } = data
                                usrname = name;
                                usremail = email
                                usrId = userId ? userId.slice(userId.length - 10).toUpperCase() : userId
                                usrassessmentType = assessmentType
                                let beiQues = response.beiQuestions.findIndex((item: any, index: any) => item._id === questionId)
                                usrquestionId = beiQues >= 0 ? `Question ${beiQues + 1}` : "Question"
                                answerss.push(usrquestionId)
                                var modifiedArray = answers.map(function(object:any) {
                                        let replacedTxt = object.replace(/\,/g, '^')
                                    return replacedTxt;
                                  });
                                answerss.push(...modifiedArray)
                            }
                            usersCsv.push([usrname, usremail, usrId, ass, answerss].join(','))
                        }
                        break;
                    }
                default:
                    headers = ['Name', 'Email', 'ID', 'Assessment Type', 'QuestionId', 'Answer']
            }

            // Convert users data to a csv
            try {
                let datacsv = [headers.toString(), ...usersCsv].join('\n')
                const blob = new Blob([datacsv], { type: "text/csv" })
                const a = document.createElement('a')
                a.download = `User-${csvDownloadName[ass].toUpperCase()}-Response`
                a.href = window.URL.createObjectURL(blob)
                const clickEvt = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true,
                })
                a.dispatchEvent(clickEvt)
                a.remove()
            } catch (e) {
                console.log("e", e)
            }
        });

    }
    /**
     * Render download button in table header
     * @param ass 
     * @returns 
     */
    renderDownload = (ass: any) => {
        return (
            ass !== "employeeCode" && ass !== "contact" && ass !== "businessUnit" && ass !== "level" && ass !== "department" && ass !== "jobTitle" ?
                <DownloadIcon style={{ margin: "10px" }} color="action" onClick={() => { this.downloadResponse(ass) }}></DownloadIcon> : ""
        )
    }
    render() {
        const { group, showAssessments, assessmentsSelected } = this.state;
        const { tableData, tableHeader, components, } = this.calculateScoresTable();
        return (
            <div>
                <h3>Results for {group && group.clientName} - Assessment {group && group.name}</h3>
                <ToggleButtonGroup fullWidth size="large" color="standard" value={assessmentsSelected} onChange={(event, newList) => this.toggleColumns(newList)}>
                    {showAssessments.map((ass) => (
                        <ToggleButton key={ass} value={ass} style={{ textTransform: 'none', padding: "6px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <span>{ass === 'personal' ? 'User Details' : csvDownloadName[ass]}</span>
                                {this.renderDownload(ass)}
                            </div>
                        </ToggleButton>
                    ))}

                </ToggleButtonGroup>

                {tableHeader && tableData && components &&
                    <MUIDataTable title="Scores" data={tableData} columns={tableHeader} options={{ caseSensitive: false, rowsPerPageOptions: [20, 50, 100], rowsPerPage: 20, selectableRows: 'none', fixedHeader: true, responsive: 'standard', filter: false, print: false, viewColumns: false, downloadOptions: { filename: 'ScoresTable' + (this.state.group ? (' - ' + this.state.group.name) : '') + '.csv' }, onDownload: this.onDownload }} components={components} />
                }
            </div>
        )
    }
}

const styledDashboard: any = withStyles(generateStyles, { withTheme: true })(Dashboard);
export { styledDashboard as Dashboard };