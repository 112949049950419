import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { userDetailById } from '../../../util/apiService';
import toast from 'react-hot-toast';
interface Props {
    match: any;
}
interface State {
    usersDetails:any;
}
class UserDetails extends Component<Props, State> {
    state: State = {
       usersDetails:{}
    }

    componentDidMount() {
        const userDetailInfo = localStorage.getItem('userDetails');
        let userId:any
        if (userDetailInfo) {
            const userData = JSON.parse(userDetailInfo);
            userId = userData.userDetails.id
        }
        const userDetails = userDetailById(userId);
        userDetails.then((usersDetailsRes:any) => {
           this.setState({usersDetails:usersDetailsRes.userDetails})
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(userDetails, {
            loading: 'Fetching user details ...',
            success: 'Fetch user',
            error: 'Fetching user failed'
        });
    }

  
    render() {
        return (
            <div>
                <h3>User Details</h3>
                    <Typography>User name: {this.state.usersDetails.username}</Typography>
            </div>
        )
    }
}

export default UserDetails;