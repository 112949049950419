import { createTheme } from "@mui/material";
import { green, red } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(127, 255, 211)'
        },
        secondary: {
            main: green[500]
        },
        error: {
            main: red[500],
        },
    },
});

export default theme;

export function getColor(num: number | string) {
    if (num === "") return '#fff';
    if (num >= 70) return '#A1DE93';
    if (num > 35) return '#F7F48B';
    return '#F49C9C';
}

export function getLPTColor(num: number | string) {
    if (num === "") return '#fff';
    if (num >= 75) return '#A1DE93';
    if (num > 25) return '#F7F48B';
    return '#F49C9C';
}

export function getLEOColor(num: number | string) {
    if (num === "") return '#fff';
    if (num >= 40) return '#A1DE93';
    if (num > -40) return '#F7F48B';
    return '#F49C9C';
}

export function getLEOAverageColor(num: number | string) {
    if (num === "") return '#fff';
    if (num >= 50) return '#A1DE93';
    return '#F7F48B';
}

export function getExitColor(num:string){
    if (num ==="Exited") return '#F49C9C';
    else return '#A1DE93';
}
export function disableCopyPaste() {
    document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });
    document.addEventListener("keydown", function (event) {
        if (event.ctrlKey) {
            event.preventDefault();
        }
    });
}
const detectFullScreen = () => {
    return document.fullscreenElement;
};

let onFullScreenChange = () => {
    let isFullScreen = detectFullScreen();
    if (!isFullScreen) {
        console.log("exit")
    } else {
        console.log("enter")
    }
};
export function detectFullScreenExit() {
    document.addEventListener('fullscreenchange', onFullScreenChange);
    document.addEventListener('mozfullscreenchange', onFullScreenChange);
    document.addEventListener('webkitfullscreenchange', onFullScreenChange);
    document.addEventListener('MSFullscreenChange', onFullScreenChange);
}
