import React, { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import BulletIcon from '@mui/icons-material/RadioButtonChecked';
import CommonInstructions from './commonInstruction'
interface Props {
    time: number | null; // minutes
}

class LEOInstructions extends Component<Props> {
    render() {
        return (
            <React.Fragment>
                <h2>Welcome to PsyQ LEO!</h2>
                <List>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            PsyQ LEO is a psychometric test designed to help you understand your profile in terms of different dimensions of Leadership &amp; Entrepreneurial Orientation
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            In this test, you will be presented with 5 sets of 10 statements each. For each set, 
                            read the 10 statements and:<br />
                            <div style={{marginLeft: '20px'}}>
                                Step 1: <span style={{ textDecoration: 'underline' }}>Select the 5 statements</span> that most apply to you and <span style={{ textDecoration: 'underline' }}>drag-drop them in the adjacent grey box</span> – the box will turn green when 5 statements have been dropped into it
                            </div>
                            <div style={{marginLeft: '20px'}}>
                                Step 2: <span style={{ textDecoration: 'underline' }}>Rank the statements</span> in the green box from 1 (Most applicable/ relevant) to 5 (Least applicable/ relevant) by <span style={{ textDecoration: 'underline' }}>moving them up/down within the green box</span>
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Once you have completed the above actions for all 5 sets of statements, you must click ‘Submit’.
                            <div style={{ marginLeft: '20px' }}>
                                The test will not be submitted unless all 5 sets are completed and the ‘Submit’ button clicked.
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Please note that there are no ‘right’ or ‘wrong’ answers to the questions. For the test results to be a true reflection of your personality, it is important that you respond to this test naturally (who you are, rather than who you want to be).
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time allowed: {this.props.time ? (this.props.time + " minutes") : <CircularProgress size="1rem" />}
                            <div style={{ marginLeft: '20px' }}>
                                The above time limit is adequate for completing the test. Please complete it in one sitting.
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                Any test which is not submitted within the allocated time, will not be evaluated
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time will begin when you press the start button below.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        Once you have pressed 'Start', you will need to complete the test within the given time - please do not close the window/ refresh the page/ stop the test midway. You will not be able to access this test again (through the link provided) once you have started.
                        </ListItemText>
                    </ListItem>
                </List>
                <CommonInstructions></CommonInstructions>
            </React.Fragment>
        )
    }
}

export default LEOInstructions;