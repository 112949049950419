import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DropzoneArea } from 'mui-file-dropzone';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import JobSkills from '../../../models/jobSkills.model';
import { getQuestion, saveQuestion, updateQuestion, uploadImage } from '../../../util/apiService';
import history from '../../../util/history';
import RichEditor from '../../common/richeditor';

interface State {
    question: JobSkills;
}
interface Props {
    match?: any;
    questionDetails?: JobSkills;
    assessmentId?: string;
}

const getEmptyQuestion = () => {
    return { question: '', options: [{ id: '', answer: '' }], level: -1 };
}

class JobSkillsQuestion extends Component<Props, State> {
    
    state: State = {
        question: this.props.questionDetails || getEmptyQuestion(),
    }

    componentDidMount() {
        const { match } = this.props;
        if(match) {
            const { params } = match;
            if (params && params.id) {
                const getQuestionPromise = getQuestion("jobSkills", params.id);
                getQuestionPromise.then((response: any) => {
                    if (response && response.length > 0) {
                        this.setState({ question: response[0] });
                    }
                });
                toast.promise(getQuestionPromise, {
                    loading: 'Fetching details',
                    success: 'Ready',
                    error: 'Fetching details failed'
                });
            }
        }
    }

    removeOption = (optionIndex: number) => {
        const {question} = this.state;
        const options = question.options;
        options.splice(optionIndex, 1);
        if(options.length === 0) {
            options.push({id: '', answer: ''});
        }
        question.options = options;
        this.setState({...this.state, question});
    }

    moveOption = (optionIndex: number, step: number) => {
        const { question } = this.state;
        const options = question.options;
        const newIndex = optionIndex + step;
        if(newIndex >= 0 && newIndex < options.length) {
            const option = options[optionIndex];
            options.splice(optionIndex, 1);
            options.splice(newIndex, 0, option);
            this.setState({...this.state, question});
        }
    }

    addOption = () => {
        const { question } = this.state;
        const options = question.options;
        options.push({id: '', answer: ''});
        this.setState({...this.state, question});
    }

    setOption = (optionIndex: number, value: string) => {
        const { question } = this.state;
        const options = question.options;
        options[optionIndex] = {id: optionIndex.toString(), answer: value};
        this.setState({...this.state, question});
    }

    setDifficulty(difficulty: any) {
        const { question } = this.state;
        question.level = parseInt(difficulty);
        this.setState({...this.state, question});
    }

    setText = (value: string) => {
        const { question } = this.state;
        question.question = value;
        this.setState({...this.state, question});
    }

    setType = (value: string) => {
        const { question } = this.state;
        question.type = value;
        this.setState({ ...this.state, question });
    }
    
    uploadImage = (files: any) => {
        if (!files || files.length === 0) {
            const {question} = this.state;
            question.image = "";
            this.setState({ question });
            return;
        }
        const uploadImagePromise = uploadImage(files[0])
        uploadImagePromise.then((response: any) => {
            const { question } = this.state;
            question.image = response.message;
            this.setState({ question });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(uploadImagePromise, {
            loading: 'Uploading Image',
            success: 'Image uploaded',
            error: 'Error uploading image'
        });
    }

    setAnswer = (checked: boolean, optionIndex: number) => {
        if(checked) {
            const {question} = this.state;
            question.answer = optionIndex;
            this.setState({...this.state, question});
        }
    }

    saveQuestion = () => {
        if (this.state.question._id) {
            const { _id, question, image, options, answer, level } = this.state.question;
            if( !question || question.length === 0) {
                window.alert("Please enter a question");
                return;
            }
            if (answer === null || answer === undefined) {
                window.alert("Please select an answer");
                return;
            }

            const questionObject: any = { question, image, options, answer, level };
            if (this.props.assessmentId) {
                questionObject.assessmentId = this.props.assessmentId;
            }
            const updateQuestionPromise = updateQuestion("jobSkills", _id, questionObject);
            updateQuestionPromise.then((response: any) => {
                
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(updateQuestionPromise, {
                loading: 'Updating question',
                success: 'Question updated',
                error: 'Error updating question'
            });
        } else {
            const { question, options, answer } = this.state.question;
            if (!question || question.length === 0) {
                window.alert("Please enter a question");
                return;
            }
            if (answer === null || answer === undefined) {
                window.alert("Please select an answer");
                return;
            }
            if (options === null || options.length === 0) {
                window.alert("Please select an answer");
                return;
            }
            const saveQuestionPromise = saveQuestion("jobSkills", [{ ...this.state.question }])
            saveQuestionPromise.then((response: any) => {
                history.push('/admin/create/jobSkills/' + response[0]._id);
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveQuestionPromise, {
                loading: 'Saving question',
                success: 'Question saved',
                error: 'Error saving question'
            });
        }
    }

    render() {
        const {question} = this.state;
        return (
            <div key={question?._id}>
                <h3>Create Job Competency Question</h3>
                <div style={{ marginTop: 15 }}>
                    <RichEditor setText={this.setText} initialContent={question.question} />
                </div>
                <div style={{ marginTop: 15 }}>
                    <TextField label='Type' style={{ width: '50%' }} onChange={(e) => this.setType(e.target.value)} value={question.type} />
                </div>
                <div style={{marginTop: 15}}>
                    <FormControl fullWidth>
                        <InputLabel>Select difficulty:</InputLabel>
                        <Select value={question.level} label="Select difficulty:" onChange={(e) => this.setDifficulty(e.target.value)}>
                            <MenuItem value={-1} key={-1}></MenuItem>
                            {["Low", "Medium", "High"].map((level: string, dIndex: number) => (
                                <MenuItem value={dIndex} key={dIndex}>{level}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginTop: 15 }}>
                    {!question.image ?
                        <DropzoneArea
                            acceptedFiles={['image/*']}
                            maxFileSize={5000000}
                            fileObjects={[]}
                            filesLimit={1}
                            dropzoneText='Drag and drop image for question image here or click to select'
                            onChange={(loadedFiles: any) => {
                                this.uploadImage(loadedFiles);
                            }}
                            showAlerts={false}
                        />
                        :
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ display: 'inline-block', position: 'relative' }}>
                                <img src={'https://inqimages.s3.amazonaws.com/dev/' + question.image} style={{ width: '500px', border: '1px solid #000' }} />
                                <IconButton color="error" style={{ position: 'absolute', top: 15, right: -45 }} onClick={() => this.uploadImage(null)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <ul>
                        { question.options.map((option: any, optionIndex: number) => (
                            <li style={{lineHeight: '5em'}} key={optionIndex}>
                                <Checkbox checked={question.answer === optionIndex} onChange={(e) => this.setAnswer(e.target.checked, optionIndex)} />
                                <TextField label={'Option ' + (optionIndex+1)} style={{ width: '80%', marginTop: 15}} value={option.answer} onChange={(e) => this.setOption(optionIndex, e.target.value)} />
                                <IconButton aria-label="delete" onClick={() => this.removeOption(optionIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                                { optionIndex !== 0 &&
                                    <IconButton aria-label="move up" onClick={() => this.moveOption(optionIndex, -1)}>
                                        <ArrowUpIcon />
                                    </IconButton>
                                }
                                { optionIndex !== question.options.length - 1 &&
                                    <IconButton aria-label="delete" onClick={() => this.moveOption(optionIndex, 1)}>
                                        <ArrowDownIcon />
                                    </IconButton>
                                }
                            </li>
                        ))}
                    </ul>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="primary" onClick={() => this.addOption()}>
                        Add Option
                    </Button>
                </div>
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="error" onClick={() => this.saveQuestion()}>
                        Save
                    </Button>
                </div>
            </div>
        );
    }
}

export default JobSkillsQuestion;