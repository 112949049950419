import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import Assessment from '../../../models/assessments.model';
import LPT from '../../../models/lpt.model';
import { generateStyles } from '../../../util/mainStyles';
import { Margin, Padding } from '@mui/icons-material';

interface OwnProps {
    questions: LPT[];
    assessment: Assessment;
    setLPTAnswer: (questionIndex: number, subQuestionIndex: number, value: number) => void;
    answers: any;
    lptQuestionsList: any;
}
interface StyleProps {
    classes: any;
}

type Props = OwnProps & StyleProps;

const options = [
    {
        display: "Never",
        value: 0
    }, 
    {
        display: "Rarely",
        value: 1
    }, 
    {
        display: "Sometimes",
        value: 2
    }, 
    {
        display: "Often",
        value: 3
    }, 
    {
        display: "Always",
        value: 4
    }
];

class LPTTest extends Component<Props> { 

    render() {
        const { answers, setLPTAnswer, lptQuestionsList } = this.props;
        
        return(
            <Grid container spacing={0} direction="row" alignItems="center" >
                <Grid item xs={12}>
                    <Card style={{ marginBottom: '30px', marginTop: '30px' }}>
                        <CardContent>
                            <Table stickyHeader padding='none'  >
                                <TableHead>
                                    <TableRow >
                                        <TableCell  >Question</TableCell>
                                        { options.map((op, opIndex) => (
                                            <TableCell  padding='none' key={op.display} >{op.display}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody  >
                                    {lptQuestionsList.map((subQ: any, subQIndex: number) => (
                                        <TableRow key={subQIndex}   >
                                            <TableCell padding='none'  style={{ color: 'answer' in answers[subQ.questionIndex].questions[subQ.subQuestionIndex] ? 'green' : 'black' }} >{subQ.question}</TableCell>
                                            {options.map((op, opIndex) => (
                                                <TableCell padding='none' sx={{padding:'5px'}} key={op + "-" + opIndex}>
                                                    <Radio checked={answers[subQ.questionIndex].questions[subQ.subQuestionIndex].answer === op.value} onChange={(e) => setLPTAnswer(subQ.questionIndex, subQ.subQuestionIndex, op.value)} />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const styledLPT: any = withStyles(generateStyles, { withTheme: true })(LPTTest);
export { styledLPT as LPTTest };