import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import JobSkills from '../../../../models/jobSkills.model';
import { addAssessment, deleteAssessment, deleteQuestion, getQuestions, saveQuestion, updateQuestion } from '../../../../util/apiService';
import ellipsify from '../../../../util/ellipsify';
import JobSkillsQuestion from '../../questions/jobSkillsQuestion';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AptitudeQuestionPicker } from '../../../common/aptitudeQuestionPicker';
import UpIcon from '@mui/icons-material/ArrowUpward';
import DownIcon from '@mui/icons-material/ArrowDownward';

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string, deleteIndex: number) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
}
interface State {
    jobSkillsIncluded: boolean;
    questions: any;
    selectedQuestions: JobSkills[] | null;
    autocompleteValue: any;
    numQuestions: number;
    questionDifficulties: number;
    questionPickerShown: boolean;
    shortlistedQuestions: any;
    type: string;
}

const assessmentType = "jobSkills";
class JobSkillsDetails extends Component<Props, State> {

    state: State = {
        jobSkillsIncluded: this.props.assessment ? true : false,
        questions: [],
        selectedQuestions: null,
        autocompleteValue: null,
        numQuestions: 0,
        questionDifficulties: -1,
        questionPickerShown: false,
        shortlistedQuestions: [],
        type: ""
    }

    questionBank: JobSkills[] = [];

    componentDidMount() {
        if (this.props.assessment) {
            const getAssessmentQuestionsPromise = getQuestions(assessmentType, this.props.assessment._id);
            getAssessmentQuestionsPromise.then((response: any) => {
                const selectedQuestions: any[] = [];
                for(const question of response) {
                    selectedQuestions.push(question);
                }
                this.setState({ selectedQuestions });
            });
            toast.promise(getAssessmentQuestionsPromise, {
                loading: 'Fetching assessment questions',
                success: 'Assessment questions fetched',
                error: 'Error fetching assessment questions'
            });
        }
        
        if (this.state.questions.length === 0) {
            const getQuestionsPromise = getQuestions(assessmentType);
            getQuestionsPromise.then((response: any) => {
                this.setState({ questions: response })
            });
            toast.promise(getQuestionsPromise, {
                loading: 'Fetching question bank',
                success: 'Question bank fetched',
                error: 'Error fetching question bank'
            });
        }
    }

    addQuestion = (jobSkillsQuestion: JobSkills | null) => {
        if (!jobSkillsQuestion) return;
        const { image, question, options, answer, level, priority } = jobSkillsQuestion;
        const questionObject: JobSkills = { image, question, options, answer, level, priority };
        questionObject.assessmentId = this.props.assessment._id;
        const saveQuestionPromise = saveQuestion(assessmentType, [{ ...questionObject }]);
        saveQuestionPromise.then((response: any) => {
            let { selectedQuestions } = this.state;
            if(selectedQuestions) {
                selectedQuestions.push(response[0]);
            } else {
                selectedQuestions = [response[0]];
            }
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(saveQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    updateQuestion = (jobSkillsQuestion: JobSkills | null, newPriority: number) => {
        if (!jobSkillsQuestion) return;
        const updateQuestionPromise = updateQuestion(assessmentType, jobSkillsQuestion._id || "", jobSkillsQuestion);
        updateQuestionPromise.then((response: any) => {
            let { selectedQuestions } = this.state;
            if(selectedQuestions) {
                for(const q of selectedQuestions) {
                    if(q._id === jobSkillsQuestion._id) {
                        q.priority = newPriority;
                        break;
                    }
                }
            }
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(updateQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }

    setNumQuestions = (value: string) => {
        this.setState({ ...this.state, numQuestions: parseInt(value)});
    }
    
    setQuestionDifficulties = (value: any) => {
        this.setState({ ...this.state, questionDifficulties: parseInt(value)});
    }

    setType = (value: string) => {
        this.setState({ type: value });
    }

    includeJobSkills(jobSkillsIncluded: boolean) {
        if (jobSkillsIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, assessmentType)
            addAssessmentPromise.then((response) => {
                this.props.addAssessment(assessmentType, response);
                this.setState({ jobSkillsIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding assessment ...',
                success: 'Added assessment',
                error: 'Adding assessment failed'
            });

            if (this.state.questions.length === 0) {
                const getQuestionsPromise = getQuestions(assessmentType);
                getQuestionsPromise.then((response: any) => {
                    this.setState({ questions: response })
                });
                toast.promise(getQuestionsPromise, {
                    loading: 'Fetching question bank',
                    success: 'Question bank fetched',
                    error: 'Error fetching question bank'
                });
            }
        } else {
            var shouldDelete = window.confirm('Delete Job Competency assessment and all questions?');
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(assessmentType, 0);
                this.setState({ jobSkillsIncluded: false, selectedQuestions: [] });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting assessment ...',
                success: 'Deleted assessment',
                error: 'Deleting assessment failed'
            });
        }
    }

    fillQuestions = () => {
        const {numQuestions, questionDifficulties, questions, selectedQuestions, type} = this.state;
        if(numQuestions > 0) {
            const categoryQuestions = this.getQuestions();
            let currentPriority = selectedQuestions ? (selectedQuestions.length > 0 ? selectedQuestions[selectedQuestions.length - 1].priority || 1 : 1) : 1;
            const shuffled = categoryQuestions.sort(() => 0.5 - Math.random());
            const selected = shuffled.slice(0, numQuestions);
            for(var i=0; i<selected.length; i++) {
                selected[i].priority = currentPriority;
                currentPriority++;
                this.addQuestion(selected[i]);
            }
        }
    }

    deleteQuestion = (qIndex: number, event: any) => {
        event.stopPropagation();
        var shouldDelete = window.confirm('Delete question?');
        if (!shouldDelete) {
            return;
        }
        const question: any = this.state.selectedQuestions![qIndex];
        const deleteQuestionPromise = deleteQuestion(assessmentType, question._id);
        deleteQuestionPromise.then(() => {
            const { selectedQuestions } = this.state;
            selectedQuestions!.splice(qIndex, 1);
            this.setState({ selectedQuestions });
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(deleteQuestionPromise, {
            loading: 'Deleting question ...',
            success: 'Deleted question',
            error: 'Deleting question failed'
        });
    }

    getQuestions = () => {
        const {questions, questionDifficulties, selectedQuestions, type} = this.state;
        const selectedQuestionTexts = selectedQuestions ? selectedQuestions.map((q: any) => q.question): [];
        let shortlistedQuestions = questions.filter((q: any) => !selectedQuestionTexts.includes(q.question));
        if(questionDifficulties > -1) {
            shortlistedQuestions = shortlistedQuestions.filter((q: any) => q.level === questionDifficulties);
        }
        if(type) {
            shortlistedQuestions = shortlistedQuestions.filter((q: any) => q.type && q.type.toLowerCase().includes(type.toLowerCase()));
        }
        return shortlistedQuestions;
    }

    toggleQuestionPicker = (competencyType?: string) => {
        if(competencyType) {
            this.setState({questionPickerShown: !this.state.questionPickerShown, shortlistedQuestions: this.getQuestions()});
        } else {
            this.setState({questionPickerShown: !this.state.questionPickerShown});
        }
    }

    addQuestionsFromPicker = (questions: JobSkills[]) => {
        if(questions.length > 0) {
            const {selectedQuestions} = this.state;
            let currentPriority = 0;
            if(selectedQuestions && selectedQuestions.length > 0) {
                const lastQuestion = selectedQuestions[selectedQuestions.length - 1];
                currentPriority = (lastQuestion.priority || 0) + 1;
            }
            for(let q of questions) {
                q.priority = currentPriority;
                currentPriority++;
                this.addQuestion(q);
            }
        }
        this.setState({questionPickerShown: false});
    }

    moveQuestion = (e: any, questionIndex: number, movement: number) => {
        e.stopPropagation();
        const orderedQuestions = this.getSortedQuestions();
        const replacementQuestionIndex = questionIndex + movement;
        const replaceQuestion = orderedQuestions[replacementQuestionIndex];
        const currentQuestion = orderedQuestions[questionIndex];
        const replacementQuestionPriority = orderedQuestions[replacementQuestionIndex].priority;
        replaceQuestion.priority = orderedQuestions[questionIndex].priority;
        currentQuestion.priority = replacementQuestionPriority;
        this.updateQuestion(replaceQuestion, replaceQuestion.priority || 0);
        this.updateQuestion(currentQuestion, currentQuestion.priority || 0);
    }

    getSortedQuestions = () => {
        const sortedQuestions = this.state.selectedQuestions!.sort((a, b) => (a.priority !== undefined && b.priority !== undefined) ? a.priority - b.priority : 0)
        return sortedQuestions;
    }

    render() {
        const { jobSkillsIncluded, selectedQuestions, numQuestions, questionDifficulties, questionPickerShown, shortlistedQuestions, type } = this.state;
        const { assessment, setAssessmentTime, saveAssessmentTime } = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={jobSkillsIncluded} onChange={(e) => this.includeJobSkills(e.target.checked)} />} label="Include Job Competency" />
                </FormGroup>
                {jobSkillsIncluded &&
                    <React.Fragment>
                        <div style={{ marginTop: 15 }}>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{flex: 8}}>
                                    <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime(assessmentType, e.target.value)} type="number" />
                                </div>
                                <div style={{flex: 2, textAlign: 'left', marginLeft: 20}}>
                                    <Button variant="contained" color="primary" onClick={() => saveAssessmentTime(assessmentType)}>Save</Button>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 15, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <div style={{ flex: 1, margin: '0 10px', flexDirection: 'column' }}>
                                <div style={{flex: 1}}>
                                    <TextField type="number" label={"Number of Questions"} value={numQuestions} onChange={(e) => this.setNumQuestions(e.target.value)} style={{ textAlign: 'right' }} />
                                </div>
                                <div style={{flex: 1, marginTop: 15}}>
                                    <FormControl fullWidth>
                                        <InputLabel>Select difficulty:</InputLabel>
                                        <Select value={questionDifficulties} label="Select difficulty:" onChange={(e) => this.setQuestionDifficulties(e.target.value)}>
                                            <MenuItem value={-1} key={-1}>Any</MenuItem>
                                            {["Low", "Medium", "High"].map((level: string, dIndex: number) => (
                                                <MenuItem value={dIndex} key={dIndex}>{level}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ marginTop: 15 }}>
                                    <TextField label='Type' style={{ width: '50%' }} onChange={(e) => this.setType(e.target.value)} value={type} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Button color="primary" variant="contained" onClick={this.fillQuestions} >Autofill Random Questions</Button>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <React.Fragment >
                                <div style={{ marginTop: 15 }}>
                                    <h3>Selected Questions</h3>
                                </div>
                                {selectedQuestions && this.getSortedQuestions().map((q, qIndex) => (
                                    <Accordion defaultExpanded={false} key={qIndex}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                <Typography sx={{ flexShrink: 0, flex: 8 }}>
                                                    <div dangerouslySetInnerHTML={{ __html: q.question }} />
                                                </Typography>
                                                <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                                                    { qIndex !== 0 &&
                                                        <IconButton onClick={(e) => this.moveQuestion(e, qIndex, -1)} >
                                                            <UpIcon />
                                                        </IconButton>
                                                    }
                                                    { qIndex !== selectedQuestions.length - 1 &&
                                                        <IconButton onClick={(e) => this.moveQuestion(e, qIndex, 1)} >
                                                            <DownIcon />
                                                        </IconButton>
                                                    }
                                                    <IconButton onClick={(e) => this.deleteQuestion(qIndex, e)} >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <JobSkillsQuestion questionDetails={q} assessmentId={assessment?._id} />
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                                <div style={{ marginTop: 15 }}>
                                    <Button variant="contained" color="primary" onClick={() => this.toggleQuestionPicker("open")} >Select Questions</Button>
                                </div>
                            </React.Fragment>
                            <AptitudeQuestionPicker open={questionPickerShown} onClose={() => this.toggleQuestionPicker()} questions={shortlistedQuestions} addQuestionsFromPicker={this.addQuestionsFromPicker} />
                        </div>
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default JobSkillsDetails;