import { IntrayCompetencyTypeScore } from "../models/intray.model";

export const intrayHMLScoreTable: any = {
    Q1: { cf: [-2, 2, 5], pd: [0, 0, 0], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q2: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [-5, 2, 5], dm: [-5, 2, 5] },
    Q3: { cf: [0, 0, 0], pd: [0, 2, 1], ee: [0, 2, 1], dm: [0, 0, 0] },
    Q4: { cf: [0, 2, 1], pd: [0, 0, 0], ee: [0, 2, 1], dm: [0, 0, 0] },
    Q5: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [2, 1, -2], dm: [0, 0, 0] },
    Q6: { cf: [0, 0, 0], pd: [-2, 2, 5], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q7: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 2, 1], dm: [0, 0, 0] },
    Q8: { cf: [-2, 2, 5], pd: [0, 0, 0], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q9: { cf: [0, 0, 0], pd: [0, 2, 1], ee: [0, 2, 1], dm: [0, 0, 0] },
    Q10: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [2, 1, -2], dm: [0, 0, 0] },
    Q11: { cf: [-2, 2, 5], pd: [0, 0, 0], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q12: { cf: [0, 0, 0], pd: [-2, 2, 5], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q13: { cf: [0, 0, 0], pd: [-2, 2, 5], ee: [0, 2, 1], dm: [-2, 2, 5] },
    Q14: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [-5, 2, 5], dm: [-5, 2, 5] },
    Q15: { cf: [-2, 2, 5], pd: [0, 0, 0], ee: [0, 2, 1], dm: [-2, 2, 5] }
}

export const intrayActionScoreTable: any = {
    Q1: { cf: [2, 1, 5], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q2: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 0, 0], dm: [1, 5, 2] },
    Q3: { cf: [0, 0, 0], pd: [5, 2, 1], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q4: { cf: [5, 1, 2], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q5: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q6: { cf: [0, 0, 0], pd: [5, 1, 2], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q7: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 0, 0], dm: [2, 5, 1] },
    Q8: { cf: [1, 5, 2], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q9: { cf: [0, 0, 0], pd: [5, 2, 1], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q10: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q11: { cf: [5, 1, 2], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q12: { cf: [0, 0, 0], pd: [2, 1, 5], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q13: { cf: [0, 0, 0], pd: [5, 2, 1], ee: [0, 0, 0], dm: [0, 0, 0] },
    Q14: { cf: [0, 0, 0], pd: [0, 0, 0], ee: [0, 0, 0], dm: [1, 2, 5] },
    Q15: { cf: [2, 5, 1], pd: [0, 0, 0], ee: [0, 0, 0], dm: [0, 0, 0] }
}

export const getHMLScoreArray = (q: number): IntrayCompetencyTypeScore[] => {
    const scoreArray: IntrayCompetencyTypeScore[] = [];
    const scoreTable = intrayHMLScoreTable[`Q${q}`];
    for(let comp in scoreTable) {
        scoreArray.push({
            competencyId: comp,
            v: scoreTable[comp]
        });
    }
    return scoreArray;
}

export const getActionScoreArray = (q: number): IntrayCompetencyTypeScore[] => {
    const scoreArray: IntrayCompetencyTypeScore[] = [];
    const scoreTable = intrayActionScoreTable[`Q${q}`];
    for(let comp in scoreTable) {
        scoreArray.push({
            competencyId: comp,
            v: scoreTable[comp]
        });
    }
    return scoreArray;
}