import React, { Component } from "react";
import TextField from "@mui/material/TextField";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Button from '@mui/material/Button';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

import RichEditor from "./richeditor";
import Mail from "../../models/intray.model";
import { getNameById } from "../../models/competency.model";
import { intrayActionScoreTable, intrayHMLScoreTable } from "../../util/intrayScoreTable";

const intrayCompetencies = ['cf', 'pd', 'ee', 'dm'];

interface Props {
    mail: Mail;
    index: number;
    removeOption: (mailIndex: number, optionIndex: number) => void;
    moveOption: (mailIndex: number, optionIndex: number, step: number) => void;
    setOption: (mailIndex: number, optionIndex: number, option: string) => void;
    setValue: (mailIndex: number, variable: keyof Mail, value: string) => void;
    setMailContent: (mainIndex: number, content: string) => void;
    clearMail: (mailIndex: number) => void;
    showUp: boolean;
    showDown: boolean;
}

class CreateMail extends Component<Props> {
    render() {
        const {mail, index, removeOption, moveOption, setOption, showUp, showDown, setValue, setMailContent, clearMail} = this.props;
        return (
            <Accordion defaultExpanded={index === 0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ width: '80%', flexShrink: 0 }}>
                        <strong>Q{index + 1}</strong> {mail.from} -- {mail.to} -- {mail.subject}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{padding: '0px 30px 30px 30px'}}>
                    <div>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                { intrayCompetencies.map((cType, cTypeIndex) => (
                                    <TableCell colSpan={3} key={"comp-" + cTypeIndex} style={{textAlign: 'center'}}>{getNameById(cType)}</TableCell>
                                ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>L</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>M</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>H</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>L</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>M</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>H</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>L</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>M</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>H</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>L</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>M</TableCell>
                                    <TableCell style={{textAlign: 'center', fontWeight: 'bold'}}>H</TableCell>
                                </TableRow>
                                <TableRow>
                                    { intrayCompetencies.map((cType, cTypeIndex) => (
                                        <React.Fragment key={"intraycomp-" + cTypeIndex}>
                                            <TableCell style={{textAlign: 'center'}} key={cTypeIndex}>{intrayHMLScoreTable["Q" + (index+1)][cType][0]}</TableCell>
                                            <TableCell style={{textAlign: 'center'}} key={cTypeIndex}>{intrayHMLScoreTable["Q" + (index+1)][cType][1]}</TableCell>
                                            <TableCell style={{textAlign: 'center'}} key={cTypeIndex}>{intrayHMLScoreTable["Q" + (index+1)][cType][2]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>A</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>B</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>C</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>A</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>B</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>C</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>A</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>B</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>C</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>A</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>B</TableCell>
                                    <TableCell style={{ textAlign: 'center' , fontWeight: 'bold' }}>C</TableCell>
                                </TableRow>
                                <TableRow>
                                    {intrayCompetencies.map((cType, cTypeIndex) => (
                                        <React.Fragment key={"score-" + cTypeIndex}>
                                            <TableCell style={{ textAlign: 'center' }} key={cTypeIndex}>{intrayActionScoreTable["Q" + (index + 1)][cType][0]}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }} key={cTypeIndex}>{intrayActionScoreTable["Q" + (index + 1)][cType][1]}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }} key={cTypeIndex}>{intrayActionScoreTable["Q" + (index + 1)][cType][2]}</TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                    <TextField fullWidth label="From" style={{ width: '80%', marginTop: 20 }} value={mail.from} placeholder='sender@company.com' required onChange={(e) => setValue(index, 'from', e.target.value)} />
                    <TextField fullWidth label="To" style={{ width: '80%', marginTop: 20 }} value={mail.to} placeholder='receiver@company.com' required onChange={(e) => setValue(index, 'to', e.target.value)} />
                    <TextField fullWidth label="Subject" style={{ width: '80%', marginTop: 20 }} value={mail.subject} placeholder='Mail subject' required onChange={(e) => setValue(index, 'subject', e.target.value)} />
                    <div style={{marginTop: 20}}>
                        <RichEditor setText={(content) => setMailContent(index, content)} initialContent={mail.content} />
                    </div>
                    <div style={{marginTop: 20}}>
                        Response options:
                    </div>
                    {mail.actions.map((action, actionIndex) => (
                        <React.Fragment key={actionIndex}>
                            <li style={{ lineHeight: '5em' }}>
                                <TextField label={'Option ' + (actionIndex + 1)} style={{ width: '80%', marginTop: 15 }} onChange={(e) => setOption(index, actionIndex, e.target.value)} value={action.text} />
                                <IconButton aria-label="delete" onClick={() => removeOption(index, actionIndex)}>
                                    <DeleteIcon />
                                </IconButton>
                                {showUp &&
                                    <IconButton aria-label="move up" onClick={() => moveOption(index, actionIndex, -1)}>
                                        <ArrowUpIcon />
                                    </IconButton>
                                }
                                {showDown &&
                                    <IconButton aria-label="delete" onClick={() => moveOption(index, actionIndex, 1)}>
                                        <ArrowDownIcon />
                                    </IconButton>
                                }
                            </li>
                        </React.Fragment>
                    ))}
                    <div style={{marginTop: 15}}>
                        <Button variant="contained" color="error" onClick={() => clearMail(index)}>Clear Mail</Button>
                    </div>
                </AccordionDetails>
                <Divider style={{ marginTop: 15 }} />
            </Accordion>
        )
    }
}

export default CreateMail;