import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import QuestionsList from './questionsList';
import questionTypes from '../../../models/questionTypes';

interface Props {
    match: any
}

class QuestionsListLayout extends Component<Props> {
    render() {
        return (
            <div>
                { questionTypes.map((qType, qIndex) => (
                    <Route key={qType.value} path={`${this.props.match.path}/${qType.value}`} render={() => <QuestionsList type={qType} />} />
                ))}
            </div>
        );
    }
}

export default QuestionsListLayout;