import DragHandleIcon from '@mui/icons-material/DragHandle';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import Assessment from '../../../models/assessments.model';
import SJTScenario from '../../../models/sjt.model';
import { generateStyles } from '../../../util/mainStyles';

interface OwnProps {
    questions: SJTScenario[];
    assessment: Assessment;
    setSJTAnswer: (questionIndex: number, subScenarioIndex: number, sortResult: any) => void;
    answers: any;
}

interface StyleProps {
    classes: any
}

type Props = OwnProps & StyleProps;

class SJTTest extends Component<Props>{
    
    componentDidMount() {
        document.addEventListener('touchend', this.cleanClasses, false);
    }
    
    cleanClasses = () => {
        document.body.className = '';
    };

    render() {
        const {classes, questions, setSJTAnswer} = this.props;
        return(
            <Grid container spacing={0} direction="column" alignItems="center" >
                <Grid xs={8} item style={{padding: '0% 2%'}}>
                    { questions.map((q: SJTScenario, qIndex: number) => (
                        <Card style={{marginBottom: '30px'}} key={qIndex.toString()}>
                            <CardContent>
                                <div className={classes.listItemText} dangerouslySetInnerHTML={{__html: q.text}} />
                                <Divider />
                                { q.subScenarios.map((subScenario, sIndex) => (
                                    <React.Fragment key={qIndex + "-" + sIndex}>
                                        <div style={{marginTop: 15}} className={classes.listItemText} dangerouslySetInnerHTML={{__html: subScenario.text}} />
                                        <Alert severity="info" className={classes.listItemText}>You can rank the options by dragging and dropping them (▲▼). The options will re-number automatically, with 1 being 'Most effective' and 4 'Least effective'.</Alert>
                                        <List>
                                            <DraggableContainer dragHandleSelector=".drag-handle" lockAxis="y" onDrop={(sortResult) => setSJTAnswer(qIndex, sIndex, sortResult)}>
                                                { subScenario.options.map((op, opIndex) => (
                                                    <Draggable key={op.text} >
                                                        <ListItem className="drag-handle" style={{cursor: 'grab'}}>
                                                            <ListItemAvatar>
                                                                <Avatar className={classes.responsiveAvatar}>{opIndex + 1}</Avatar>
                                                            </ListItemAvatar>
                                                            <ListItemText>
                                                                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                                                                    <div style={{ flex: 11 }} className={classes.listItemText}>{op.text}</div>
                                                                    <div style={{flex: 1, textAlign: 'right'}}>
                                                                        <ListItemIcon>
                                                                            <DragHandleIcon />
                                                                        </ListItemIcon>
                                                                    </div>
                                                                </div>
                                                            </ListItemText>
                                                            {/* <ListItemSecondaryAction>
                                                                <ListItemIcon>
                                                                    <DragHandleIcon />
                                                                </ListItemIcon>
                                                            </ListItemSecondaryAction> */}
                                                        </ListItem>
                                                    </Draggable>
                                                ))}
                                            </DraggableContainer>
                                        </List>
                                    </React.Fragment>
                                ))}
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
        )
    }
}

const styledSJTTest: any = withStyles(generateStyles, {withTheme: true})(SJTTest);
export { styledSJTTest as SJTTest};
