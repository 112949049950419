import { Theme } from '@mui/material';
import { red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import theme from './theme';

export const drawerWidth = 240;
export const toolbarHeight = 64;
export const footerHeight = 18;
export const mailButtonHeight = 80;
export const instructionsHeight = 48;

export const generateStyles = (theme: Theme) => ({
    root: {
        display: 'flex'
    },
    toolbar: {
        paddingRight: 24,
        alignContent: 'space-between',
        backgroundColor: 'rgb(127, 255, 211) !important',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        backgroundColor: 'rgb(127, 255, 211) !important',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    menuButtonHidden: {
        display: 'none'
    },
    title: {
        flexGrow: 1
    },
    drawerPaper: {
        position: 'relative !important' as 'relative',
        whiteSpace: 'nowrap' as 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: 'hidden' as 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 2,
        height: `calc(100vh - ${toolbarHeight}px)`,
        overflowX: 'hidden' as 'hidden',
        overflowY: 'auto' as 'auto',
        marginTop: '64px'
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    contentContainer: {
        padding: theme.spacing(4),
        paddingBottom: 80
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column' as 'column'
    },
    fixedHeight: {
        height: 240
    },
    center: {
        textAlign: 'center' as 'center'
    },
    footer: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
        position: 'fixed' as 'fixed',
        bottom: '0px',
        width: `calc(100% - ${drawerWidth}px)`,
        overflowX: 'hidden' as 'hidden',
        whiteSpace: 'nowrap' as 'nowrap',
        transition: theme.transitions.create(['width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1000
    },
    footerShift: {
        width: `calc(100% - ${theme.spacing(7)}px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.spacing(9)}px)`,
        }
    },
    footerFull: {
        width: `100%`,
    },
    mainContent: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(11),
        flexGrow: 1,
    },
    listContainer: {
        padding: theme.spacing(2)
    },
    user: {
        backgroundColor: theme.palette.background.paper
    },
    selectedUser: {
        backgroundColor: theme.palette.grey[300]
    },
    otpInput: {
        padding: theme.spacing(1),
        width: '3em !important', 
        height: '3em', 
        marginRight: theme.spacing(1), 
        color: theme.typography.body1.color,
        fontSize: '1.1rem',
        fontFace: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    closeButton: {
        position: 'absolute !important' as 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    routeContainer: {
        width: '85%'
    },
    expandedButton: {
        margin: theme.spacing(2),
        width: '90%'
    },
    progressIndicator: {
        marginRight: '15px'
    },
    whiteProgressIndicator: {
        color: 'white',
    },
    deleteButton: {
        background: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main),
        '&:hover': {
            background: red[700],
            color: theme.palette.getContrastText(red[700]),
        }
    },
    deleteIcon: {
        color: theme.palette.error.main,
        '&:hover': {
            color: red[700],
        }
    },
    tab: {
        textAlign: 'center' as 'center',
        fontWeight: 300,
        // border: '1px solid #aaa',
        borderBottom: '2px solid white',
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        cursor: 'pointer',
        padding: '14px !important',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginBottom: '2px',
        backgroundColor: theme.palette.grey[200]
    },
    tabActive: {
        textAlign: 'center' as 'center',
        fontWeight: 600,
        // border: '1px solid #aaa',
        borderBottom: '2px solid ' + theme.palette.primary.main,
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
        padding: '14px !important',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        marginBottom: '2px'
    },
    profileImageContainer: {
        position: 'relative' as 'relative',
        width: '134px',
        cursor: 'pointer'
    },
    profileImageWrapper: {
        width: '134px',
        borderRadius: '67px',
        height: '134px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
    },
    imageEditIcon: {
        position: 'absolute' as 'absolute',
        border: '4px solid',
        borderColor: theme.palette.primary.main,
        top: '4px',
        right: '4px',
        height: '32px',
        width: '32px',
        textAlign: 'center' as 'center',
        borderRadius: '50%',
        lineHeight: '19px'
    },
    mailList: {
        borderRight: '1px solid #aaa',
        overflowY: 'auto' as 'auto',
        height: `calc(100vh - ${toolbarHeight + footerHeight + mailButtonHeight + instructionsHeight}px)`,
    },
    mailContent: {
        overflowY: 'auto' as 'auto',
        height: `calc(100vh - ${toolbarHeight + footerHeight}px)`,
    },
    mailActive: {
        backgroundColor: '#ccc !important'
    },
    mailButton: {
        padding: '20px 50px !important',
    },
    mailAvatar: {
        backgroundColor: 'rgb(127, 255, 211) !important',
        color: 'black !important',
    },
    modalRoot: {
        position: 'absolute' as 'absolute',
        top: '50% !important',
        left: '50% !important',
        transform: 'translate(-50%, -50%) !important',
        backgroundColor: '#fff',
        boxShadow: theme.shadows[1],
        p: 4,
        padding: '20px',
        borderRadius: '10px'
    },
    generalContainer: {
        // overflowY: 'auto' as 'auto',
        height: `calc(100vh - ${toolbarHeight + footerHeight}px)`,
    },
    timer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '200px',
        fontSize: '2em'
    },
    timerEnding: {
        color: 'red'
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    listItemText: {
        fontSize: '0.8em',
        '@media (min-width:600px)': {
            fontSize: '1rem',
        },
    },
    leoListItemText: {
        fontSize: '0.8em',
        '@media (min-width:600px)': {
            fontSize: '0.9rem',
        },
    },
    responsiveAvatar: {
        fontSize: '0.8em',
        width: '20px',
        height: '20px',
        backgroundColor: 'rgb(127, 255, 211)',
        color: 'black',
        '@media (min-width:600px)': {
            fontSize: '1.25em',
            width: '40px',
            height: '40px',
        },
    },
    responsiveListItemAvatar: {
        minWidth: '25px',
        '@media (min-width:600px)': {
            minWidth: '56px',
        },
    },
    inarySubject:{
        '@media (max-width:600px)':{
            visibility: 'hidden',
            clear: 'both',
            float: 'left',
            margin: '10px auto 5px 20px',
            width: '28%',
            display: 'none',
    }
    },
    intrayOptions:{
        '@media (max-width:600px)':{
            maxWidth: '300px',
            overflowX: 'auto',
        }
    },
    intrayOptionDivid:{
        '@media (max-width:600px)':{
            width: '200px'
        }
    },
});

export const useStyles = makeStyles(generateStyles(theme));