import React, { Component } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import BulletIcon from '@mui/icons-material/RadioButtonChecked';
import CommonInstructions from './commonInstruction'
interface Props {
    time: number | null; // minutes
}

class InTrayInstructions extends Component<Props> {
    render() {
        return (
            <>
                <h2>Welcome to inQbox</h2>
                <List>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            In this exercise you are presented with different business issues in the form of mails received in your mailbox. You have to set priorities and take decisions on each mail, within the prescribed exercise duration.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Please read “inQbox Brief” & the Organization Chart before you start taking actions on the emails.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Please read through all the emails and:<br />
                            <div style={{ marginLeft: '20px' }}>
                                Assign a priority (High/ Medium/ Low) for each mail, based on the specific context &amp; situation
                            </div>
                            <div style={{ marginLeft: '20px' }}>
                                Choose the most appropriate action to be taken on each mail
                            </div>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            If you are done with responses for all mails before the time is complete, click on the red 'Finish' button.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        This exercise is best completed on a desktop/ laptop. In case you are attempting this on a handheld device, please use the device in ‘landscape’ (horizontal) mode
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time allowed: {this.props.time ? (this.props.time + " minutes") : <CircularProgress size="1rem" />}
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                            Time will begin when you press the start button below.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><BulletIcon color="primary" /></ListItemIcon>
                        <ListItemText>
                        Once you have pressed 'Start', you will need to complete the test within the given time - please do not close the window/ refresh the page/ stop the test midway. You will not be able to access this test again (through the link provided) once you have started.
                        </ListItemText>
                    </ListItem>
                </List>
                <React.Fragment>
                <CommonInstructions></CommonInstructions>
                </React.Fragment>
            </>
        )
    }
}

export default InTrayInstructions;