import React, { Component } from "react";
import { Bei } from "../../../models/bei.model";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { getNameById } from "../../../models/competency.model";
import { saveBEIScores } from "../../../util/apiService";
import toast from "react-hot-toast";

interface Props {
    review: any;
    questions: Bei[]
}
interface State {
    scores: {questionId: string, score: {id: string, v: number}}[];
}

class BeiReview extends Component<Props, State> {
    
    state: State = {
        scores: []
    }

    setScore = (question: Bei, value: string) => {
        const {scores} = this.state;
        for(const score of scores) {
            if(score.questionId === question._id) {
                score.score = {id: question.competency, v: parseInt(value)};
                this.setState({scores});
                return;
            }
        }
        scores.push({questionId: question._id || "", score: {id: question.competency, v: parseInt(value)}});
        this.setState({scores});
    }

    getScore = (question: Bei) => {
        const {scores} = this.state;
        for(const score of scores) {
            if(score.questionId === question._id) {
                return score.score.v;
            }
        }
        return -1;
    }

    getAnswer = (questionId: string, index: number) => {
        const { review } = this.props;
        try {
            const answers = JSON.parse(review.answer).answers;
            for(const answer of answers) {
                if(answer.questionId === questionId) {
                    return answer.answers[index];
                }
            }
            return "";
        } catch (error) {
            return "";
        };
    }

    saveScores = () => {
        const {questions} = this.props;
        const {scores} = this.state;
        const questionIdsList = questions.map(q => q._id);
        const scoresList = scores.map(s => s.questionId);
        for(const questionId of questionIdsList) {
            if(!questionId) continue;
            if(!scoresList.includes(questionId)) {
                window.alert("Please provide scores for all questions of this BEI Assessment");
                return;
            }
        }
        const saveScoresPromise = saveBEIScores(this.props.review._id, scores);
        saveScoresPromise.then((response: any) => {

        }).catch((error: any) => {
            console.log(error);
        });
        toast.promise(saveScoresPromise, {
            loading: 'Saving scores ...',
            success: 'Scores saved',
            error: 'Error saving scores'
        });
    }
    
    render() {
        const { questions } = this.props;
        return (
            <div>
                { questions && questions.map((question, index) => (
                    <React.Fragment>
                        <div style={{ marginTop: 15 }}>
                            <h5 dangerouslySetInnerHTML={{__html: question.question}} />
                        </div>
                        <div style={{ marginTop: 15 }}>
                            { question.options.map((option, optionIndex) => (
                                <Card key={index} style={{marginTop: 15}}>
                                    <CardHeader title={option.name } />
                                    <CardContent>
                                        <div>{this.getAnswer(question._id || "", optionIndex)}</div>
                                    </CardContent>
                                </Card>
                            ))}
                        </div>
                        <div style={{marginTop: 15}}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                                <Paper style={{ padding: 15, borderRadius: 15, marginBottom: 15 }}>
                                    <FormControl>
                                        <FormLabel>{getNameById(question.competency)} Score</FormLabel>
                                        <RadioGroup row aria-label="score" name="score" value={this.getScore(question)} onChange={(e) => this.setScore(question, e.target.value)}>
                                            <FormControlLabel value="1" control={<Radio />} label="1" />
                                            <FormControlLabel value="2" control={<Radio />} label="2" />
                                            <FormControlLabel value="3" control={<Radio />} label="3" />
                                            <FormControlLabel value="4" control={<Radio />} label="4" />
                                            <FormControlLabel value="5" control={<Radio />} label="5" />
                                        </RadioGroup>
                                    </FormControl>
                                </Paper>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                <div style={{ marginTop: 15 }}>
                    <Button variant="contained" color="primary" onClick={() => this.saveScores()}>Save</Button>
                </div>
            </div>
        );
    }
}

export default BeiReview;