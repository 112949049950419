export default class Group {
    _id?: string;
    name: string;
    clientContact: string;
    clientName: string;
    clientLogo?: string;
    du?: Date;
    dc?: Date;
    emailSubject?: string;
    emailPart1?: string;
    emailPart2?: string;
    reminderEmailSubject?: string;
    reminderEmailPart1?: string;
    reminderEmailPart2?: string;
}