import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { Component } from 'react';
import { changePassword } from '../../../util/apiService';
import { UserCredential } from '../../../models/user.model';
import toast from 'react-hot-toast';
import history from "../../../util/history";
interface Props {
    match: any;
    location:any;
}
interface State {
    confirmPassword: string;
    oldPassword: string;
    password: string;
    submitted:boolean;
    confirmPasswordErr:boolean;
    userName:string;
}
class ChangePassword extends Component<Props, State> {
    state: State = {
        confirmPassword: "",
        oldPassword: "",
        password: "",
        submitted:false,
        confirmPasswordErr:false,
        userName:""
    }

    componentDidMount() {
    }
    setValue = (variable: any, value: string) => {
        this.setState({ ...this.state, [variable]: value, confirmPasswordErr:false})
    }
    changePassword = () => {
        this.setState({submitted:true})
        const { confirmPassword, oldPassword, password } = this.state;
        if(password && confirmPassword !== password){
            this.setState({confirmPasswordErr:true})
            return
        }
       
        if (confirmPassword && oldPassword && password) {
            const userParams: UserCredential = { oldPassword: oldPassword, password: password }
            const { match } = this.props;
            const { params } = match;
            const saveUserPromise = changePassword(params.id, userParams).then(() => {
                history.push("/admin/assessments")
            })
            toast.promise(saveUserPromise, {
                loading: 'Saving password',
                success: 'Password saved',
                error: 'Error saving password'
            });
        } else {
            console.log("error")
        }
    }
    render() {
        const { confirmPassword,oldPassword, password, confirmPasswordErr,userName } = this.state
        return (
            <div>
                <h3> Change Password  { this.props.location && this.props.location.state && this.props.location.state.userName ?`for `+ this.props.location.state.userName: ``}</h3>
                <Accordion >

                    <AccordionDetails style={{ padding: '0px 30px 30px 30px' }}>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Old Password' type="password" onChange={(e) => { this.setValue('oldPassword', e.target.value) }} style={{ textAlign: 'right' }} />
                            {!oldPassword && this.state.submitted && <p className='red-text'>Old password field is required.</p>}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='New Password' type="password" onChange={(e) => { this.setValue('password', e.target.value) }} style={{ textAlign: 'right' }} />
                            {!password && this.state.submitted && <p className='red-text'>New password field is required.</p>}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <TextField fullWidth label='Confirm Password' type="password" onChange={(e) => { this.setValue('confirmPassword', e.target.value) }} style={{ textAlign: 'right' }} />
                            {!confirmPassword && this.state.submitted && <p className='red-text'>Confirm password field is required.</p>}
                            {confirmPasswordErr && this.state.submitted && <p className='red-text'>Confirm password does not match.</p>}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <Button variant="contained" color="error" onClick={() => { this.changePassword() }}>
                                Save
                            </Button>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        )
    }
}

export default ChangePassword;