import UserIcon from '@mui/icons-material/AccountCircle';
import ExitIcon from '@mui/icons-material/ExitToApp';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuizIcon from '@mui/icons-material/Quiz';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Link, Route } from 'react-router-dom';
import Copyright from './copyright';
import QuestionsListLayout from './questions/questionListLayout';
import QuestionsLayout from './questions/questions';
import { useStyles } from '../../util/mainStyles';
import questionTypes from '../../models/questionTypes';
import authService from '../../util/authService';
import AssessmentsLayout from './assessments/assessments';
import AssessmentIcon from '@mui/icons-material/DynamicForm';
import BEIReviewLayout from './beireview/beiReviewLayout';
import usersLayout from './users/users';
import { userDetailById } from '../../util/apiService';

const MainLayout = (props: any) => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
    const userMenuOpen = Boolean(userMenuAnchorEl);
    const [isAdminRole, setIsAdminRole] = useState(false);
    const [usersDetails, setUserDetails] = useState<any>(null);
    const [userID, setUserId] = useState<any>(null);
    
    const handleUserMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    }

    const closeUserMenu = () => {
        setUserMenuAnchorEl(null);
    }

    useEffect(() => {
        const userDetailInfo = localStorage.getItem('userDetails');
        if (userDetailInfo) {
            const userDetails = JSON.parse(userDetailInfo);
            const userId = userDetails.userDetails.id
            const isAdmin =  userDetails.userRole.realmMappings.length && userDetails.userRole.realmMappings.find((val:any )=> val.name === "admin");
            if(isAdmin){
             setIsAdminRole(isAdmin);
            }
            setUserId(userId)
        const currentUser = userDetailById(userId);
        currentUser.then((usersDetailsRes:any) => {
           setUserDetails(usersDetailsRes.userDetails)
        }).catch((err: any) => {
            console.log(err);
        });
        }
      }, [500]);

    return(
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="absolute" className={classes.appBar}>
                <Toolbar className="classes.toolbar">
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        Testing Portal
                    </Typography>
                    <IconButton color="inherit" aria-controls="user-menu" aria-haspopup="true" onClick={handleUserMenuClick} >
                        <UserIcon />
                        {usersDetails && <Typography style={{paddingLeft:10}} component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {usersDetails.username}
                    </Typography>}
                    </IconButton>
                    <Menu 
                        id="user-menu" 
                        anchorEl={userMenuAnchorEl} 
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        keepMounted 
                        open={userMenuOpen} 
                        onClose={closeUserMenu} 
                        TransitionComponent={Fade}
                    >
                      {(usersDetails && usersDetails.username!='admin') && 
                        <MenuItem>
                             <ListItem button component={Link} to={`/admin/users/changepassword/${userID}`} >
                                <ListItemIcon><KeyIcon /></ListItemIcon>
                                <ListItemText>Change password</ListItemText>
                            </ListItem>
                        </MenuItem>
                    }
                        <MenuItem onClick={() => authService.logout() }>
                              <ListItem>
                                <ListItemIcon><ExitIcon /></ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </ListItem>
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" classes={{paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)}} open={open}>
                <div className={classes.toolbarIcon}>
                    <img src="https://inqsights.com/wp-content/uploads/2021/08/logo_dark.png" alt="inQsights" style={{ width: '100px' }} />
                </div>
                <Divider />
                <List>
                    <ListItem button component={Link} to="/admin/assessments" >
                        <ListItemIcon><AssessmentIcon /></ListItemIcon>
                        <ListItemText primary="Assessments" />
                    </ListItem>
                </List>
                {isAdminRole &&<List>
                    <ListItem button component={Link} to="/admin/users" >
                        <ListItemIcon><PeopleAltIcon /></ListItemIcon>
                        <ListItemText primary="Users" />
                    </ListItem>
                </List>}
                {isAdminRole && <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.wrapIcon}>
                            <QuizIcon style={{ marginRight: 31, color: 'rgba(0, 0, 0, 0.54)'}} />
                            Question Bank
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List>
                            { questionTypes.map((qType, qTypeIndex) => (
                                <ListItem key={qTypeIndex} button component={Link} to={"/admin/questions/" + qType.value} >
                                    <ListItemText primary={qType.name} />
                                </ListItem>
                            ))}
                        </List>
                    </AccordionDetails>
                </Accordion>
                }
            </Drawer>
            <main className={classes.content}>
                <div className={classes.contentContainer}>
                    <Route path={`${props.match.path}/questions`} component={QuestionsListLayout} />
                    <Route path={`${props.match.path}/assessments`} component={AssessmentsLayout} />
                    <Route path={`${props.match.path}/bei`} component={BEIReviewLayout} />
                    <Route path={`${props.match.path}/create`} component={QuestionsLayout} />
                    <Route path={`${props.match.path}/users`} component={usersLayout} />
                    {/* <Redirect from="*" to="/admin/assessments" /> */}
                </div>
                <footer className={clsx(classes.footer, !open && classes.footerShift)} >
                    <Copyright />
                </footer>
            </main>
        </div>
    )
}

export default MainLayout;