import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StartIcon from '@mui/icons-material/PlayCircleFilled';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Component } from 'react';
import toast from 'react-hot-toast';
import BEIInstructions from './instructions/beiInstructions';
import InTrayInstructions from './instructions/intrayInstructions';
import LEOInstructions from './instructions/leoInstructions';
import LPTInstructions from './instructions/lptInstructions';
import MCQInstructions from './instructions/mcqInstructions';
import SJTInstructions from './instructions/sjtInstructions';
import { InTrayTest } from './testcomponents/intray';
import { LEOTest } from './testcomponents/leo';
import { fetchTest, submitAnswers, saveExitFullScreen, saveUserLocation,startTest } from '../../util/apiService';
import { generateStyles } from '../../util/mainStyles';
import Timer from '../common/timer';
import Copyright from '../main/copyright';
import { LPTTest } from './testcomponents/lpt';
import { SJTTest } from './testcomponents/sjt';
import { arrayMoveImmutable } from 'array-move';
import { SimpleDialog } from '../common/dialog';
import { BEITest } from './testcomponents/bei';
import { AptitudeTest } from './testcomponents/aptitude';
import { JobSkillsTest } from './testcomponents/jobSkills';
import { Prompt } from 'react-router-dom';
import { disableCopyPaste, detectFullScreenExit } from '../../util/theme'
import { SaveExitFullScreen, userLocation } from '../../models/userassessment.model'
interface OwnProps { }
interface State {
    fetchingTest: boolean;
    time: number; // seconds
    inProgress: boolean;
    finished: boolean;
    token: string;
    testType: string;
    assessment: any;
    questions: any[];
    group: any;
    showInstructions: boolean;
    answers: any;
    shouldBlockNavigation: boolean;
    sjtConfirmation: boolean;
    completeMessage: string;
    completeHeader: string;
    lptQuestionsList: any;
    exited: boolean;
}

interface StyleProps {
    classes: any;
}

type Props = OwnProps & StyleProps;

const normalCompleteMessage = '<div style="text-align: center;">Thank you for taking this test. Your answers have been successfully submitted. You can now close this window.</div>';
const incompleteMessage = '<div style="text-align: center;">Your responses have not been submitted as all questions were mandatory and some were not answered</div>';
const normalHeader = "Answers submitted!";
const incompleteHeader = "Answers not submitted!";
const errorHeader = "Error submitting answers";
const errorMessage = '<div style="text-align: center;">An error occurred while submitting your answers. Please <a href="mailto:contactus@inqsights.com">contact the Inqsights team</a> and let us know.</div>';

class TestLayout extends Component<Props, State> {

    state: State = {
        fetchingTest: true,
        time: 0,
        inProgress: false,
        finished: false,
        token: "",
        testType: "",
        assessment: null,
        questions: [],
        group: {},
        showInstructions: true,
        answers: [],
        shouldBlockNavigation: false,
        sjtConfirmation: false,
        completeMessage: normalCompleteMessage,
        completeHeader: normalHeader,
        lptQuestionsList: [],
        exited: false
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        const testType = url.searchParams.get("testType");
        this.setState({ testType: testType || "" });
        if (!testType || !token) {
            this.setState({ fetchingTest: false });
            return;
        }
        if (token) {
            const fetchingTestPromise = fetchTest(token);
            let lptQuestionsList: any = [];
            fetchingTestPromise.then((response: any) => {
                let { assessment, questions, group } = response;
                if (!assessment || JSON.stringify(assessment) === "{}") {
                    assessment = null;
                }
                if (testType === 'lpt' || testType === 'lptJ' || testType === 'lptJC') {
                    let allQuestions = [];
                    for (let i = 0; i < questions.length; i++) {
                        const q: any = { ...questions[i] };
                        for (let j = 0; j < q.questions.length; j++) {
                            const subQ: any = q.questions[j];
                            subQ.questionIndex = i;
                            subQ.subQuestionIndex = j;
                            allQuestions.push(subQ);
                        }
                    }

                    lptQuestionsList = allQuestions.sort(() => 0.5 - Math.random())
                } else if (testType === 'leo') {
                    for (const q of questions) {
                        const shuffled = q.questions.sort(() => 0.5 - Math.random());
                        q.questions = shuffled;
                    }
                }
                const answers = this.getBlankAnswers(testType, questions);
                this.setState({ token: token || "", testType: testType || "", assessment, questions, group, answers, fetchingTest: false, time: assessment.time ? assessment.time * 60 : 0, lptQuestionsList });
                this.getUserIpAddress(token)
            }).catch((err: any) => {
                this.setState({ fetchingTest: false });
                console.log(err);
            });
            disableCopyPaste()

            toast.promise(fetchingTestPromise, {
                loading: 'Fetching test details ...',
                success: 'Test details fetched',
                error: 'Error fetching test details'
            });
        }
        window.addEventListener('mouseout', this.handleMouseOut);
        document.addEventListener('visibilitychange', this.handleVisibility)
    }

    getBlankAnswers = (testType: string, questions: any) => {
        const answers = [];
        switch (testType) {
            case "leo":
                for (const q of questions) {
                    answers.push({
                        category: q.category,
                        questions: []
                    });
                }
                break;
            case "lpt":
            case "lptJC":
            case "lptJ":
                for (const q of questions) {
                    answers.push({
                        category: q.category,
                        questions: q.questions.map((q: any) => ({ question: q.question }))
                    })
                }
                break;
            case "sjt":
                for (const q of questions) {
                    for (const subScenario of q.subScenarios) {
                        answers.push({
                            subScenarioId: subScenario._id,
                            options: subScenario.options.map((op: any) => op._id)
                        })
                    }
                }
                break;
            case "bei":
                for (const q of questions) {
                    answers.push({
                        questionId: q._id,
                        answers: q.options.map(() => "")
                    })
                }
                break;
            case "aptitude":
                for (const q of questions) {
                    answers.push({
                        questionId: q._id
                    })
                }
                break;
            case "jobSkills":
                for (const q of questions) {
                    answers.push({
                        questionId: q._id
                    })
                }
                break;
            case "inTray":
            case "intray":
                for (const q of questions) {
                    for (const email of q.emails) {
                        answers.push({
                            emailId: email._id,
                            hmlAnswer: null,
                            actionAnswer: null
                        })
                    }
                }
                break;
            default:

        }
        return answers;
    }

    startTimer = () => {
        this.setState({ inProgress: true, showInstructions: false, time: this.state.assessment.time * 60, shouldBlockNavigation: true });
        const url = new URL(window.location.href);
        const token:any = url.searchParams.get("token");
        const startPromise = startTest(token)
        startPromise.then((response: any) => {

        })
    }

    setSJTConfirmation = (value: boolean) => {
        this.setState({ sjtConfirmation: value });
    }

    submitAnswers = (check: boolean = true) => {
        let answerObject: any = [];
        let message = "Are you sure you want to submit your answers?";
        let completeMessage = normalCompleteMessage;
        let completeHeader = normalHeader;
        let incomplete = false;
        switch (this.state.testType) {
            case "leo":
                for (const answer of this.state.answers) {
                    if (answer.questions.length < 5) {
                        if (check) {
                            window.alert("You have not completed all questions. Please complete all 5 sets before clicking 'Submit'.");
                            return;
                        } else {
                            incomplete = true;
                            completeMessage = incompleteMessage;
                            completeHeader = incompleteHeader;
                        }
                    }
                    answerObject.push({
                        category: answer.category,
                        questions: answer.questions.map((q: any) => q.question)
                    });
                }
                break;
            case "lpt":
            case "lptJC":
            case "lptJ":
                for (const answer of this.state.answers) {
                    for (const q of answer.questions) {
                        if (!('answer' in q)) {
                            if (check) {
                                window.alert("All questions are mandatory. You have some questions unanswered. Please complete before submitting answers.");
                                return;
                            } else {
                                incomplete = true;
                                completeMessage = incompleteMessage;
                                completeHeader = incompleteHeader;
                            }
                        }
                    }
                    answerObject.push(answer);
                }
                break;
            case "sjt":
                answerObject = [...this.state.answers]
                break;
            case "bei":
                for (const answer of this.state.answers) {
                    for (const text of answer.answers) {
                        if (!text) message = "One of your answers is empty. Are you sure you want to submit?"
                    }
                }
                answerObject = [...this.state.answers];
                break;
            case "aptitude":
                for (const answer of this.state.answers) {
                    if (!('answer' in answer)) {
                        message = "One or more questions are unanswered. There are no negative marks. Are you sure you want to submit?"
                    } else {
                        answerObject.push(answer);
                    }
                }
                break;
            case "jobSkills":
                for (const answer of this.state.answers) {
                    if (!('answer' in answer)) {
                        message = "One or more questions are unanswered. There are no negative marks. Are you sure you want to submit?"
                    } else {
                        answerObject.push(answer);
                    }
                }
                break;
            case "inTray":
            case "intray":
                for (const answer of this.state.answers) {
                    if (answer.hmlAnswer === null || answer.actionAnswer === null) {
                        message = "One or more emails have not been marked for priority or action taken, or has not been sent. Are you sure you want to submit?"
                    }
                }
                answerObject = [...this.state.answers];
                break;
            default:
                message = "Are you sure you want to submit your answers?"
        }
        if (check) {
            const submitCheck = window.confirm(message);
            if (!submitCheck) return;
        }
        this.setState({ finished: true, shouldBlockNavigation: false, completeMessage, completeHeader });
        let finalAnswerObject = answerObject;
        if (['lpt', 'lptJ', 'leo', 'lptJC'].includes(this.state.testType)) {
            finalAnswerObject = { answers: answerObject, incomplete };
        }
        const submitAnswersPromise = submitAnswers((this.state.testType === "lptJ" || this.state.testType === "lptJC") ? "lpt" : this.state.testType, this.state.token, finalAnswerObject);
        submitAnswersPromise.then((response: any) => {

        }).catch((err) => {
            console.log(err);
            this.setState({ completeHeader: errorHeader, completeMessage: errorMessage });
        });
        toast.promise(submitAnswersPromise, {
            loading: 'Submitting answers ...',
            success: completeHeader,
            error: "Error submitting answers"
        });
    }

    // For LEO only
    setLEOAnswer = (questionIndex: number, newQuestions: any[], newAnswers: any[]) => {
        const { questions, answers } = this.state;
        questions[questionIndex].questions = newQuestions;
        answers[questionIndex].questions = newAnswers;
        this.setState({ questions, answers });
    }

    // For LPT only
    setLPTAnswer = (questionIndex: number, subQuestionIndex: number, value: number) => {
        const { answers } = this.state;
        answers[questionIndex].questions[subQuestionIndex].answer = value;
        this.setState({ answers });
    }

    //For SJT only
    setSJTAnswer = (questionIndex: number, subScenarioIndex: number, sort: any) => {
        const { questions, answers } = this.state;
        const subScenario = questions[questionIndex].subScenarios[subScenarioIndex];
        const subScenarioOptions = subScenario.options;
        const newOrder = arrayMoveImmutable(subScenarioOptions, sort.removedIndex, sort.addedIndex);
        questions[questionIndex].subScenarios[subScenarioIndex].options = newOrder;
        for (const a of answers) {
            if (a.subScenarioId === subScenario._id) {
                a.options = newOrder.map((n: any) => n._id);
                break;
            }
        }
        this.setState({ questions, answers });
    }

    // For BEI only
    setBeiAnswer = (questionIndex: number, optionIndex: number, maxChars: number, value: string) => {
        if (value.length > maxChars) return;
        const { answers } = this.state;
        const options = answers[questionIndex].answers;
        options[optionIndex] = value;
        this.setState({ answers });
    }

    // For aptitude only
    setAptitudeAnswer = (questionId: string, value: number) => {
        const { answers } = this.state;
        for (const answer of answers) {
            if (answer.questionId === questionId) {
                answer.answer = value;
                break;
            }
        }
        this.setState({ answers });
    }

    // For Intray only
    setIntrayAnswer = (emailId: string, type: string, value: number) => {
        const { answers } = this.state;
        for (const email of answers) {
            if (email.emailId === emailId) {
                email[type] = value;
                break;
            }
        }
        this.setState({ answers });
    }

    getTestComponent = () => {
        const { questions, assessment, answers, lptQuestionsList } = this.state;

        if (!assessment) return;

        switch (this.state.testType) {
            case "aptitude":
                return <AptitudeTest questions={questions} assessment={assessment} answers={answers} setAptitudeAnswer={this.setAptitudeAnswer} />;
            case "jobSkills":
                return <JobSkillsTest questions={questions} assessment={assessment} answers={answers} setJobSkillsAnswer={this.setAptitudeAnswer} />;
            case "sjt":
                return <SJTTest questions={questions} assessment={assessment} answers={answers} setSJTAnswer={this.setSJTAnswer} />;
            case "bei":
                return <BEITest questions={questions} assessment={assessment} answers={answers} setBeiAnswer={this.setBeiAnswer} />;
            case "inTray":
            case "intray":
                return <InTrayTest questions={questions} assessment={assessment} answers={answers} setIntrayAnswer={this.setIntrayAnswer} submitAnswers={this.submitAnswers} />;
            case "lpt":
            case "lptJC":
            case "lptJ":
                return <LPTTest questions={questions} assessment={assessment} answers={answers} setLPTAnswer={this.setLPTAnswer} lptQuestionsList={lptQuestionsList} />;
            case "leo":
                return <LEOTest questions={questions} assessment={assessment} answers={answers} setQuestionsAndAnswers={this.setLEOAnswer} submitAnswers={this.submitAnswers} />;
            default:
                return null;
        }
    }

    getInstructions = () => {
        switch (this.state.testType) {
            case "aptitude":
            case "jobSkills":
                return <MCQInstructions time={this.state.assessment?.time} type={this.state.testType} />;
            case "sjt":
                return <SJTInstructions time={this.state.assessment?.time} />;
            case "bei":
                return <BEIInstructions time={this.state.assessment?.time} />;
            case "inTray":
            case "intray":
                return <InTrayInstructions time={this.state.assessment?.time} />;
            case "lpt":
            case "lptJ":
            case "lptJC":
                return <LPTInstructions time={this.state.assessment?.time} type={this.state.testType} />;
            case "leo":
                return <LEOInstructions time={this.state.assessment?.time} />;
            default:
                return null;
        }
    }

    componentDidUpdate = () => {
        if (this.state.shouldBlockNavigation) {
            window.onbeforeunload = () => true
        } else {
            window.onbeforeunload = null;
        }
    }
    /**
     * Find user is exit from full screen or mouse leave from screen
     */
    captureExitFullScreen = () => {
        const params: SaveExitFullScreen = { _id: this.state.assessment._id, exitedFullScreen: true }
        console.log("state",this.state.exited)
        if (!this.state.exited) {
            saveExitFullScreen(params, this.state.token)
            this.setState({ exited: true })
        }
    }
    /**
     * Get user's ip address and location
     * @param token 
     */
    getUserIpAddress = (token: string) => {
        fetch('https://ipapi.co/json/')
            .then(function (response) {
                response.json().then(jsonData => {
                    if (jsonData) {
                        const params: userLocation = { ipAddress: jsonData.ip, location: jsonData.city + ',' + jsonData.country_name }
                        saveUserLocation(params, token)
                    }
                })
            })
            .then(function (data) {
                console.log(data);
            });
    }
    componentWillUnmount() {
        window.removeEventListener('mouseout', this.handleMouseOut);
        document.removeEventListener('visibilitychange', this.handleVisibility)
    }
    handleMouseOut = (event: any) => {
        // Check if the mouse left the window
        if (event.relatedTarget === null && this.state.shouldBlockNavigation) {
            this.captureExitFullScreen()
        }
    }
    handleVisibility = () => {
        if (this.state.shouldBlockNavigation) {
            console.log("coming to minimize ")
            this.captureExitFullScreen()
        }
    }
    render() {
        const { classes } = this.props;
        const { finished, time, fetchingTest, assessment, inProgress, testType, showInstructions, group, sjtConfirmation, completeMessage, completeHeader } = this.state;

        return (
            <div className={classes.root} >
                <CssBaseline />
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
                            <div style={{ flex: 1, textAlign: 'left' }}>
                                <img src="https://inqsights.com/wp-content/uploads/2021/08/logo_dark.png" alt="inQsights" style={{ width: '120px', marginTop: 10}} />
                            </div>
                            <div style={{ flex: 1, textAlign: 'center' }}>
                                {inProgress &&
                                    <Timer time={time} key={time} submitAnswers={this.submitAnswers} finished={finished} testType={testType} setSJTConfirmation={this.setSJTConfirmation} />
                                }
                            </div>
                            <div style={{ flex: 1, textAlign: 'right' }}>
                                {group?.clientLogo &&
                                    <img src={'https://inqimages.s3.amazonaws.com/dev/' + group.clientLogo} alt="inQsights" style={{ maxHeight: '40px', marginTop: 10 }} />
                                }
                            </div>
                        </div>
                        <div>

                        </div>
                    </Toolbar>
                </AppBar>
                <main className={classes.content} style={{ paddingBottom: 30 }} >
                    {!fetchingTest && (!assessment || !testType) &&
                        <Card style={{ margin: 40 }}>
                            <CardHeader title="Invalid Test" />
                            <CardContent>This seems to be an invalid link. Please <a href="mailto:contactus@inqsights.com">contact the Inqsights team</a> to check validity of this test.</CardContent>
                        </Card>
                    }
                    {testType && (assessment || fetchingTest) &&
                        <React.Fragment>
                            <Accordion expanded={showInstructions} onChange={() => this.setState({ showInstructions: !this.state.showInstructions })} style={{ marginTop: 15 }} >
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} >{this.state.showInstructions ? '' : 'Show '} Exercise Instructions</AccordionSummary>
                                <AccordionDetails>
                                    {this.getInstructions()}
                                </AccordionDetails>
                            </Accordion>
                            {!inProgress &&
                                <div style={{ textAlign: 'center', padding: 20 }}>
                                    <Button style={{ padding: '15px 20px' }} variant="contained" color="primary" onClick={() => this.startTimer()} disabled={fetchingTest} startIcon={<StartIcon />}>Start</Button>
                                </div>
                            }
                            {inProgress &&
                                <>
                                    <div >
                                        {this.getTestComponent()}
                                    </div>
                                    {this.state.testType !== 'inTray' &&
                                        <div style={{ marginTop: '15px', marginBottom: 50, textAlign: 'center' }}>
                                            <Button style={{ padding: '15px 20px' }} variant="contained" color="primary" onClick={() => this.submitAnswers()} disabled={fetchingTest} startIcon={<StartIcon />}>Submit Answers</Button>
                                        </div>
                                    }
                                </>
                            }
                        </React.Fragment>
                    }
                    <Prompt when={this.state.shouldBlockNavigation} message={"Your test is in progress. If navigate away, you will not be allowed to retake the test. Are you sure?"} />
                    {finished &&
                        <SimpleDialog open={true} title={completeHeader} showCloseButton={true} content={completeMessage} persistent={true} />
                    }
                    {sjtConfirmation &&
                        <SimpleDialog open={true} title={'Submit Answers'} content={'<div style="text-align: center;">To submit your answers click on the submit button below.</div>'} persistent={true} submitAnswers={this.submitAnswers} />
                    }
                    <footer className={clsx(classes.footer, classes.footerFull)} >
                        <Copyright />
                    </footer>
                </main>
            </div>
        )
    }
}

const styledTestLayout: any = withStyles(generateStyles, { withTheme: true })(TestLayout);
export { styledTestLayout as TestLayout };