import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';

import { generateStyles } from "../../../util/mainStyles";
import {Bei} from "../../../models/bei.model";
import Assessment from "../../../models/assessments.model";

interface OwnProps {
    questions: Bei[];
    assessment: Assessment;
    setBeiAnswer: (questionIndex: number, optionIndex: number, maxChars: number, value: string) => void;
    answers: any;
}

interface StyleProps {
    classes: any;
}

type Props = OwnProps & StyleProps;

class BEITest extends Component<Props> {
    
    render() {
        const { questions, setBeiAnswer, answers} = this.props;
        return(
            <Grid container spacing={0} direction="column" alignItems="center" >
                <Grid xs={8} item style={{ padding: '0% 5%', width: '100%' }}>
                    <Card style={{ marginBottom: '30px', marginTop: '30px' }}>
                        <CardContent>
                            { questions.map((question, questionIndex) => (
                                <div key={questionIndex}>
                                    <div style={{marginTop: 15}}>
                                        <div dangerouslySetInnerHTML={{__html: question.question}} />
                                    </div>
                                    {question.options.map((option, optionIndex) => (
                                        <React.Fragment key={optionIndex}>
                                            <div key={optionIndex} style={{ marginTop: '15px' }}>
                                                <TextField multiline fullWidth label={option.name} value={answers[questionIndex].answers[optionIndex]} onChange={(e) => setBeiAnswer(questionIndex, optionIndex, option.maxChars, e.target.value)} style={{ textAlign: 'right', margin: '15px 0' }} minRows={3} />
                                            </div>
                                            <div style={{ marginTop: '15px' }}>
                                                <span style={{ color: (answers[questionIndex].answers[optionIndex]?.length < (option.maxChars - 20)) ? 'black' : 'red'}}>{(option.maxChars - answers[questionIndex].answers[optionIndex]?.length) || 0}</span>/{option.maxChars} characters left
                                            </div>
                                        </React.Fragment>
                                    ))}
                                    { (questionIndex < questions.length - 1) &&
                                        <Divider style={{marginTop: 15, borderTop: '2px solid'}} />
                                    }
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const styledBEITest: any = withStyles(generateStyles, { withTheme: true })(BEITest);
export { styledBEITest as BEITest};