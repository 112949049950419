import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import Assessment from '../../../models/assessments.model';
import LEO, { Leoquestions } from '../../../models/leo.model';
import { generateStyles } from '../../../util/mainStyles';
import { LeoComponent } from '../../common/leoComponent';

interface OwnProps {
    questions: LEO[],
    assessment: Assessment;
    setQuestionsAndAnswers: (questionIndex: number, questionList: Leoquestions[], answerList: Leoquestions[]) => void;
    answers: any;
}

interface StyleProps {
    classes: any;
}

// interface State { }

type Props = OwnProps & StyleProps; 


class LEOTest extends Component<Props> {

    render() {
        const { questions, setQuestionsAndAnswers, answers } = this.props;
        return(
            <Grid container spacing={0} direction="row" alignItems="center" >
                <Grid xs={12} item style={{ padding: '0% 2%' }}>
                    <Card style={{ marginBottom: '30px', marginTop: '30px' }}> 
                        <CardContent>
                            {questions.map((q, index) => (
                                <div style={{paddingTop: '20px'}} key={index} >
                                    <div style={{fontSize: '1.4em', textAlign: 'center'}}>Set {index + 1}</div>
                                    <LeoComponent question={q} answer={answers[index]} index={index} setQuestionsAndAnswers={setQuestionsAndAnswers} />
                                    { (index < questions.length - 1) &&
                                        <Divider style={{marginTop: '20px', borderWidth: '4px'}} />
                                    }
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const styledLEO: any = withStyles(generateStyles, { withTheme: true })(LEOTest);
export { styledLEO as LEOTest };