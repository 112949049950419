import React, { Component } from 'react';
import Button from '@mui/material/Button';
import history from "../../../util/history";
import { UserCredential } from '../../../models/user.model';
import toast from 'react-hot-toast';
import { getUsers, deleteUserById } from '../../../util/apiService'
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import KeyIcon from '@mui/icons-material/Key';

interface Props {
}
interface State {
    users: any[]
}

class UsersList extends Component<Props, State> {
    state: State = {
        users: []
    }
    componentDidMount() {
        const getUsersPromise = getUsers()
        getUsersPromise.then((response: any) => {
            this.setState({ users: response.usersList })
        });
        toast.promise(getUsersPromise, {
            loading: 'Fetching users',
            success: 'Users fetched',
            error: 'Error fetching users'
        })
    }

    deleteUser = (uId: any, index: number) => {
        var shouldDelete = window.confirm('Delete user?');
        if (!shouldDelete) {
            return;
        }
        const deletePromise = deleteUserById(uId);
        deletePromise.then(() => {
            const { users } = this.state;
            users.splice(index, 1);
            this.setState({ users })
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(deletePromise, {
            loading: 'Deleting user ...',
            success: 'Deleted user',
            error: 'Deleting user failed'
        });
    }
    render() {
        const { users } = this.state;
        return (
            <div>
                <h3>
                    List of Stored Users
                    <Button style={{ marginLeft: 30 }} variant="contained" color='primary'
                        onClick={() => history.push(`/admin/users/create`)}>New User</Button>
                </h3>
                {users.length > 0 && users.map((a: UserCredential, aIndex: number) => (

                    <React.Fragment >
                        {(a.username != 'admin') &&
                            <div>
                                <ListItem
                                    key={aIndex}
                                    secondaryAction={
                                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                            <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }}
                                                onClick={() => {
                                                    history.push(
                                                        {
                                                            pathname: `/admin/users/changepassword/${a.id}`,
                                                            state: { userName: a.username },
                                                        }
                                                    )
                                                }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <IconButton><KeyIcon /> </IconButton>
                                                </div>
                                                <div>
                                                    Change password
                                                </div>
                                            </div>
                                            <div style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingLeft: 10, paddingRight: 10, cursor: 'pointer' }} onClick={() => { this.deleteUser(a.id, aIndex) }}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <IconButton><DeleteIcon /> </IconButton>
                                                </div>
                                                <div>
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    }>
                                    <ListItemButton style={{ maxWidth: '100%' }} onClick={() => { }} >
                                        <ListItemIcon>
                                            <Avatar>
                                                {aIndex}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }}
                                            primary={a.username} />
                                    </ListItemButton>
                                </ListItem>
                                {(aIndex < users.length) &&
                                    <Divider />
                                }
                            </div>
                        }
                    </React.Fragment>
                ))}

            </div>
        )
    }
}

export default UsersList;