import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { withStyles } from "@mui/styles";
import { generateStyles } from "../../util/mainStyles";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemButton from '@mui/material/ListItemButton';

interface Props {
    open: boolean;
    onClose: any;
    classes: any;
    questions: any[];
    addQuestionsFromPicker: any;
}

interface State {
    selectedItems: any;
}

class AptitudeQuestionPicker extends Component<Props, State> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: []
        }
    }

    getSecondaryText = (q: any) => {
        return 'Created: ' + (q.dc ? new Date(q.dc).toLocaleDateString('en-in', { year: 'numeric', month: 'short', day: 'numeric' }) : 'NA');
    }

    handleToggle = (qIndex: number) => {
        const {selectedItems} = this.state;
        const question = this.props.questions[qIndex];
        let foundIndex = -1;
        for(var i=0; i<selectedItems.length; i++) {
            if(selectedItems[i]._id === question._id) {
                foundIndex = i;
                break;
            }
        }
        if(foundIndex > -1) {
            selectedItems.splice(foundIndex, 1);
        } else {
            selectedItems.push(question);
        }
        this.setState({selectedItems});
    }

    isChecked = (index: number) => {
        const qId = this.props.questions[index]._id;
        const {selectedItems} = this.state;
        for(let i=0; i<selectedItems.length; i++) {
            if(qId === selectedItems[i]._id) {
                return true;
            }
        }
        return false;
    }

    selectQuestions = () => {
        this.props.addQuestionsFromPicker(this.state.selectedItems);
        this.setState({selectedItems: []});
    }
    
    render() {
        const { open, onClose, classes, questions } = this.props;

        return(
            <Dialog
                open={open}
                onClose={onClose}
                closeAfterTransition
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle style={{ position: 'relative' }}>
                    <Typography sx={{ fontSize: 25 }} >
                        Select Questions
                    </Typography>
                    <IconButton className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ textAlign: 'center', maxHeight: 600 }}>
                    <List sx={{width: '100%', }}>
                        {questions.map((q: any, qIndex: number) => (
                            <React.Fragment key={qIndex}>
                                <ListItem disablePadding>
                                    <ListItemButton style={{ flex: 1 }} role={undefined} dense onClick={() => this.handleToggle(qIndex)}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={this.isChecked(qIndex)}
                                                tabIndex={-1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                    </ListItemButton>
                                    <ListItemText style={{flex: 10}} primaryTypographyProps={{ style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }} primary={<div dangerouslySetInnerHTML={{__html: q.question}}/>} secondary={this.getSecondaryText(q)} />
                                </ListItem>
                                {(qIndex < questions.length - 1) &&
                                    <Divider />
                                }
                            </React.Fragment>
                        ))}
                    </List>
                    {questions.length === 0 &&
                        <Typography variant="body1" style={{ marginBottom: '20px', textAlign: 'center' }}>
                            No questions match your selection criteria
                        </Typography>
                    }
                    <Button variant="contained" color="primary" onClick={this.selectQuestions}>Add Questions</Button>
                </DialogContent>
            </Dialog>
        )
    }
}

const styledAptitudeQuestionPicker: any = withStyles(generateStyles, { withTheme: true })(AptitudeQuestionPicker);
export { styledAptitudeQuestionPicker as AptitudeQuestionPicker };