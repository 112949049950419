import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import UsersList from "./usersList";
import CreateUser from "./createUser";
import ChangePassword from "./changePassword";
import UserDetails from "./userDetails";

interface Props {
    match: any
}

class UsersLayout extends Component<Props> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/changepassword/:id`} component={ChangePassword} />
                <Route path={`${this.props.match.path}/create`} component={CreateUser} />
                <Route exact path={`${this.props.match.path}/`} component={UsersList} />
                <Route exact path={`${this.props.match.path}/userdetails/:id`} component={UserDetails} />
            </Switch>
        );
    }
}

export default UsersLayout;