import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import React, { Component } from 'react';
import { deleteQuestion, getQuestions } from '../../../util/apiService';
import history from '../../../util/history';
import toast from 'react-hot-toast';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { aptitudeCompetencies } from '../../../models/aptitude.model';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface Props {
    type: {name: string; value: string;}
}

interface State {
    questions: any[];
    selectedTabIndex: number;
    shortlistedQuestions: any[];
    selectedDifficulty: number;
}


class QuestionsList extends Component<Props, State> {
    
    state: State = {
        questions: [],
        selectedTabIndex: 0,
        shortlistedQuestions: [],
        selectedDifficulty: -1
    }

    componentDidMount() {
        const getQuestionsPromise = getQuestions(this.props.type.value)
        getQuestionsPromise.then((response: any) => {
            if(this.props.type.value === 'aptitude') {
                this.setState({questions: response, shortlistedQuestions: this.getShortlistedQuestions(response, aptitudeCompetencies[0].id, -1)});
            } else {
                this.setState({questions: response, shortlistedQuestions: response});
            }
        });
        toast.promise(getQuestionsPromise, {
            loading: 'Fetching questions',
            success: 'Questions fetched',
            error: 'Error fetching questions'
        });
       
    }

    getShortlistedQuestions = (questions: any[], category: string, difficulty: number) => {
        if(difficulty === -1) {
            return questions.filter(question => (question.category === category));
        }
        return questions.filter(question => (question.category === category && question.level === difficulty));
    }

    getPrimaryText = (q: any) => {
        switch(this.props.type.value) {
            case "sjt":
                return <div dangerouslySetInnerHTML={{ __html: q.text }} />;
            case "bei":
                return <div dangerouslySetInnerHTML={{ __html: (q.title + " - " + q.question) }} />;
            case "inTray":
                return q.name;
            case "aptitude":
            case "jobSkills":
                return <div dangerouslySetInnerHTML={{ __html: q.question}} /> 
            default:
                return ""
        }
    }

    getSecondaryText = (q: any) => {
        return 'Created: ' + (q.dc ? new Date(q.dc).toLocaleDateString('en-in', {year: 'numeric', month: 'short', day: 'numeric'}) : 'NA');
    }

    deleteQuestion = (qId: string) => {
        var shouldDelete = window.confirm('Delete question?');
        if (!shouldDelete) {
            return;
        }
        let questionIndex = -1;
        for(var i = 0; i<this.state.questions.length; i++) {
            if(this.state.questions[i]._id === qId) {
                questionIndex = i;
                break;
            }
        }

        if(questionIndex > -1) {
            const deleteQuestionPromise = deleteQuestion(this.props.type.value, this.state.questions[questionIndex]._id);
            deleteQuestionPromise.then(() => {
                const { questions } = this.state;
                questions.splice(questionIndex, 1);
                this.setState({ questions, shortlistedQuestions: this.getShortlistedQuestions(questions, aptitudeCompetencies[this.state.selectedTabIndex].id, this.state.selectedDifficulty) });
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(deleteQuestionPromise, {
                loading: 'Deleting question ...',
                success: 'Deleted question',
                error: 'Deleting question failed'
            });
        } else {
            window.alert("Something went wrong. Please reload the page and try again.");
        }
    }

    setDifficulty = (value: any) => {
        const selectedDifficulty = parseInt(value);
        this.setState({selectedDifficulty: parseInt(value), shortlistedQuestions: this.getShortlistedQuestions(this.state.questions, aptitudeCompetencies[this.state.selectedTabIndex].id, selectedDifficulty)});
    }

    changeTab = (tabIndex: number) => {
        this.setState({ selectedTabIndex: tabIndex, shortlistedQuestions: this.getShortlistedQuestions(this.state.questions, aptitudeCompetencies[tabIndex].id, this.state.selectedDifficulty) });
    }

    render() {
        const {type} = this.props;
        const {questions, selectedTabIndex, shortlistedQuestions, selectedDifficulty} = this.state;
        return (
            <div>
                <h3>
                    List of Stored {type.name} Questions
                    <Button style={{marginLeft: 30}} variant="contained" color='primary' onClick={() => history.push(`/admin/create/${type.value}`)}>New Question</Button>
                    </h3>
                {type.value === 'aptitude' &&
                    <React.Fragment>
                        <div style={{marginTop: 15, marginBottom: 15}}>
                            <FormControl fullWidth>
                                <InputLabel>Select difficulty:</InputLabel>
                                <Select value={selectedDifficulty} label="Select difficulty:" onChange={(e) => this.setDifficulty(e.target.value)}>
                                    <MenuItem value={-1} key={-1}>Any</MenuItem>
                                    {["Low", "Medium", "High"].map((level: string, dIndex: number) => (
                                        <MenuItem value={dIndex} key={dIndex}>{level}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={selectedTabIndex} onChange={(e, tabIndex) => this.changeTab(tabIndex)}>
                                    {aptitudeCompetencies.map((cType, cTypeIndex: number) => (
                                        <Tab key={cTypeIndex} label={cType.display} />
                                    ))}
                                </Tabs>
                            </Box>
                        </Box>
                    </React.Fragment>
                }
                <List>
                    { shortlistedQuestions.map((q: any, qIndex: number) => (
                            <React.Fragment key={qIndex}>
                                <ListItem key={qIndex} secondaryAction={
                                    <IconButton edge="end" onClick={() => this.deleteQuestion(q._id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }>
                                    <ListItemButton style={{maxWidth: '100%'}} onClick={() => history.push(`/admin/create/${type.value}/${q._id}`)} >
                                        <ListItemIcon>
                                            <Avatar>
                                                {qIndex + 1}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{style: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}} primary={this.getPrimaryText(q)} secondary={this.getSecondaryText(q)} />   
                                    </ListItemButton>
                                </ListItem>
                                { (qIndex < questions.length - 1) &&
                                    <Divider />
                                }
                            </React.Fragment>
                    ))}
                </List>
                { shortlistedQuestions.length === 0 &&
                    <div style={{marginTop: 15}}>
                        <Typography variant="body1">No questions found</Typography>
                    </div>
                }
            </div>
        )
    }
}

export default QuestionsList;