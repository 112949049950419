export const CompetencyType = [
    {
        competency: "Execution Excellence",
        id: "ee",
    },
    {
        competency: "Decision Making",
        id: "dm",
    },
    {
        competency: "Customer Focus",
        id: "cf",
    },
    {
        competency: "People Development",
        id: "pd",
    },
    {
        competency: "Entrepreneurial & Strategic Orientation",
        id: "es"
    },
    {
        competency: "Innovation & Change Leadership",
        id: "ic"
    }
];

export const CompetencyTypeIds = CompetencyType.map(a => a.id);

export const searchCompetencyByName = (competency: string) => {
    return CompetencyType.filter(a => a.competency === competency);
};

export const searchCompetencyById = (id: string) => {
    return CompetencyType.filter(a => a.id === id);
};

export const getNameById = (id: string) => {
    const result = searchCompetencyById(id);
    if (result.length > 0) {
        return result[0].competency;
    }
    return "";
}