import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import history from '../../util/history';

const LoadingMessage = () => {
    return (
        <div style={{ width: '100%', paddingTop: '10%', textAlign: 'center' }}>
            <h2><CircularProgress /></h2>
            <h2>Please wait... Logging you in.</h2>
            <h2>In case you are not redirected, click <Button variant="contained" color="primary" size="small" onClick={() => history.push("/login")}>Login</Button> to continue.</h2>
        </div>
    )
}

export default LoadingMessage;