import React, { Component } from "react";
import '../../../App.css';
class CommonInstructions extends Component{
    render() {
        return (
            <React.Fragment>
                <div className="instructions">
                    We believe that candidates follow test guidelines & will not try to bend the rules. Yet there is always the potential for some to try. To prevent this, we include best-in-class proctoring (anti-cheating) features in xQalibre that deter & detect attempts to cut corners.
                </div>
            </React.Fragment>
        )
    }
}

export default CommonInstructions;