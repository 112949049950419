export enum AptitudeQuestionTypes {
    qa = "qa", //quantitative ability
    di = "di", //data interpretation
    lr = "lr", //logical reasoning
    va = "va" //verbal ability
}

export const aptitudeCompetencies = [
    { id: 'va', display: 'Verbal Ability'},
    { id: 'qa', display: 'Quantitative Ability'},
    { id: 'lr', display: 'Logical Reasoning'},
    { id: 'di', display: 'Data Interpretation'},
]

export class MCQOption {
    id: string;
    answer: string;
}

export default class Aptitude {
    _id?: string;
    image?: string;
    category: string;
    question: string;
    level: number;
    options: MCQOption[];
    priority?: number;
    response?: string;
    answer?: number;
    assessmentId?: string;
    du?: Date;
    dc?: Date;
}