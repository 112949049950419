import { Component } from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { addAssessment, deleteAssessment, personalityProfileQuestions, getQuestions,updateQuestions } from "../../../../util/apiService";
import toast from "react-hot-toast";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { LPTQuestions } from '../../../../models/lpt.model'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveQuestion, updateQuestion } from '../../../../util/apiService';
import { getPP2DislayNames } from '../../../../models/userassessment.model'

interface Props {
    groupId: string;
    assessment: any;
    addAssessment: (assessmentType: string, assessmentDetails: any) => void;
    deleteAssessment: (assessmentType: string) => void;
    setAssessmentTime: (assessmentType: string, time: string) => void;
    saveAssessmentTime: (assessmentType: string) => void;
    type: string;
    selectedQuestions?: LPTQuestions;
}
interface State {
    lptIncluded: boolean;
    selectedQuestions: LPTQuestions[],
    questions: any,
    isSave: boolean
}
const getEmptyQuestion = () => {
    return { _id: '', category: '', priority: 0, status: 0, quetsions: [{ _id: '', question: '' }] };
}

class LptDetails extends Component<Props, State> {

    state: State = {
        questions: [],
        lptIncluded: this.props.assessment ? true : false, isSave: false,
        selectedQuestions: [{ _id: '', category: '', assessmentId: "", priority: 0, status: 0, questions: [{ _id: '', question: '' }] }]
    }
    componentDidMount() {
        if (this.state.lptIncluded &&  this.props.type==="lptJC") {
            this.getLptJCQuestions()
        }
    }
    getLptJCQuestions() {
        const getAssessmentQuestionsPromise = personalityProfileQuestions(this.props.assessment._id);
        let isSaveQuestions: boolean = false
        getAssessmentQuestionsPromise.then((res: any) => {
            const result = res.questions.map((item: LPTQuestions) => {
                if (!item.assessmentId) {
                    isSaveQuestions = true
                    const { category, priority, status, questions } = item
                    const obj: LPTQuestions = { category, priority, status, questions }
                    obj.assessmentId = this.props.assessment._id
                    return obj
                } else {
                    return item
                }

            })
            this.setState({ selectedQuestions: result, isSave: isSaveQuestions });
        })
        toast.promise(getAssessmentQuestionsPromise, {
            loading: 'Fetching assessment questions',
            success: 'Assessment questions fetched',
            error: 'Error fetching assessment questions'
        });

    }
    includeLPT(lptIncluded: boolean) {
        if (lptIncluded) {
            const addAssessmentPromise = addAssessment(this.props.groupId, this.props.type)
            addAssessmentPromise.then((response: any) => {
                this.props.addAssessment(this.props.type, response);
                if (this.props.type === "lptJC") {
                    this.getLptJCQuestions()
                }
                this.setState({ lptIncluded: true });
            });
            toast.promise(addAssessmentPromise, {
                loading: 'Adding test ...',
                success: 'Added test',
                error: 'Adding test failed'
            });
        } else {
            const deleteMessage=this.props.type === "lptJC"?"Delete LPTJC assessment?":"Delete LPT assessment?"
            var shouldDelete = window.confirm(deleteMessage);
            if (!shouldDelete) {
                return;
            }
            const deleteAssessmentPromise = deleteAssessment(this.props.assessment._id)
            deleteAssessmentPromise.then(() => {
                this.props.deleteAssessment(this.props.type);
                this.setState({ lptIncluded: false, selectedQuestions: [] });
            });
            toast.promise(deleteAssessmentPromise, {
                loading: 'Deleting test ...',
                success: 'Deleted test',
                error: 'Deleting test failed'
            });
        }
    }

    modifyQuestions(qIndex: number, index: number, value: string) {
        const { selectedQuestions } = this.state;
        const questions = selectedQuestions[qIndex].questions[index];
        questions.question = value
        this.setState({ selectedQuestions })
    }
    saveQuestion() {
        if (this.state.isSave) {
            const saveQuestionPromise = saveQuestion("lpt", [...this.state.selectedQuestions])
            saveQuestionPromise.then((response: any) => {
                this.setState({isSave: false})
                this.getLptJCQuestions()
            }).catch((err: any) => {
                console.log(err);
            });
            toast.promise(saveQuestionPromise, {
                loading: 'Saving question',
                success: 'Question saved',
                error: 'Error saving question'
            });
        } else {
            this.updateQuestion()
        }
    }
    updateQuestion() {
        const saveQuestionPromise = updateQuestions( [...this.state.selectedQuestions])
        saveQuestionPromise.then((response: any) => {
        }).catch((err: any) => {
            console.log(err);
        });
        toast.promise(saveQuestionPromise, {
            loading: 'Saving question ...',
            success: 'Question saved',
            error: 'Error saving question'
        });
    }
    
    render() {
        const { lptIncluded, selectedQuestions } = this.state;
        const { setAssessmentTime, saveAssessmentTime, assessment, type } = this.props;

        return (
            <div>
                <FormGroup>
                    <FormControlLabel control={<Switch checked={lptIncluded} onChange={(e) => this.includeLPT(e.target.checked)} />} label={"Include " + (type === "lpt" ? "LPT" : type === "lptJC" ? "PP2":"Personality Profile")} />
                </FormGroup>
                {lptIncluded &&
                    <><div style={{ marginTop: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: 8 }}>
                                <TextField fullWidth label='Time for this assessment' value={assessment?.time || 0} onChange={(e) => setAssessmentTime(this.props.type, e.target.value)} type="number" />
                            </div>
                            <div style={{ flex: 2, textAlign: 'left', marginLeft: 20 }}>
                                <Button variant="contained" color="primary" onClick={() => saveAssessmentTime(this.props.type)}>Save</Button>
                            </div>
                        </div>
                    </div>
                        {this.props.type === "lptJC" &&
                            <><div style={{ marginTop: 15 }}>
                                {selectedQuestions.map((q: any, qIndex: number) => (
                                    <Accordion defaultExpanded={false} key={qIndex}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                <Typography sx={{ flexShrink: 0, flex: 8 }}>
                                                    Category: {getPP2DislayNames[q.category]}
                                                </Typography>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {q.questions.map((question: any, index: number) => (
                                                <div style={{ marginTop: 15 }} key={index}>
                                                    <TextField label='Question' style={{ width: '80%' }}
                                                        value={question.question}
                                                        onChange={(e) => this.modifyQuestions(qIndex, index, e.target.value)} />
                                                </div>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div><div style={{ marginTop: 15 }}>
                                    <Button variant="contained" color="error" onClick={() => this.saveQuestion()}>
                                        Save
                                    </Button>
                                </div></>
                        }
                    </>
                }
            </div>
        )
    }
}

export default LptDetails;