import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Dashboard } from "../dashboard";
import AssessmentsList from "./assessmentsList";
import CreateAssessments from "./createAssessment";

interface Props {
    match: any
}

class AssessmentsLayout extends Component<Props> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/dashboard/:id`} component={Dashboard} />
                <Route path={`${this.props.match.path}/dashboard`} component={Dashboard} />
                <Route path={`${this.props.match.path}/create/:id`} component={CreateAssessments} />
                <Route path={`${this.props.match.path}/create`} component={CreateAssessments} />
                <Route exact path={`${this.props.match.path}/`} component={AssessmentsList} />
            </Switch>
        );
    }
}

export default AssessmentsLayout;