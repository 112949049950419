import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import JobSkills from '../../../models/jobSkills.model';
import Assessment from '../../../models/assessments.model';
import { generateStyles } from '../../../util/mainStyles';

interface OwnProps {
    questions: JobSkills[];
    assessment: Assessment;
    setJobSkillsAnswer: (questionId: string, value: number) => void;
    answers: any;
}

interface StyleProps {
    classes: any
}

type Props = OwnProps & StyleProps;

interface State {
    questions: JobSkills[],
}

class JobSkillsTest extends Component<Props, State>{

    isChecked = (questionId: string, value: number) => {
        const answers = this.props.answers;
        const filteredAnswers = answers.filter((a: any) => a.questionId === questionId);
        if (filteredAnswers.length > 0) {
            if (filteredAnswers[0].answer === value) return true;
        }
        return false;
    }

    organizeQuestions = (questions: JobSkills[]) => {
        const sortedQuestionsLookup: any = [];
        const sortedQuestions = questions.sort((a, b) => (a.priority !== undefined && b.priority !== undefined) ? a.priority - b.priority : 0);
        return sortedQuestions;
    }
    renderImage(img: any) {
        if (img) {
            const image = 'https://inqimages.s3.amazonaws.com/dev/' + img
            const newimg = new Image();
            newimg.src = image;
            return (
            <div style={{ marginTop: 15, textAlign: 'center' }}>
                <img src={ image} style={{width:"50%"}} />
            </div>
            )
        }
    }
    render() {
        const { questions, setJobSkillsAnswer } = this.props;
        const sortedQuestions = this.organizeQuestions(questions);
        return (
            <Grid container spacing={0} direction="column" alignItems="center" >
                <Grid xs={8} item style={{ padding: '0% 5%', width: '100%', paddingTop: 25 }}>
                    {sortedQuestions.map((q: JobSkills, qIndex: number) => (
                        <Card style={{ marginBottom: '30px' }} key={qIndex.toString()}>
                            <CardContent>
                                <Typography variant="h6">
                                    Question {qIndex + 1}: <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: q.question }}></div>
                                </Typography>
                                <>{this.renderImage(q.image)}</>
                                <div style={{ textAlign: 'center' }} >
                                    <FormControl component="fieldset">
                                        <RadioGroup>
                                            {q.options.map((option: any, optionIndex: number) => (
                                                <FormControlLabel onClick={() => setJobSkillsAnswer(q._id || "", optionIndex)} key={optionIndex.toString()} label={option.answer} control={<Radio checked={this.isChecked(q._id || "", optionIndex)} />} />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </CardContent>
                        </Card>
                    ))}
                </Grid>
            </Grid>
        )
    }
}

const styledJobSkillsTest: any = withStyles(generateStyles, { withTheme: true })(JobSkillsTest);
export { styledJobSkillsTest as JobSkillsTest };
