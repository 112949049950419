import React, { Component } from "react";
import toast from "react-hot-toast";
import { getGroupUserAssessments, getQuestions } from "../../../util/apiService";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import BeiReview from "./beireview";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props {
    match: any
}

interface State {
    reviews: any[];
    questions: any;
}

class BEIReviewList extends Component<Props, State> {

    state: State = {
        reviews: [],
        questions: {},
    }

    componentDidMount() {
        if(this.props.match) {
            const getUserAssessmentPromise = getGroupUserAssessments(this.props.match.params.id, "bei", true);
            getUserAssessmentPromise.then((response: any) => {
                const filteredAssessments = response.filter((q: any) => q.assessmentType === 'bei');
                this.setState({ reviews: filteredAssessments });
                const assessmentIdList = filteredAssessments.map((q: any) => q.assessmentId);
                for(const assessmentId of assessmentIdList) {
                    const getQuestionsPromise = getQuestions("bei", assessmentId);
                    getQuestionsPromise.then((questionResponse: any) => {
                        const {questions} = this.state;
                        questions[assessmentId] = questionResponse;
                        this.setState({ questions });
                    });
                    toast.promise(getQuestionsPromise, {
                        loading: 'Fetching questions .. ',
                        success: 'Questions fetched',
                        error: 'Error fetching questions'
                    });
                }
            });
            toast.promise(getUserAssessmentPromise, {
                loading: 'Fetching assessments to be reviewed .. ',
                success: 'Assessments fetched',
                error: 'Error fetching assessments'
            });
            
        }
    }

    render() {
        const {reviews, questions} = this.state;
        return(
            <div>
                <h3>
                    BEI Assessments for Review
                </h3>
                <List>
                    {reviews && questions && reviews.map((review: any, rIndex: number) => (
                        <React.Fragment key={rIndex}>
                            <ListItem>
                                <Accordion defaultExpanded={true} style={{width: '100%'}}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                                        {review.user.name} ({review.user.email}) {review.user.employeeCode ? (" - " + review.user.employeeCode) : ""} {review.user.contact ? (" - " + review.user.contact) : ""}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <BeiReview review={review} questions={questions[review.assessmentId]} />
                                    </AccordionDetails>
                                </Accordion>
                            </ListItem>
                            {(rIndex < reviews.length - 1) &&
                                <Divider />
                            }
                        </React.Fragment>
                    ))}
                </List>
            </div>
        )
    }
}

export default BEIReviewList